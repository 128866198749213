import { useCallback, useMemo } from "react";
import { uniqBy } from "lodash";

import { useAllResourceSubscriptionsQuery } from "src/graphql";

type BaseRecipient = {
  id: string;
  channel: {
    id: string;
    channel_type: string;
    config: any;
  };
};

type ResourceNotificationChannel = BaseRecipient & {
  __typename: "resource_notification_channels";
  resource_id: string;
  resource_type: string;
};

type NotificationChannelTemplate = BaseRecipient & {
  __typename: "notification_channel_templates";
  id: string;
  parent_resource_id: string;
  parent_resource_type: string;
};

export type Recipient =
  | ResourceNotificationChannel
  | NotificationChannelTemplate;

export const useRecipients = () => {
  const { data } = useAllResourceSubscriptionsQuery({}, { suspense: true });

  const overrides = data?.resource_notification_channels ?? [];
  const templates = data?.notification_channel_templates ?? [];

  const getRecipientsForParentResourceIds = useCallback(
    (parentResourceIds: string[]): Array<Recipient> => {
      const stringIds = parentResourceIds.map(String);
      return uniqBy(
        templates.filter((s) =>
          stringIds.includes(s.parent_resource_id.toString()),
        ),
        "channel.id",
      ) as Array<Recipient>;
    },
    [overrides, templates],
  );

  const getRecipientsByParentResourceId = useCallback(
    (parentResourceIds: string[]) => {
      const stringIds = parentResourceIds.map(String);
      return stringIds.reduce((acc, id) => {
        acc[id] = templates.filter(
          (s) => s.parent_resource_id.toString() === id,
        );
        return acc;
      }, {});
    },
    [overrides, templates],
  );

  const getRecipientsForResourceIds = useCallback(
    (resourceIds: string[]) => {
      return overrides.filter((s) => resourceIds.includes(s.resource_id));
    },
    [overrides],
  );

  return useMemo(
    () => ({
      getRecipientsForParentResourceIds,
      getRecipientsByParentResourceId,
      getRecipientsForResourceIds,
      data,
    }),
    [
      getRecipientsForParentResourceIds,
      getRecipientsByParentResourceId,
      getRecipientsForResourceIds,
      data,
    ],
  );
};
