import { FC, useEffect, useMemo } from "react";

import {
  CloseIcon,
  Column,
  IconButton,
  NumberInput,
  Row,
  Select,
  Text,
  TimeIcon,
  Tooltip,
} from "@hightouchio/ui";
import isEqual from "lodash/isEqual";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  TIME_OPTIONS,
  ATTRIBUTION_BASIS_OPTIONS,
  DEFAULT_ATTRIBUTION_WINDOW,
} from "src/pages/metrics/constants";
import {
  AttributionBasis,
  AttributionWindow as AttributionWindowType,
} from "src/types/visual";

import { InputGroup } from "../input-group";
import { SubSection } from "../subsection";

interface AttributionWindowProps {
  attributionWindow: AttributionWindowType;
  hideRemoveButton?: boolean;
  onChange: (attributionWindow: AttributionWindowType) => void;
  onRemove: () => void;
}

export const AttributionWindow: FC<Readonly<AttributionWindowProps>> = ({
  attributionWindow,
  onChange,
  onRemove,
}) => {
  const filterId = useMemo<string>(uuidv4, []);
  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const quantityError = filterErrors?.quantity;

  const updateAttributionWindow = (updates: Partial<AttributionWindowType>) => {
    onChange({ ...attributionWindow, ...updates });
  };

  useEffect(() => {
    setFieldError(
      filterId,
      isNaN(attributionWindow?.quantity) || attributionWindow?.quantity < 0
        ? { quantity: "Must have a quantity greater than or equal to 0" }
        : null,
    );

    return () => {
      removeErrors([filterId]);
    };
  }, [attributionWindow.quantity]);

  return (
    <SubSection>
      <Row align="center" justify="space-between">
        <Text color="text.secondary" fontWeight="medium">
          <Tooltip
            openSpeed="slow"
            message="During what period should customer actions be attributed back to an audience?"
          >
            <Row gap={1}>
              <TimeIcon /> Attribution method
            </Row>
          </Tooltip>
        </Text>

        {!isEqual(attributionWindow, DEFAULT_ATTRIBUTION_WINDOW) && (
          <Tooltip message="Reset attribution method">
            <IconButton
              aria-label="Reset attribution method."
              icon={CloseIcon}
              size="sm"
              onClick={onRemove}
            />
          </Tooltip>
        )}
      </Row>

      <Column>
        <InputGroup
          isInvalid={Boolean(quantityError)}
          content={
            <Row flex={1} minWidth={0} gap={2}>
              <Column>
                <NumberInput
                  isInvalid={Boolean(quantityError)}
                  width="auto"
                  value={attributionWindow.quantity}
                  onChange={(value) => {
                    if (value === undefined) {
                      return;
                    }

                    updateAttributionWindow({ quantity: value });
                  }}
                />
                {Boolean(quantityError) && (
                  <Text color="text.danger" mt={2} size="sm">
                    {quantityError}
                  </Text>
                )}
              </Column>
              <Select
                options={TIME_OPTIONS}
                value={attributionWindow.unit}
                width="auto"
                onChange={(value) => {
                  if (!value) {
                    return;
                  }

                  updateAttributionWindow({ unit: value });
                }}
              />
              <Select
                options={ATTRIBUTION_BASIS_OPTIONS}
                value={attributionWindow.basis ?? AttributionBasis.Exit}
                width="auto"
                onChange={(value) => {
                  if (!value) {
                    return;
                  }

                  updateAttributionWindow({ basis: value });
                }}
              />
            </Row>
          }
        >
          <Text color="primary.base" textTransform="lowercase">
            {attributionWindow.quantity}{" "}
            {
              TIME_OPTIONS.find(({ value }) => value === attributionWindow.unit)
                ?.label
            }{" "}
            {
              ATTRIBUTION_BASIS_OPTIONS.find(
                ({ value }) =>
                  value === (attributionWindow.basis ?? AttributionBasis.Exit),
              )?.label
            }
          </Text>
        </InputGroup>

        {Boolean(quantityError) && (
          <Text color="text.danger" mt={2} size="sm">
            {quantityError}
          </Text>
        )}
      </Column>
    </SubSection>
  );
};
