import { Box, Column, Skeleton, SkeletonBox, Text } from "@hightouchio/ui";
import { FC } from "react";
import { Table } from "src/ui/table";
import { TableColumn } from "src/ui/table/table";

export const MembersTablePlaceholder: FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  if (isLoading) {
    return <SkeletonTable />;
  }

  return <EmptyTable />;
};

const SkeletonTable = () => (
  <Skeleton isLoading>
    <Column overflowY="auto" pb={6} px={6}>
      <Table
        data={Array.from({ length: 100 }).map((_, index) => ({ id: index }))}
        columns={Array.from({ length: 5 }).map(() => SkeletonColumn)}
      />
    </Column>
  </Skeleton>
);

const SkeletonColumn: TableColumn = {
  cell: () => <SkeletonBox width="100%" height="20px" borderRadius="sm" />,
};

const EmptyTable = () => (
  <Column sx={{ alignItems: "center", pt: 24, px: 4, overflowY: "auto" }}>
    <Text size="lg" fontWeight="semibold" mb={1}>
      No rows found
    </Text>
    <Box color="text.secondary">
      There are no rows with a primary label matching your search.
    </Box>
  </Column>
);
