import { FC, useState } from "react";

import {
  AudienceIcon,
  Badge,
  Box,
  Button,
  ChakraListItem,
  ChakraUnorderedList,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  IconButton,
  Paragraph,
  Row,
} from "@hightouchio/ui";
import { isEqual, uniq } from "lodash";
import {
  Link,
  Navigate,
  useNavigate,
  useOutletContext,
  useParams,
} from "src/router";

import { Drawer } from "src/components/drawer";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import {
  useDeleteIdentityResolutionModelMutation,
  useUpdateIdentityResolutionGraphMutation,
  useUpdateIdentityResolutionModelMutation,
} from "src/graphql";
import {
  getDefaultIdentifiers,
  ModelState,
} from "src/pages/identity-resolution/types";
import eventIcon from "src/pages/schema/assets/event.svg";
import profileIcon from "src/pages/schema/assets/parent.svg";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { abbreviateNumber } from "src/utils/numbers";
import { MapperField, OrderByField } from "src/pages/identity-resolution/forms";
import {
  getIdentifiersDefinedByThisModel,
  getRemovedIdentifiers,
  GraphVersion,
  modelConfigValidationResolver,
} from "src/pages/identity-resolution/utils";

import { OutletContext } from ".";

export const Model: FC = () => {
  const { modelId: id } = useParams<{ modelId: string }>();
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const isIDRv2 = graph.version === GraphVersion.V2;
  const identifierOptions = uniq([
    ...getDefaultIdentifiers(isIDRv2),
    ...identifiers,
  ]);

  const onClose = () => {
    navigate(`/idr/${graph.id}/models`);
  };

  const model = graph.models.find((model) => String(model.id) === String(id));

  const values: ModelState = {
    model: model?.model ?? { id: "", name: "" },
    type: model?.type as "profile" | "event",
    order_by: model?.order_by?.column,
    mappings: [...(model?.mappings ?? []), { identifier: "", column: "" }],
  };

  const identifiersDefinedByThisModel = getIdentifiersDefinedByThisModel(
    id,
    graph,
  );

  const updateModelMutation = useUpdateIdentityResolutionModelMutation();
  const updateGraphMutation = useUpdateIdentityResolutionGraphMutation();
  const deleteMutation = useDeleteIdentityResolutionModelMutation();

  const form = useHightouchForm({
    onSubmit: async (model) => {
      if (!model || !id) return;

      const { mappings, order_by } = model;

      await updateModelMutation.mutateAsync({
        id: String(id),
        model: {
          order_by: order_by ? { column: order_by, order: "asc" } : undefined,
        },
        mappings: mappings.map(({ identifier, column }) => ({
          idr_model_id: String(id),
          model_id: String(model?.model.id),
          identifier,
          column,
        })),
      });

      // Only filter out broken merge rules if in v2 graph
      if (isIDRv2) {
        const removedIdentifiers = getRemovedIdentifiers(
          identifiersDefinedByThisModel,
          mappings.map(({ identifier }) => identifier),
        );

        const newMergeRules = (graph.merge_rules ?? []).filter(
          ({ identifier }) => !removedIdentifiers.includes(identifier),
        );
        const newBlockRules = (graph.block_rules ?? []).filter(
          ({ identifier }) => !removedIdentifiers.includes(identifier),
        );

        // Remove rules, if necessary
        if (
          !isEqual(newMergeRules ?? [], graph.merge_rules) ||
          !isEqual(newBlockRules ?? [], graph.block_rules)
        ) {
          await updateGraphMutation.mutateAsync({
            id: graph.id,
            input: { merge_rules: newMergeRules, block_rules: newBlockRules },
          });
        }
      }

      onClose();
    },
    values,
    resolver: (data, context, options) =>
      modelConfigValidationResolver(
        data,
        graph.version === GraphVersion.V2,
        context,
        options,
      ),
    success: "Model updated",
  });

  const deleteModel = async () => {
    if (!id) return;

    await deleteMutation.mutateAsync({ id: String(id) });

    // Only filter out rules if in v2 graph
    if (isIDRv2) {
      const newMergeRules = (graph.merge_rules ?? []).filter(
        (rule) => !identifiersDefinedByThisModel.includes(rule.identifier),
      );
      const newBlockRules = (graph.block_rules ?? []).filter(
        (rule) => !identifiersDefinedByThisModel.includes(rule.identifier),
      );

      // Remove rules, if necessary
      if (
        !isEqual(newMergeRules ?? [], graph.merge_rules) ||
        !isEqual(newBlockRules ?? [], graph.block_rules)
      ) {
        await updateGraphMutation.mutateAsync({
          id: graph.id,
          input: { merge_rules: newMergeRules, block_rules: newBlockRules },
        });
      }
    }

    setIsDeleting(false);
    onClose();
  };

  const validMappings = form
    .watch("mappings")
    .filter(({ identifier }) => identifier);

  const removedRuleIdentifiers: Set<string> = new Set();
  const identifiersReferencedInRules: Set<string> = new Set();

  if (isIDRv2) {
    const removedIdentifiers = new Set(
      getRemovedIdentifiers(
        identifiersDefinedByThisModel,
        validMappings.map(({ identifier }) => identifier),
      ),
    );

    const mergeRules = graph.merge_rules ?? [];
    const blockRules = graph.block_rules ?? [];

    for (const rule of [...mergeRules, ...blockRules]) {
      if (removedIdentifiers.has(rule.identifier)) {
        removedRuleIdentifiers.add(rule.identifier);
      }
      if (identifiersDefinedByThisModel.includes(rule.identifier)) {
        identifiersReferencedInRules.add(rule.identifier);
      }
    }
  }

  if (!model) {
    return <Navigate to={`/idr/${graph.id}/models`} replace />;
  }

  return (
    <Form form={form}>
      <Drawer closeOnEsc trapFocus={false} size="xl" isOpen onClose={onClose}>
        <Column
          height="100%"
          borderLeft="1px"
          borderTop="1px"
          borderColor="base.border"
        >
          <Row
            justify="space-between"
            align="flex-start"
            p={6}
            borderBottom="1px solid"
            borderColor="base.border"
            width="100%"
          >
            <Column gap={2}>
              <Row align="center" gap={2} overflow="hidden" height="24px">
                <Box
                  as="img"
                  src={model.type === "event" ? eventIcon : profileIcon}
                  width="24px"
                />
                <TextWithTooltip fontWeight="medium" isTruncated size="lg">
                  {model.model.name}
                </TextWithTooltip>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Row
                  borderRight="1px"
                  borderColor="base.divider"
                  borderStyle="solid"
                  pr={2}
                  align="center"
                >
                  <Badge icon={AudienceIcon}>
                    {model.model.query_runs?.[0]
                      ? `${abbreviateNumber(
                          model.model.query_runs?.[0]?.size,
                        )} rows`
                      : "Unknown size"}
                  </Badge>
                </Row>
                <Link href={`/models/${model.model.id}`}>View model</Link>
              </Row>
            </Column>
            <IconButton icon={CloseIcon} aria-label="Close" onClick={onClose} />
          </Row>

          <DrawerBody>
            <Column gap={6} flex={1} minH={0} overflow="auto">
              <MapperField
                columns={model.model.columns}
                identifierOptions={identifierOptions}
              />
              {model.type === "event" ? (
                <OrderByField columns={model.model.columns} />
              ) : null}
            </Column>
          </DrawerBody>

          <DrawerFooter>
            <FormActions
              confirmation={
                validMappings && removedRuleIdentifiers.size > 0
                  ? {
                      title: "Are you sure?",
                      message: (
                        <Column gap={2}>
                          <Paragraph>
                            Removing these identifiers will result in merge
                            rules and/or limits being removed. Are you sure you
                            want to remove merge rules and/or limits that
                            reference the following identifiers?
                          </Paragraph>
                          <ChakraUnorderedList>
                            {Array.from(removedRuleIdentifiers).map(
                              (identifier) => (
                                <ChakraListItem key={identifier}>
                                  {identifier}
                                </ChakraListItem>
                              ),
                            )}
                          </ChakraUnorderedList>
                        </Column>
                      ),
                    }
                  : undefined
              }
            />
            <Button
              size="lg"
              variant="warning"
              onClick={() => {
                setIsDeleting(true);
              }}
            >
              Delete
            </Button>
          </DrawerFooter>
        </Column>
      </Drawer>

      <DeleteConfirmationModal
        label="model"
        content={
          <Column gap={2}>
            <Paragraph>
              Deleting this model will remove its data from the identity graph.
              {identifiersReferencedInRules.size > 0 &&
                " There are merge rules and/or limits that reference the following identifiers. They will be deleted from the graph."}
            </Paragraph>
            {identifiersReferencedInRules.size > 0 && (
              <ChakraUnorderedList>
                {Array.from(identifiersReferencedInRules).map((identifier) => (
                  <ChakraListItem key={identifier}>{identifier}</ChakraListItem>
                ))}
              </ChakraUnorderedList>
            )}
          </Column>
        }
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        onDelete={deleteModel}
      />
    </Form>
  );
};
