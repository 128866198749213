import { Box, Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { Term } from "src/components/term";
import { valueIsEmpty } from "src/components/resource-alert-triggers/utils";
import { NumberInputField } from "./fields/number-input-field";
import { ConditionSummary, Critical, Value, Warning } from "./utils";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";
import { ReactNode } from "react";

export const SyncThroughputField = ({ name }) => {
  const { setValue, control, getValues } = useFormContext();

  const interval = getValues(`${name}.interval`);

  return (
    <Row gap={1} align="center" flexWrap="wrap">
      <Controller
        name={`${name}.sign`}
        control={control}
        render={({ field: { value } }) => (
          <Box width="120px">
            <Select
              value={value}
              size="sm"
              options={[
                { label: "More than", value: "gt" },
                { label: "Fewer than", value: "lt" },
              ]}
              onChange={(option) => {
                setValue(
                  name,
                  {
                    sign: option,
                    interval: interval ?? "7 days",
                    count: option === "gt" ? 1e6 : 1,
                  },
                  { shouldDirty: true },
                );
              }}
            />
          </Box>
        )}
      />
      <NumberInputField name={`${name}.count`} />
      <Text>
        <Term message="An operation is performed whenever a row is added, changed, or removed in a destination. This counts successful operations only; rejected operations are excluded.">
          sync operations
        </Term>{" "}
        in the last
      </Text>

      <Controller
        name={`${name}.interval`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Box width="90px">
            <Select
              value={value}
              size="sm"
              options={[
                { label: "1 hour", value: "1 hour" },
                { label: "4 hours", value: "4 hours" },
                { label: "1 day", value: "1 day" },
                { label: "2 days", value: "2 days" },
                { label: "7 days", value: "7 days" },
              ]}
              onChange={onChange}
            />
          </Box>
        )}
      />
    </Row>
  );
};

const withSign = (opts: {
  sign: "gt" | "lt";
  count: number;
  interval: string;
}): ReactNode => {
  const prefix = opts.sign === "gt" ? "more than" : "fewer than";
  return (
    <>
      {prefix} <Value>{Intl.NumberFormat("en-US").format(opts.count)}</Value>{" "}
      sync operations in the last <Value>{opts.interval}</Value>
    </>
  );
};

const Summary = ({ value }) => {
  return <>alert if {withSign(value)}</>;
};

export const SyncThroughputSummary: ConditionSummary<{
  sign: "gt" | "lt";
  count: number;
}> = ({ condition, prefix = true }) => {
  return (
    <>
      {!valueIsEmpty(
        MonitorConditionTypes.SyncThroughput,
        condition.error_value,
      ) && (
        <Critical prefix={prefix}>
          <Summary value={condition.error_value} />
        </Critical>
      )}
      {!valueIsEmpty(
        MonitorConditionTypes.SyncThroughput,
        condition.warning_value,
      ) && (
        <Warning prefix={prefix}>
          <Summary value={condition.warning_value} />
        </Warning>
      )}
    </>
  );
};
