import type { MonitorOperationType } from "@hightouch/core/server/graphql/types";
import type { ClientBase } from "pg";
import type { ElementOf } from "ts-essentials";
import type { Queryable } from "zapatos/db";

export enum ChannelType {
  Slack = "slack",
  Email = "email",
  PagerDuty = "pagerduty",
  Webhook = "webhook",
  Sms = "sms",
}

export function assertedChannelType(channelType: string): ChannelType {
  if (!Object.values(ChannelType).includes(channelType as ChannelType)) {
    throw new Error(`Unknown channel type ${channelType}`);
  }
  return channelType as ChannelType;
}

type SlackChannelConfig = {
  slackChannelId: string;
  name: string;
};

type SmsChannelConfig = {
  phoneNumber: string;
  name: string;
};

type EmailConfig = { emailAddress: string };

type WebhookConfig = { url: string; name: string };

type ChannelTypeToChannelConfigMapping = {
  [ChannelType.Slack]: SlackChannelConfig;
  [ChannelType.Sms]: SmsChannelConfig;
  [ChannelType.Email]: EmailConfig;
  [ChannelType.PagerDuty]: { severity: string };
  [ChannelType.Webhook]: WebhookConfig;
};

export type ChannelConfig = {
  [C in ChannelType]: ChannelTypeToChannelConfigMapping[C];
};

export type StoredMonitorCondition<TThresholds = unknown> = {
  id: string;
  workspace_id: string;
  evaluation_type: string;
  evaluation_trigger: string;
  type: string;
  enabled: boolean;
  error_value: TThresholds | null;
  warning_value: TThresholds | null;
  resource_id: string;
  resource_type: MonitoredResourceType;
  isTemplate?: boolean;
};

/**
 * If you are implementing a new monitored resource type, you will need to add it here.
 * An example of a monitored resource could be a sync, match booster, git sync etc...
 */
export enum MonitoredResourceType {
  Sync = "Sync",
  EventSource = "EventSource",
  EventSync = "EventSync",
}

/**
 * This is where you register any "parent" resource types. For example, a destination is the
 * parent of a sync. Parents are used for monitor condition _templates_ i.e. defaults for all child
 * resources of the parent.
 */
export enum ParentResourceTypes {
  Destination = "Destination",
}

// Don't touch these
export enum EvaluationType {
  Point = "point",
  Batch = "batch",
  Manual = "manual",
}

// Don't touch these
export enum EvaluationTrigger {
  Discrete = "discrete",
  Continuous = "continuous",
}

/**
 * If you are implementing a new monitor condition type, you will need to add it here.
 * Monitor conditions are used to evaluate the status of a monitored resource.
 */
export enum MonitorConditionTypes {
  SyncRejectedRows = "SyncRejectedRows",
  SyncRejectedRowsV2 = "SyncRejectedRowsV2",
  SyncSequentialFailures = "SyncSequentialFailures",
  SyncDuration = "SyncDuration",
  SyncMissedSchedules = "SyncMissedSchedules",
  SyncThroughput = "SyncThroughput",
  EventSourceVolume = "EventSourceVolume",
  ModelSize = "ModelSize",
}

// Exported for the frontend
export const ConditionFriendlyNames: {
  [M in MonitorConditionTypes]: string;
} = {
  [MonitorConditionTypes.SyncSequentialFailures]: "Fatal errors",
  [MonitorConditionTypes.SyncRejectedRows]: "Rejected rows",
  [MonitorConditionTypes.SyncRejectedRowsV2]: "Rejected rows",
  [MonitorConditionTypes.SyncThroughput]: "Successful operations",
  [MonitorConditionTypes.ModelSize]: "Model size",
  [MonitorConditionTypes.SyncDuration]: "Sync duration",
  [MonitorConditionTypes.SyncMissedSchedules]: "Excessive idle time",
  [MonitorConditionTypes.EventSourceVolume]: "Volume",
};

/**
 * This is where we define the input types used to evaluate each monitored resource type. This
 * will be the shape of args passed to SQS when enqueuing a monitor condition.
 */
type MonitoredResourceInputsTypeMapping = {
  [MonitoredResourceType.Sync]: { syncRequestId: string | null };
  [MonitoredResourceType.EventSource]: { eventSourceId: string };
  [MonitoredResourceType.EventSync]: { runId: string | null };
};

/**
 * This is where we map monitored resource types to their inputs. If you are getting
 * an error here, it's likely because you are missing an input for a monitored resource type.
 */
export type MonitoredResourceInputs = {
  [T in MonitoredResourceType]: MonitoredResourceInputsTypeMapping[T];
};

/**
 * Here we dictate how and when each monitor condition is evaluated.
 */
export const MonitorConditionEvaluationProperties: {
  [k in MonitorConditionTypes]: {
    EvaluationTrigger: EvaluationTrigger;
    EvaluationType: EvaluationType;
  };
} = {
  [MonitorConditionTypes.SyncRejectedRows]: {
    EvaluationTrigger: EvaluationTrigger.Discrete,
    EvaluationType: EvaluationType.Point,
  },
  [MonitorConditionTypes.SyncRejectedRowsV2]: {
    EvaluationTrigger: EvaluationTrigger.Discrete,
    EvaluationType: EvaluationType.Point,
  },
  [MonitorConditionTypes.SyncSequentialFailures]: {
    EvaluationTrigger: EvaluationTrigger.Discrete,
    EvaluationType: EvaluationType.Point,
  },
  [MonitorConditionTypes.SyncDuration]: {
    EvaluationTrigger: EvaluationTrigger.Continuous,
    EvaluationType: EvaluationType.Batch,
  },
  [MonitorConditionTypes.SyncMissedSchedules]: {
    EvaluationTrigger: EvaluationTrigger.Continuous,
    EvaluationType: EvaluationType.Batch,
  },
  [MonitorConditionTypes.SyncThroughput]: {
    EvaluationTrigger: EvaluationTrigger.Continuous,
    EvaluationType: EvaluationType.Batch,
  },
  [MonitorConditionTypes.EventSourceVolume]: {
    EvaluationTrigger: EvaluationTrigger.Continuous,
    EvaluationType: EvaluationType.Batch,
  },
  [MonitorConditionTypes.ModelSize]: {
    EvaluationTrigger: EvaluationTrigger.Discrete,
    EvaluationType: EvaluationType.Point,
  },
} as const;

// All point monitors can have an eventTime which is the time the actual
// monitored event occurred. This is optional for testing.
type PointMetricValue<T> = T & { eventTime?: Date };

/**
 * This is where we define the property types for each monitor condition. Each
 * new condition *must* have properties defined.
 */
export type MonitorConditionProperties = {
  [MonitorConditionTypes.SyncRejectedRows]: {
    Threshold: Threshold<{
      rejectedRowCount: number | null;
      pctRejectedRows: number | null;
    }>;
    Value: PointMetricValue<{
      acceptedRows: number;
      rejectedRows: number;
      sampledError?: string;
    }>;
    Metadata: {
      error: string;
    };
  };
  [MonitorConditionTypes.SyncRejectedRowsV2]: {
    Threshold: Threshold<{
      method: "percentage" | "count";
      value: number;
    }>;
    Value: PointMetricValue<{
      acceptedRows: number;
      rejectedRows: number;
      resourceError?: string;
      sampledError?: string;
    }>;
    Metadata: {
      error: string;
    };
  };
  [MonitorConditionTypes.SyncSequentialFailures]: {
    Threshold: Threshold<number>;
    Value: PointMetricValue<{ count: number; error?: string }>;
    Metadata: {
      error: string;
    };
  };
  [MonitorConditionTypes.SyncDuration]: {
    Threshold: Threshold<number>;
    Value: {
      durationMinutes: number;
      previousDurationMinutes?: number;
      inProgress: boolean;
    };
    Metadata: never;
  };
  [MonitorConditionTypes.SyncMissedSchedules]: {
    Threshold: Threshold<number>;
    Value: number;
    Metadata: never;
  };
  [MonitorConditionTypes.SyncThroughput]: {
    Threshold: Threshold<
      { interval: ElementOf<typeof SyncThroughputInterval> } & (
        | {
            sign: "gt" | "lt";
            count: number;
          }
        | { sign: "gtlt"; min: number; max: number }
      )
    >;
    Value: { warnChanges: bigint; unhealthyChanges: bigint };
    Metadata: never;
  };
  [MonitorConditionTypes.EventSourceVolume]: {
    Threshold: Threshold<
      {
        interval: ElementOf<typeof EventSourceVolumeInterval>;
        sign: "gt" | "lt";
        count: number;
      }[]
    >;
    Value: {
      // ordered list of volume counts
      // order should match the order of Threshold configs
      warnChanges: number[];
      unhealthyChanges: number[];
    };
    Metadata: never;
  };
  [MonitorConditionTypes.ModelSize]: {
    Threshold: Threshold<
      {
        sign: "gt" | "lt";
        count: number;
      }[]
    >;
    Value: {
      size: number;
    };
    Metadata: never;
  };
};

export const EventSourceVolumeInterval = [
  "7 days",
  "2 days",
  "1 day",
  "4 hours",
  "1 hour",
  "15 minutes",
] as const;

export const SyncThroughputInterval = [
  "7 days",
  "2 days",
  "1 day",
  "1 hour",
  "4 hours",
] as const;

/**
 * If you are getting an error here, it's likely because you haven't implemented
 * properties properly for a new monitor condition type.
 */
export type MonitorConditionConfig = {
  [T in MonitorConditionTypes]: MonitorConditionProperties[T] &
    (typeof MonitorConditionEvaluationProperties)[T];
};

// Thresholds types from the condition jsonb values
export interface Threshold<T> {
  [MonitorStatus.Warning]?: T;
  [MonitorStatus.Unhealthy]?: T;
}

export enum MonitorStatus {
  Unhealthy = "unhealthy",
  Warning = "warning",
  Healthy = "healthy",
  Disabled = "disabled",
}

export type PointEvaluationContext = {
  resourceId: string;
  resourceType: MonitoredResourceType;
  workspaceId: string;
};

export type BatchEvaluationContext = {
  resourceType: MonitoredResourceType;
  monitorConditionType: MonitorConditionTypes;
};

export class AbortedEvaluation extends Error {
  constructor(message: string) {
    super(message);
    this.name = "AbortedEvaluation";
  }
}

export type EvaluatedPointMonitorCondition<
  T extends MonitorConditionTypes,
  R extends MonitoredResourceType,
> = {
  evaluationType: EvaluationType.Point;
  getMetricValue(
    input: MonitoredResourceInputs[R],
    context: PointEvaluationContext,
    pgClient: Queryable,
    signal?: AbortSignal,
  ): Promise<MonitorConditionConfig[T]["Value"]>;
  evaluate(
    thresholds: MonitorConditionConfig[T]["Threshold"],
    value: MonitorConditionConfig[T]["Value"],
    context: PointEvaluationContext,
    signal?: AbortSignal,
  ): Promise<{
    status: MonitorStatus;
    resourceError?: string; // This field is for errors associated with the health of the resource e.g. the fatal sync error
  }>;
  // We would have better typing here but it borks the frontend if we import from /channels/types
  // so this will do for now.
  render: (
    opts: any,
  ) => Promise<{ text: string; metadata?: { error?: string } }>;
};

export type EvaluatedBatchMonitorCondition<T extends MonitorConditionTypes> = {
  batchSize?: number;
  evaluationType: EvaluationType.Batch;
  getMetricValues(
    context: BatchEvaluationContext,
    conditionConfigs: StoredMonitorCondition[],
    pgClient: ClientBase,
    signal?: AbortSignal,
  ): Promise<
    {
      value: MonitorConditionConfig[T]["Value"];
      conditionConfig: StoredMonitorCondition;
    }[]
  >;
  evaluate(
    thresholds: MonitorConditionConfig[T]["Threshold"],
    value: MonitorConditionConfig[T]["Value"],
    context: PointEvaluationContext,
    signal?: AbortSignal,
  ): Promise<{
    status: MonitorStatus;
    resourceError?: string; // This field is for errors associated with the health of the resource e.g. the fatal sync error
  }>;
  // We would have better typing here but it borks the frontend if we import from /channels/types
  // so this will do for now.
  render: (
    opts: any,
  ) => Promise<{ text: string; metadata?: { error?: string } }>;
};

export type TemplateOrManualConditionId = {
  conditionId?: string;
  conditionTemplateId?: string;
};

export interface MonitorConditionEvaluateAll {
  type: typeof MonitorOperationType.EvaluateAll;
  ids?: TemplateOrManualConditionId[];
  ignoreAlert?: boolean;
}

export type MonitorConditionEvaluate = {
  type: typeof MonitorOperationType.Evaluate;
  ids: TemplateOrManualConditionId[];
  ignoreAlert?: boolean;
};

export type MonitorConditionDelete = {
  type: typeof MonitorOperationType.Delete;
  ids: TemplateOrManualConditionId[];
  ignoreAlert?: boolean;
};

export type MonitorConditionDisable = {
  type: typeof MonitorOperationType.Disable;
  ids: TemplateOrManualConditionId[];
  ignoreAlert?: boolean;
};

export type MonitorConditionEnable = {
  type: typeof MonitorOperationType.Enable;
  ids: TemplateOrManualConditionId[];
  ignoreAlert?: boolean;
};

// Operations for conditions on a resource's monitor
export type MonitorConditionOperations =
  | MonitorConditionEvaluateAll
  | MonitorConditionEvaluate
  | MonitorConditionDelete
  | MonitorConditionDisable
  | MonitorConditionEnable;

export type MonitorEvaluatorMapping = {
  [R in MonitoredResourceType]: Partial<{
    [C in MonitorConditionTypes]:
      | EvaluatedPointMonitorCondition<C, R>
      | EvaluatedBatchMonitorCondition<C>;
  }>;
};

/**
 * To support a new monitor condition for a resource type, first add it here.
 *
 * Note: It's technically possible to generate this object from the
 * MonitorConditionEvaluators object; but the types that we get
 * out of it, while correct, are wildly unreadable in the editor.
 * They also can't be imported on the frontend.
 * This manual declaration is buying readability with a
 * little bit of boilerplate.
 */
export const SupportedConditionsForResourceTypes = {
  [MonitoredResourceType.Sync]: [
    MonitorConditionTypes.SyncSequentialFailures,
    MonitorConditionTypes.SyncRejectedRows,
    MonitorConditionTypes.SyncThroughput,
    MonitorConditionTypes.ModelSize,
    MonitorConditionTypes.SyncDuration,
    MonitorConditionTypes.SyncMissedSchedules,
  ],
  [MonitoredResourceType.EventSync]: [
    MonitorConditionTypes.SyncRejectedRowsV2,
    MonitorConditionTypes.SyncSequentialFailures,
  ],
  [MonitoredResourceType.EventSource]: [
    MonitorConditionTypes.EventSourceVolume,
  ],
} as const;

export type SupportedConditionsForResourceTypes =
  typeof SupportedConditionsForResourceTypes;

export type SupportedConditionsForResourceType<
  T extends MonitoredResourceType,
> = SupportedConditionsForResourceTypes[T][number];

export type StatusUpdate = {
  oldStatus: MonitorStatus | null;
  newStatus: MonitorStatus;
  statusChangeId: string;
  statusChangeTime: string;
};
