import { Syncs } from "src/components/models/syncs";
import { AudiencePageOutletState } from "src/pages/audiences/types";
import { useOutletContext } from "src/router";

export const AudienceSyncs = () => {
  const { audience, parentModel } = useOutletContext<AudiencePageOutletState>();

  const syncs = audience?.syncs;

  return (
    <Syncs
      model={audience}
      parentModel={parentModel}
      syncs={syncs}
      modelId={audience.id}
      modelMatchboostingEnabled={Boolean(parentModel?.matchboosting_enabled)}
    />
  );
};
