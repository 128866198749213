import {
  Box,
  Column,
  ExternalLinkIcon,
  Paragraph,
  Row,
  SparkleIcon,
  Switch,
  Text,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as analytics from "src/lib/analytics";

import { FormkitModel } from "../formkit-context";
import { isConversionSyncType } from "src/utils/match-boosting";
import { DestinationDefinitionFragment } from "src/graphql";
import {
  LearnMoreToDocsLink,
  LearnMoreToMarketingLink,
  ModelSetUpLink,
} from "src/pages/syncs/sync/components/shared-match-booster";

export const MatchBoosterMappingToggle: React.FC<
  Readonly<{
    matchBoostingEnabled: boolean;
    mbEnabledForWorkspace: boolean;
    mbAvailableForSyncConfig: boolean;
    model: FormkitModel | undefined;
    workspaceId: number | undefined;
    syncId: number | undefined;
    onToggle: () => void;
    config: { type?: string; [x: string]: unknown };
    destinationDefinition: DestinationDefinitionFragment | undefined;
    isDisabled?: boolean;
  }>
> = ({
  matchBoostingEnabled,
  mbEnabledForWorkspace,
  mbAvailableForSyncConfig,
  model,
  syncId,
  workspaceId,
  onToggle,
  config,
  destinationDefinition,
  isDisabled = false,
}) => {
  const mbEnabledForModel = Boolean(
    model?.parent?.matchboosting_enabled || model?.matchboosting_enabled,
  );

  // Return nothing if the destination doesn't support MB or enrichment hasn't
  // ran yet, even if model has MB enabled, or if a sync is already created for
  // a non-MB workspace
  if (
    !mbAvailableForSyncConfig ||
    // We only want to display the up-sell for non-MB customers when creating a sync
    (!mbEnabledForWorkspace && syncId)
  ) {
    return null;
  }

  const mbAvailableToToggle =
    mbAvailableForSyncConfig && mbEnabledForWorkspace && mbEnabledForModel;

  const isConversionType = isConversionSyncType(
    config?.type,
    destinationDefinition?.type,
  );

  const modelId = model?.id;

  return (
    <Row
      border="1px"
      borderStyle="solid"
      borderRadius="6px"
      borderColor="gray.400"
      mt={3}
      mb={4}
      p={4}
      display="flex"
      gap={4}
    >
      <Column>
        <Switch
          isChecked={matchBoostingEnabled}
          onChange={onToggle}
          isDisabled={isDisabled || !mbAvailableToToggle}
        />
      </Column>

      <Column gap={2}>
        <Row gap={1} pt="2px">
          <Text
            color={!mbAvailableToToggle ? "text.secondary" : "text.primary"}
            fontWeight="medium"
          >
            {getTitle({
              mbAvailableToToggle,
              mbEnabledForWorkspace,
              isConversionType,
            })}
          </Text>
          <Box as={SparkleIcon} color="warning.400" fontSize="20px" />
        </Row>

        <Paragraph>
          {getContent({
            mbAvailableToToggle,
            mbEnabledForWorkspace,
            isConversionType,
          })}{" "}
          {mbAvailableToToggle ? (
            getLinkWhenMbAvailable({ isConversionType, workspaceId, modelId })
          ) : mbEnabledForWorkspace ? (
            <ModelSetUpLink model={model} />
          ) : (
            <LearnMoreToMarketingLink
              modelId={modelId}
              workspaceId={workspaceId}
              appComponent="MatchBoosterMappingToggle"
            />
          )}
        </Paragraph>
      </Column>
    </Row>
  );
};

const getTitle = ({
  mbAvailableToToggle,
  mbEnabledForWorkspace,
  isConversionType,
}: {
  mbAvailableToToggle: boolean;
  mbEnabledForWorkspace: boolean;
  isConversionType: boolean;
}) => {
  if (mbAvailableToToggle) return "Match Booster";

  if (isConversionType) {
    return mbEnabledForWorkspace
      ? "Enable Match Booster for better conversion performance"
      : "Increase your conversion performance!";
  }

  return mbEnabledForWorkspace
    ? "Enable Match Booster for higher audience reach"
    : "Increase your audience size!";
};

const getContent = ({
  isConversionType,
  mbAvailableToToggle,
  mbEnabledForWorkspace,
}: {
  isConversionType: boolean;
  mbAvailableToToggle: boolean;
  mbEnabledForWorkspace: boolean;
}) => {
  if (isConversionType) {
    return mbEnabledForWorkspace ? (
      <Text>
        Improve return on ad spend (ROAS) across conversion-focused campaigns by
        enhancing your event data with Hightouch’s cookieless identity graph.
      </Text>
    ) : (
      <Text>
        Improve return on ad spend (ROAS) across conversion-focused campaigns by
        enhancing your event data with Hightouch’s cookieless identity graph -
        feeding better data to the AI algorithms that manage budgets, bids, and
        targeting.
      </Text>
    );
  }

  return mbAvailableToToggle ? (
    <Text>
      Increase your audience match rates and reach by enhancing records with
      Hightouch’s cookieless identity graph.
    </Text>
  ) : (
    <Text>
      Increase your audience reach with Hightouch’s cookieless identity graph.
      Drive incremental revenue from broader targeting and reduce wasted spend
      with better suppression.
    </Text>
  );
};

const getLinkWhenMbAvailable = ({
  isConversionType,
  workspaceId,
  modelId,
}: {
  isConversionType: boolean;
  workspaceId: string | number | undefined;
  modelId: string | number | undefined;
}) => {
  if (isConversionType) {
    return (
      <Text
        onClick={() => {
          analytics.track(
            "Match Booster Conversion Performance Clicked In-App",
            {
              workspace_id: workspaceId?.toString(),
              model_id: modelId?.toString(),
              app_component: "MatchBoosterMappingToggle",
            },
          );
        }}
      >
        <Link
          href="https://hightouch.com/docs/match-booster/offerings#conversion-event-boosting"
          isExternal
        >
          Learn more about Match Booster for conversions
          <Box as={ExternalLinkIcon} ml={1} mb="1px" />
        </Link>
      </Text>
    );
  }

  return (
    <LearnMoreToDocsLink
      modelId={modelId}
      workspaceId={workspaceId}
      appComponent="MatchBoosterMappingToggle"
    />
  );
};
