import { parseEventSourceResourceId } from "@hightouch/lib/resource-monitoring/composite-ids";
import {
  Text,
  Drawer,
  Row,
  Column,
  Tabs,
  Tab,
  TabList,
  DrawerBody,
  Heading,
  Box,
  Avatar,
  Pill,
  Skeleton,
  IconButton,
  CloseIcon,
  DrawerFooter,
} from "@hightouchio/ui";
import { useNavigate, useParams } from "src/router";
import { eventTypeIconMap } from "./utils";
import { capitalize, omit } from "lodash";
import {
  EventSourceVolumeInterval,
  MonitorConditionEvaluationProperties,
  MonitorConditionTypes,
  MonitorStatus,
  MonitoredResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import {
  EventSchemaEventType,
  UpsertMonitorConditionsMutationVariables,
  ResourceMonitorConditionsQuery,
  useContractsForSourceQuery,
  useUpsertMonitorConditionsMutation,
  useMonitoredResourceStatusQuery,
  useResourceMonitorConditionsQuery,
} from "src/graphql";
import { formatDate } from "src/utils/time";
import { ConfigureAlertsForEventType } from "./components/configure-alerts-for-event-type";
import { EventTypeAlertsDetails } from "./components/event-type-alerts-details";
import * as y from "yup";
import { Static, Type } from "@sinclair/typebox";
import { yupResolver } from "@hookform/resolvers/yup";
import { Value } from "@sinclair/typebox/value";
import { useHightouchForm, Form, FormActions } from "src/components/form";

export const EventMonitorConditionTypes = [
  MonitorConditionTypes.EventSourceVolume,
] as const;

export const ConfigureEvent = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const resourceId = eventId;
  const navigate = useNavigate();
  const { eventName, eventType, eventSourceId } = parseEventSourceResourceId(
    resourceId ?? "",
  );

  if (
    !resourceId ||
    !eventType ||
    !eventSourceId ||
    // Track events are required to have name, all other types do not have a name
    (!eventName && eventType === EventSchemaEventType.Track)
  ) {
    navigate("../..");
    return <></>;
  }

  const Icon = eventType && eventTypeIconMap[eventType];

  const { data: contractForEvent } = useContractsForSourceQuery(
    { event_source_id: eventSourceId?.toString() },
    {
      select: (data) =>
        data.event_sources_by_pk?.event_plan?.event_schemas.find(
          (schema) =>
            schema.event_type === eventType &&
            schema.event_name === (eventName || null),
        ) || undefined,
    },
  );

  const { data: numAlerts, isLoading: alertsLoading } =
    useMonitoredResourceStatusQuery(
      {
        resourceId: resourceId,
        resourceType: MonitoredResourceType.EventSource,
        limit: 1,
      },
      {
        select: (data) => {
          const latestStatus = data.resource_monitor_statuses?.at(0);
          const activeAlerts =
            latestStatus?.new_status === MonitorStatus.Unhealthy
              ? (latestStatus?.condition_changes as string[])?.length
              : null;

          return activeAlerts;
        },
      },
    );
  const { mutateAsync: upsertMonitorConditions } =
    useUpsertMonitorConditionsMutation();

  const { data: conditions, isLoading: loadingConditions } =
    useResourceMonitorConditionsQuery({
      resourceId,
      resourceType: MonitoredResourceType.EventSource,
    });

  const closeDrawer = () => {
    navigate("..");
  };

  const formValues = apiMonitorsToFormState(
    conditions?.resource_monitor_conditions,
  );
  const form = useHightouchForm({
    values: formValues,
    resolver: yupResolver(
      y
        .object()
        .shape({ [MonitorConditionTypes.EventSourceVolume]: formValueSchema }),
    ),
    onSubmit: async (values) => {
      const args: UpsertMonitorConditionsMutationVariables["objects"] = [
        {
          ...formStateToApiMonitors(values),
          resource_id: resourceId,
          resource_type: MonitoredResourceType.EventSource,
          type: MonitorConditionTypes.EventSourceVolume,
          evaluation_trigger:
            MonitorConditionEvaluationProperties[
              MonitorConditionTypes.EventSourceVolume
            ].EvaluationTrigger,
          evaluation_type:
            MonitorConditionEvaluationProperties[
              MonitorConditionTypes.EventSourceVolume
            ].EvaluationType,
        },
      ];

      await upsertMonitorConditions({ objects: args });
    },
  });

  return (
    <Drawer isOpen onClose={closeDrawer}>
      <Column gap={2} p={4}>
        <Row alignItems="center" justifyContent="space-between">
          <Heading>{eventName}</Heading>
          <IconButton
            aria-label="Close drawer"
            icon={CloseIcon}
            onClick={closeDrawer}
          />
        </Row>
        {eventType && (
          <Row gap={1}>
            <Icon />
            <Text>{capitalize(eventType)}</Text>
            {contractForEvent && (
              <Box borderLeft="1px" borderLeftColor="base.border" px={2} mx={1}>
                Last updated: {formatDate(contractForEvent.updated_at)}{" "}
                {contractForEvent.updated_by_user && (
                  <>
                    by
                    <Avatar name={contractForEvent.updated_by_user.name} />
                  </>
                )}
              </Box>
            )}
          </Row>
        )}
      </Column>
      <Column>
        <Tabs>
          <TabList px={6}>
            <Tab>
              <Row px={4} gap={2} alignItems="baseline">
                <Text>Alerting</Text>
                {numAlerts && (
                  <Pill variant="danger" size="sm">
                    {numAlerts}
                  </Pill>
                )}
              </Row>
            </Tab>
          </TabList>
        </Tabs>
      </Column>

      <Form form={form}>
        <DrawerBody backgroundColor="base.lightBackground">
          <Skeleton isLoading={alertsLoading || loadingConditions}>
            <Column gap={4} pb={4}>
              <EventTypeAlertsDetails eventSourceResourceId={resourceId} />

              <ConfigureAlertsForEventType
                eventName={eventName}
                eventSourceId={eventSourceId}
                eventType={eventType}
              />
            </Column>
          </Skeleton>
        </DrawerBody>
        <DrawerFooter>
          <FormActions
            permission={{
              v1: { resource: "workspace", grant: "update" },
              v2: {
                resource: "workspace",
                grant: "can_update",
              },
            }}
          />
        </DrawerFooter>
      </Form>
    </Drawer>
  );
};

const apiMonitorsToFormState = (
  apiConditions:
    | ResourceMonitorConditionsQuery["resource_monitor_conditions"]
    | undefined,
) => {
  const volumeCondition = apiConditions?.find(
    (condition) =>
      condition.enabled &&
      condition.type === MonitorConditionTypes.EventSourceVolume,
  );

  const errorValue = volumeCondition?.error_value;

  const upperboundFormDefault = {
    sign: "gt" as const,
    count: 1e6,
    interval: "7 days" as const,
    enabled: false,
  };
  const lowerboundFormDefault = {
    sign: "lt" as const,
    count: 100,
    interval: "7 days" as const,
    enabled: false,
  };

  const existingUpperBound =
    isApiThresholdValue(errorValue) &&
    errorValue.find((threshold) => threshold.sign === "gt");
  const existingLowerBound =
    isApiThresholdValue(errorValue) &&
    errorValue.find((threshold) => threshold.sign === "lt");

  const upperThreshold =
    (existingUpperBound && { ...existingUpperBound, enabled: true }) ||
    upperboundFormDefault;
  const lowerThreshold =
    (existingLowerBound && { ...existingLowerBound, enabled: true }) ||
    lowerboundFormDefault;

  return {
    [MonitorConditionTypes.EventSourceVolume]: {
      gt: upperThreshold,
      lt: lowerThreshold,
    },
  };
};

const formStateToApiMonitors = (
  formState: ReturnType<typeof apiMonitorsToFormState>,
): {
  enabled: boolean;
  error_value: ApiThresholdValue;
} => ({
  enabled:
    formState.EventSourceVolume.gt.enabled ||
    formState.EventSourceVolume.lt.enabled,
  error_value: [formState.EventSourceVolume.gt, formState.EventSourceVolume.lt]
    .filter((value) => value.enabled)
    .map((value) => omit(value, "enabled")),
});

const formValueSchema = y
  .object({
    gt: y
      .object({
        enabled: y.boolean().required(),
        interval: y.string().required(),

        count: y.number().min(0).max(1e8).required(),
      })
      .required(),
    lt: y
      .object({
        enabled: y.boolean().required(),
        interval: y.string().required(),
        count: y.number().min(0).max(1e8).required(),
      })
      .required(),
  })
  .required();

/**
 * Why a different validation library?
 *
 * Well, you see. The types that we get out of Yup schemas are broken as of right now;
 * all you get is super generic `object` variants. This means that if I try to use a
 * yup schema on these incoming types from the API, the types I get out of it are useless.
 * We're insulated from the problem by our form validation tools, but if I try to use
 * these schemas directly, they fall apart.
 * This problem will be fixed if we can swing an upgrade of yup, but the codebase is
 * quite behind and upgrading is becoming a time sink.
 *
 * Why not use this library for all form validation?
 *
 * Similar issue. We could, but we'd have to upgrade https://github.com/react-hook-form/resolvers
 * which is used all over for our existing yup forms, and the upgrade includes significant
 * breaking changes.
 */
const apiThresholdValueSchema = Type.Array(
  Type.Object({
    sign: Type.Union([Type.Literal("gt"), Type.Literal("lt")]),
    interval: Type.Union(EventSourceVolumeInterval.map((i) => Type.Literal(i))),
    count: Type.Number({ minimum: 0, maximum: 1e8 }),
  }),
);

type ApiThresholdValue = Static<typeof apiThresholdValueSchema>;

const isApiThresholdValue = (v: unknown): v is ApiThresholdValue =>
  Value.Check(apiThresholdValueSchema, v);
