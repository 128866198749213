import { useMemo } from "react";
import {
  MonitorConditionTypes,
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

import {
  useResourceTypeMonitorConditionsQuery,
  useSubscribeableResourcesQuery,
} from "src/graphql";

/*
    Returns an object with the following shape:
    {
        [conditionType]: {
            destinations: {
                [destinationId]: [syncId, ...]
            }
        }
    }
    Where syncIds are the ids of the syncs that override the condition for the given destination
*/
export const useOverridesState = (destinationIds: string[]) => {
  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const { data } = useResourceTypeMonitorConditionsQuery(
    {
      resourceType: MonitoredResourceType.Sync,
      parentResourceType: ParentResourceTypes.Destination,
      includeTemplates: true,
    },
    { suspense: true },
  );

  const getSyncIdsForDestination = (destinationId: string) => {
    return syncsAndDestinations?.destinations
      ?.find((d) => d.id.toString() === destinationId)
      ?.syncs.map((s) => s.id);
  };

  const getSyncCount = (destinationIds: string[]) => {
    let count = 0;
    for (const destination of syncsAndDestinations?.destinations?.filter((d) =>
      destinationIds.includes(d.id.toString()),
    ) ?? []) {
      count += destination.syncs_aggregate.aggregate?.count ?? 0;
    }
    return count;
  };

  const getOverrides = (
    conditionType: MonitorConditionTypes,
    destinationIds: string[],
  ) => {
    const syncIds = destinationIds
      .flatMap(getSyncIdsForDestination)
      .map(String);
    return data?.resource_monitor_conditions
      .filter(
        (c) => c.type === conditionType && syncIds.includes(c.resource_id),
      )
      .map((condition) => ({
        condition,
        sync: syncsAndDestinations?.syncs.find(
          (s) => s.id.toString() === condition.resource_id,
        ),
      }));
  };

  const getOverrideCount = (
    conditionType: MonitorConditionTypes,
    destinationIds: string[],
  ) => {
    return getOverrides(conditionType, destinationIds)?.length ?? 0;
  };

  return useMemo(() => {
    return {
      getOverrides,
      getSyncCount,
      getOverrideCount,
    };
  }, [destinationIds, data, syncsAndDestinations]);
};
