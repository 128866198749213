import { FC, useMemo, useState } from "react";

import { AudienceSplit } from "@hightouch/lib/query/visual/types";
import {
  Box,
  Column,
  Row,
  Skeleton,
  SkeletonBox,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";

import { orderBy } from "lodash";
import placeholder from "src/assets/placeholders/sync.svg";
import { AddSyncButton } from "src/components/audiences/add-sync/add-sync-button";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { SyncRunStatusBadge } from "src/components/syncs/sync-run-status-badge";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  ModelWithoutColumnsQuery,
  useDestinationMetadataQuery,
  useIsDestinationMetadataQuery,
  useSyncsLabelsQuery,
} from "src/graphql";
import { ParentModel } from "src/pages/audiences/types";
import { DestinationCell } from "src/pages/syncs/components/destination-cell";
import {
  calculateAudienceSizeValues,
  processAudienceSizeComponent,
} from "src/pages/syncs/sync/components/external-data";
import { isSyncMatchBoosted } from "src/pages/syncs/sync/matchbooster";
import { Link, LinkButton, useNavigate } from "src/router";
import { isPresent } from "src/types/utils";
import { Audience } from "src/types/visual";
import { Table, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import {
  isAudienceStatusProcessing,
  isMatchedUsersCountSettling,
} from "src/utils/match-boosting";
import { formatDatetime } from "src/utils/time";
import { openUrl } from "src/utils/urls";

type Props = {
  modelId: string;
  parentModel?: NonNullable<ParentModel>;
  model?: NonNullable<Audience>;
  syncs?: NonNullable<ModelWithoutColumnsQuery["segments_by_pk"]>["syncs"][0][];
  // Because the model above is actually sometimes an audience and it's often not
  // provided, it's too difficult to extract the matchbooster enabled status from
  // the model. So we just pass it in here.
  modelMatchboostingEnabled: boolean;
};

enum SortKeys {
  Destination = "destination",
  Status = "status",
  LastUpdated = "updated_at",
}

export const Syncs: FC<Readonly<Props>> = ({
  parentModel,
  model,
  syncs,
  modelMatchboostingEnabled,
  modelId,
}) => {
  const navigate = useNavigate();

  const isAudience = Boolean(parentModel);
  const showSplitsColumn = isAudience;
  const [showExternalColumn, setShowExternalColumn] = useState(false);

  const { orderBy, onSort } = useTableConfig<{
    [key in SortKeys]: "asc" | "desc";
  }>({
    sortOptions: Object.values(SortKeys),
  });

  const syncsLabelsQuery = useSyncsLabelsQuery(
    {
      filters: { segment_id: { _eq: model?.id } },
      limit: 100,
      offset: 0,
    },
    {
      select: (data) => data.syncs,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const sortedSyncs = useMemo(
    () => getSortedSyncs(syncs ?? [], orderBy),
    [syncs, orderBy],
  );

  const columns: TableColumn[] = [
    {
      sortDirection: orderBy?.status,
      onClick: () => onSort(SortKeys.Status),
      name: "Status",
      min: "148px",
      max: "148px",
      cell: ({ status, sync_requests, draft: isInitialDraft }) => {
        if (isInitialDraft) {
          return <DraftBadge />;
        }

        const syncRequest = sync_requests?.[0];
        const request = syncRequest ? syncRequest : { status_computed: status };
        return <SyncRunStatusBadge request={request} status={status} />;
      },
    },
    {
      name: "Destination",
      max: "1.5fr",
      sortDirection: orderBy?.destination,
      onClick: () => onSort(SortKeys.Destination),
      cell: ({ id, description, config, destination }) => {
        useIsDestinationMetadataQuery(
          { id },
          {
            select: (data) => data.supportsDestinationMetadata,
            onSuccess: (data) => {
              if (data) {
                setShowExternalColumn(true);
              }
            },
          },
        );

        const name = destination?.name;
        const definition = destination?.definition ?? {};
        const syncBoosted = isSyncMatchBoosted(config);
        const metadata = syncsLabelsQuery.data?.find(
          (s) => s.id === id,
        )?.labels;

        return (
          <DestinationCell
            destinationName={name ?? ""}
            syncDescription={description}
            metadata={metadata}
            definition={definition}
            isSyncBoosted={syncBoosted}
          />
        );
      },
    },
    Boolean(showExternalColumn) && {
      name: "Matched users",
      cell: ({ id, config, first_sync_request }) => {
        const { data: destinationMetadata, isLoading } =
          useDestinationMetadataQuery(
            {
              id,
            },
            { select: (data) => data.getDestinationMetadata },
          );

        const { matchPercent, matchedUserCount } = calculateAudienceSizeValues(
          destinationMetadata?.audienceSize,
          destinationMetadata?.matchRate,
          config.useExistingSegment,
          config.deleteMode,
        );

        const audienceIsProcessing =
          isAudienceStatusProcessing(destinationMetadata);
        const matchedCountIsSettling = isMatchedUsersCountSettling(
          first_sync_request?.[0]?.created_at,
          destinationMetadata,
          config.useExistingSegment,
        );
        const syncMatchboosterEnabled = isSyncMatchBoosted(config);

        if (isLoading) {
          return (
            <Skeleton isLoading={isLoading}>
              <SkeletonBox height="14px" width="100px" />
            </Skeleton>
          );
        }

        if (
          destinationMetadata?.audienceSize &&
          destinationMetadata.audienceSize >= 0
        ) {
          return (
            <Tooltip
              message={
                <Column>
                  {modelMatchboostingEnabled && syncMatchboosterEnabled && (
                    <Row gap={1}>
                      <Text color="white" fontWeight="medium">
                        Match Booster:
                      </Text>
                      <Text color="success.border" fontWeight="normal">
                        Enabled
                      </Text>
                    </Row>
                  )}
                  <Row gap={1}>
                    <Text color="white" fontWeight="medium">
                      Matched users:
                    </Text>
                    <Text color="white" fontWeight="normal">
                      {audienceIsProcessing ? "--" : matchedUserCount}
                    </Text>
                  </Row>
                  <Row gap={1}>
                    <Text color="white" fontWeight="medium">
                      Match rate:
                    </Text>
                    <Text color="white" fontWeight="normal">
                      {audienceIsProcessing ? "--" : matchPercent || "--"}
                    </Text>
                  </Row>
                  {matchedCountIsSettling && (
                    <Row textAlign="left">
                      <Text color="white">
                        Warning: Matched users count may still be updating
                      </Text>
                    </Row>
                  )}
                </Column>
              }
            >
              <Box display="flex" gap={1}>
                {audienceIsProcessing
                  ? "Processing... check back later."
                  : processAudienceSizeComponent(
                      destinationMetadata?.audienceSize,
                      destinationMetadata?.matchRate,
                      config.useExistingSegment,
                      config.deleteMode,
                    )}

                {matchedCountIsSettling && (
                  <Box
                    as={WarningIcon}
                    color="warning.border"
                    fontSize="16px"
                  />
                )}
              </Box>
            </Tooltip>
          );
        }

        return "--";
      },
    },
    {
      name: "Last run",
      max: "max-content",
      cell: ({ id, sync_requests }) => {
        const syncRequest = sync_requests?.[0];
        return syncRequest ? (
          <>
            <Link href={`/syncs/${id}/runs/${syncRequest?.id}`}>
              <Text fontWeight="medium">
                {formatDatetime(syncRequest?.created_at)}
              </Text>
            </Link>
          </>
        ) : null;
      },
    },
    {
      sortDirection: orderBy?.updated_at,
      onClick: () => onSort(SortKeys.LastUpdated),
      ...LastUpdatedColumn,
    },
    !!showSplitsColumn && {
      name: "Splits",
      max: "1fr",
      cell: ({ destination_instance_splits }) => {
        if (destination_instance_splits.length === 0) {
          return "--";
        }

        const splitsText = destination_instance_splits.map(
          ({ split }: { split: AudienceSplit }) =>
            `${split.friendly_name} (${split.percentage}%)`,
        );

        return <TextWithTooltip>{splitsText.join(", ")}</TextWithTooltip>;
      },
    },
  ].filter(isPresent);

  return (
    <Table
      columns={columns}
      data={sortedSyncs}
      rowHeight="80px"
      placeholder={{
        title: `This ${
          isAudience ? "audience" : "model"
        } is not syncing to any destinations`,
        body: (
          <Text whiteSpace="nowrap">
            Still working on this? Add a sync when you’re ready.
          </Text>
        ),
        image: placeholder,
        button:
          isAudience && model ? (
            <AddSyncButton
              audience={model}
              parentModel={parentModel}
              variant="primary"
            />
          ) : (
            <LinkButton variant="primary" href={`/syncs/new?model=${modelId}`}>
              Add sync
            </LinkButton>
          ),
      }}
      onRowClick={({ id }, event) => openUrl(`/syncs/${id}`, navigate, event)}
    />
  );
};

const Selectors: Record<SortKeys, string> = {
  [SortKeys.Destination]: "destination.name",
  [SortKeys.LastUpdated]: "updated_at",
  [SortKeys.Status]: "status",
};

function getSortedSyncs(
  syncs: NonNullable<ModelWithoutColumnsQuery["segments_by_pk"]>["syncs"][0][],
  orderByCol?: { [key in SortKeys]: "asc" | "desc" },
) {
  if (!syncs || !orderByCol) {
    return syncs;
  }

  if (orderByCol.destination) {
    return orderBy(
      syncs,
      [Selectors[SortKeys.Destination]],
      [orderByCol.destination],
    );
  }

  if (orderByCol.status) {
    return orderBy(syncs, [Selectors[SortKeys.Status]], [orderByCol.status]);
  }

  if (orderByCol.updated_at) {
    return orderBy(
      syncs,
      [Selectors[SortKeys.LastUpdated]],
      [orderByCol.updated_at],
    );
  }
  return syncs;
}
