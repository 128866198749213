import {
  Button,
  ButtonGroup,
  Column,
  Dialog,
  FormField,
  TextInput,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { useNavigate } from "src/router";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { UserGroupSelect } from "src/components/user-group-select";
import { useUser } from "src/contexts/user-context";
import { useSendOrganizationInviteMutation } from "src/graphql";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is not valid"),
  userGroupIds: Yup.array()
    .of(Yup.number())
    .required("User must be assigned to at least one group"),
});

export const InviteUserModal = () => {
  const { user } = useUser();
  const inviteMutation = useSendOrganizationInviteMutation();

  const onClose = () => {
    navigate("/organization/members");
  };

  const form = useHightouchForm({
    onSubmit: async ({ email, userGroupIds }) => {
      await inviteMutation.mutateAsync({
        recipientEmail: email,
        organizationId: String(user!.organization!.id),
        recipientUserGroupIds: userGroupIds.map(String),
      });
      onClose();
    },
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: "",
      userGroupIds: [],
    },
  });

  const navigate = useNavigate();

  return (
    <Form form={form}>
      <Dialog
        isOpen
        variant="form"
        title="Invite user"
        actions={
          <ButtonGroup>
            <Button onClick={onClose}>Cancel</Button>
            <SaveButton size="md">Send invite</SaveButton>
          </ButtonGroup>
        }
        onClose={onClose}
      >
        <Column gap={6}>
          <Controller
            name="email"
            render={({ field, fieldState }) => {
              return (
                <FormField
                  label="Email address"
                  error={fieldState.error?.message}
                >
                  <TextInput placeholder="user@company.com" {...field} />
                </FormField>
              );
            }}
          />
          <Controller
            name="userGroupIds"
            render={({ field, fieldState }) => {
              return (
                <FormField
                  label="Assign to groups"
                  error={fieldState?.error?.message}
                >
                  <UserGroupSelect
                    organizationId={user!.organization!.id}
                    onChange={field.onChange}
                    value={field.value}
                  />
                </FormField>
              );
            }}
          />
        </Column>
      </Dialog>
    </Form>
  );
};
