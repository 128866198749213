import { Alert } from "@hightouchio/ui";
import { PermissionedLinkButton } from "src/components/permission";

export const OrgSettingsBanner = () => (
  <Alert
    type="info"
    title="Organization settings have moved!"
    message="We’ve made it easier to manage your team in Hightouch. Head over to the new organization settings page to invite team members, assign permissions, and more."
    actions={
      <PermissionedLinkButton
        organizationPermission={{ resource: "organization" }}
        href="/organization"
        variant="primary"
      >
        View organization settings
      </PermissionedLinkButton>
    }
  />
);
