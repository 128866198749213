import * as Yup from "yup";
import { getZerothIndexAsDefault } from ".";
import {
  type AssociationMappingsComponent,
  type AssociationMappingsProps,
  type CheckboxComponent,
  type CheckboxProps,
  type ColumnComponent,
  type ColumnOrConstantComponent,
  type ColumnOrConstantProps,
  type ColumnProps,
  ComponentType,
  type EditorComponent,
  type EditorProps,
  type InputComponent,
  type InputProps,
  type MappingComponent,
  type MappingProps,
  type MappingsComponent,
  type MappingsProps,
  NodeType,
  type RadioGroupComponent,
  type RadioGroupProps,
  type RichTextEditorComponent,
  type RichTextEditorProps,
  type SecretComponent,
  type SecretProps,
  type SelectComponent,
  type SelectProps,
  type SwitchComponent,
  type SwitchProps,
} from "../api";
import type { ButtonComponent, ButtonProps } from "../api/components/button";
import type { CodeComponent, CodeProps } from "../api/components/code";
import type {
  CollapsibleComponent,
  CollapsibleProps,
} from "../api/components/collapsible";
import type { FileComponent, FileProps } from "../api/components/files";
import type {
  GooglePickerComponent,
  GooglePickerProps,
} from "../api/components/google-picker";
import type {
  KeyValueMappingComponent,
  KeyValueMappingProps,
} from "../api/components/key-value-mapping";
import type { MessageComponent, MessageProps } from "../api/components/message";
import type {
  NestedCheckboxGroupComponent,
  NestedCheckboxGroupProps,
} from "../api/components/nested-checkbox-group";
import type {
  NestedRadioGroupComponent,
  NestedRadioGroupProps,
} from "../api/components/nested-radio-group";
import type { TableComponent, TableProps } from "../api/components/table";
import type {
  TextAreaComponent,
  TextAreaProps,
} from "../api/components/text-area";
import { commonFnInfo } from "./functions";
import { COMMON_SCHEMAS } from "./schemas";
import type { TunnelTableComponent } from "../api/components/tunnel-table";
import type { LabelComponent, LabelProps } from "../api/components/label";

export function Checkbox(key: string, props: CheckboxProps): CheckboxComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Checkbox,
    key,
    props: { validation: Yup.boolean().notRequired(), ...props },
  };
}

export function Switch(key: string, props: SwitchProps): SwitchComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Switch,
    key,
    props: { validation: Yup.boolean().notRequired(), ...props },
  };
}

export function Input(key: string, props: InputProps): InputComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Input,
    key,
    // Input components vary a decent amount in their required validation schemas,
    // so left the default validation pretty flexible
    props: { validation: Yup.mixed().notRequired(), ...props },
  };
}

export function TextArea(key: string, props: TextAreaProps): TextAreaComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Textarea,
    key,
    props: { validation: Yup.string().optional(), ...props },
  };
}

export function Mapping(key: string, props: MappingProps): MappingComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Mapping,
    key,
    props: {
      default: {},
      validation: COMMON_SCHEMAS.standardMapping,
      templates: props?.advanced ? commonFnInfo : undefined,
      ...props,
    },
  };
}

// Note: Accepts mustHaveOne prop - an array of arrays that contains a list of strings in which one of the
// strings is required
// Example: A destination that requires an accountId, organizationId, or userId and messageId or timestamp
// would translate to [[“accountId”, “organizationId”, “userId”], [“messageId”, “timestamp”]]
export function Mappings(key: string, props: MappingsProps): MappingsComponent {
  const templates = [...(props.templates || []), ...commonFnInfo];

  // Display error output using the option labels rather than their values
  // Defaults to the value if a corresponding label cannot be found
  const mustHaveLabels: string[][] = Array.isArray(props.mustHaveOne)
    ? props.mustHaveOne.map((arr) =>
        arr.map(
          (mustHave) =>
            (Array.isArray(props.options)
              ? props.options.find(({ value }) => value === mustHave)?.label
              : undefined) ?? mustHave,
        ),
      )
    : [[]];

  return {
    type: NodeType.Component,
    component: ComponentType.Mappings,
    key,
    props: {
      default: props.required
        ? [{ from: undefined, to: undefined, type: "standard" }]
        : [],
      validation: props.mustHaveOne
        ? COMMON_SCHEMAS.mappings.test(
            "at-least-one-of-required-fields-present",
            `Must specify at least one of: ${mustHaveLabels
              .map((arr) => arr.join(", ").replace(/, ([^,]*)$/, " or $1"))
              .join(" and ")}`,
            (mappings: []) => {
              return props.mustHaveOne?.every((arr) => {
                return mappings.find(({ to }) => arr.includes(to));
              });
            },
          )
        : COMMON_SCHEMAS.mappings,
      advanced: true,
      ...props,
      templates,
    },
  };
}

export function AssociationMappings(
  key: string,
  props: AssociationMappingsProps,
): AssociationMappingsComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.AssociationMappings,
    key,
    props: { validation: COMMON_SCHEMAS.associationMappings, ...props },
  };
}

export function Column(key: string, props?: ColumnProps): ColumnComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Column,
    key,
    props: {
      validation: props?.advanced
        ? COMMON_SCHEMAS.advancedColumn
        : COMMON_SCHEMAS.column,
      templates: props?.advanced ? commonFnInfo : undefined,
      ...props,
    },
  };
}

export function ColumnOrConstant(
  key: string,
  props?: ColumnOrConstantProps,
): ColumnOrConstantComponent {
  let zerothIndex;

  if (props?.constantComponentType === ComponentType.RadioGroup) {
    const { defaultValue } = getZerothIndexAsDefault(props);
    zerothIndex = defaultValue;
  }

  return {
    type: NodeType.Component,
    component: ComponentType.ColumnOrConstant,
    key,
    props: {
      default: zerothIndex,
      validation: props?.multi
        ? COMMON_SCHEMAS.columnOrConstantMulti
        : COMMON_SCHEMAS.columnOrConstant,
      ...props,
    },
  };
}

export function RadioGroup(
  key: string,
  props: RadioGroupProps,
): RadioGroupComponent {
  let defaultValue;
  if (!props.disableDefault) {
    defaultValue = getZerothIndexAsDefault(props).defaultValue;
  }

  return {
    type: NodeType.Component,
    component: ComponentType.RadioGroup,
    key,
    props: {
      default: defaultValue,
      validation: Yup.string().notRequired(),
      ...props,
    },
  };
}

export function Select(key: string, props: SelectProps): SelectComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Select,
    key,
    props: {
      validation: Yup.string().required(),
      ...props,
    },
  };
}

export function Editor(key: string, props: EditorProps): EditorComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Editor,
    key,
    props: {
      placeholder: props.placeholder ?? "{{ 'Enter message here' }}",
      language: props.language ?? "liquid",
      theme: "sqlserver",
      ...props,
    },
  };
}

export function RichTextEditor(
  key: string,
  props: RichTextEditorProps,
): RichTextEditorComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.RichTextEditor,
    key,
    props: {
      placeholder: props.placeholder ?? "{{ 'Enter message here' }}",
      ...props,
    },
  };
}

export function Secret(key: string, props: SecretProps): SecretComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Secret,
    key,
    props,
  };
}

export function File(key: string, props: FileProps): FileComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.File,
    key,
    props,
  };
}

export function Code(props: CodeProps): CodeComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Code,
    key: "_code", //Dummy key as we don't really need it, as this component is not an input.
    props,
  };
}

export function Textarea(key: string, props: TextAreaProps): TextAreaComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Textarea,
    key,
    props,
  };
}

export function Message(props: MessageProps): MessageComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Message,
    key: "_message", //Dummy key as we don't really need it, as this component is not an input.
    props,
  };
}

export function Label(props: LabelProps): LabelComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Label,
    key: "_label", //Dummy key as we don't really need it, as this component is not an input.
    props,
  };
}

export function Button(props: ButtonProps): ButtonComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Button,
    key: "_button", //Dummy key as we don't really need it, as this component is just an action.
    props,
  };
}

export function KeyValueMapping(
  key: string,
  props: KeyValueMappingProps,
): KeyValueMappingComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.KeyValueMapping,
    key,
    props,
  };
}

export function Collapsible(
  key: string,
  props: CollapsibleProps,
): CollapsibleComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Collapsible,
    key,
    props: {
      ...props,
    },
    children: props.children,
  };
}

export function NestedRadioGroup(
  key: string,
  props: NestedRadioGroupProps,
): NestedRadioGroupComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.NestedRadioGroup,
    key,
    props: {
      /**
       * The field key that represents the nested path to reach the child option.
       * For example, given a listKey, "accountPath", and a nested option like:
       * () - A
       *  () - B
       *    (x) - C
       * () - D
       * by selecting option C, the "accountPath" should expect this array: ["A", "B", "C"].
       */
      listKey: `${key}List`,
      validation: Yup.string().required(),
      ...props,
    },
  };
}

export function Table(key: string, props: TableProps): TableComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.Table,
    key,
    props,
  };
}

export function GooglePicker(
  key: string,
  props: GooglePickerProps,
): GooglePickerComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.GooglePicker,
    key,
    props,
  };
}

export function TunnelTable(
  key: string,
  props: TableProps,
): TunnelTableComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.TunnelTable,
    key,
    props,
  };
}

export function NestedCheckboxGroup(
  key: string,
  props: NestedCheckboxGroupProps,
): NestedCheckboxGroupComponent {
  return {
    type: NodeType.Component,
    component: ComponentType.NestedCheckboxGroup,
    key,
    props,
  };
}
