import {
  MonitorConditionProperties,
  MonitorConditionTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import { ThresholdType } from "src/components/resource-alert-triggers/utils";
import { NumberInputField } from "./fields/number-input-field";
import { Value } from "./utils";

type IntervalType = ThresholdType<
  MonitorConditionProperties[MonitorConditionTypes.EventSourceVolume]["Threshold"]
>[number]["interval"];

export const EventSourceVolumeField = ({ name }) => {
  const { watch, control } = useFormContext();

  const count = watch(`${name}.count`);

  const intervalOptions: {
    label: string;
    value: IntervalType;
  }[] = [
    { label: "15 minutes", value: "15 minutes" },
    { label: "4 hours", value: "4 hours" },
    { label: "1 day", value: "1 day" },
    { label: "1 hour", value: "1 hour" },
    { label: "2 days", value: "2 days" },
    { label: "7 days", value: "7 days" },
  ] as const;

  return (
    <Row gap={1} align="center" flexWrap="wrap">
      <NumberInputField name={`${name}.count`} />
      <Text mx={2}>{count === 1 ? "event" : "events"} received in</Text>
      <Controller
        name={`${name}.interval`}
        control={control}
        render={({ field: { value, onChange } }) => (
          <Select
            value={value}
            size="sm"
            width="auto"
            options={intervalOptions}
            onChange={onChange}
          />
        )}
      />
    </Row>
  );
};

export const EventSourceVolumeSummary = ({ condition }) => {
  return (
    <>
      <Value>{condition.count}</Value> events received in{" "}
      <Value>{condition.interval}</Value>
    </>
  );
};
