import { Box, NumberInput, Row, Text } from "@hightouchio/ui";
import { Controller } from "react-hook-form";

import { Term } from "src/components/term";
import { valueIsEmpty } from "src/components/resource-alert-triggers/utils";
import { ConditionSummary, Critical, Value, Warning } from "./utils";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";

export const SyncSequentialFailuresField = ({ name }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Row align="center" flexWrap="wrap" gap={1}>
          <Box
            isInvalid={Boolean(error)}
            as={NumberInput}
            size="sm"
            display="inline-flex"
            width="50px"
            value={value}
            onChange={onChange}
          />{" "}
          <Text>or more consecutive sync runs with</Text>
          <Term message="A fatal error occurs when a sync experiences a problem that prevents it from running. (e.g., expired source or destination credentials, invalid SQL syntax, network issues, etc.)">
            fatal errors
          </Term>
        </Row>
      )}
    />
  );
};

const Summary = ({ value }) => {
  return (
    <>
      alert if <Value>{value}</Value> or more consecutive sync runs with fatal
      errors.
    </>
  );
};

export const SyncSequentialFailuresSummary: ConditionSummary<number> = ({
  prefix = true,
  condition,
}) => {
  return (
    <>
      {!valueIsEmpty(
        MonitorConditionTypes.SyncSequentialFailures,
        condition.error_value,
      ) && (
        <Critical prefix={prefix}>
          <Summary value={condition.error_value} />
        </Critical>
      )}
      {!valueIsEmpty(
        MonitorConditionTypes.SyncSequentialFailures,
        condition.warning_value,
      ) && (
        <Warning prefix={prefix}>
          <Summary value={condition.warning_value} />
        </Warning>
      )}
    </>
  );
};
