import { useMemo } from "react";

import { FormkitComponent, RelationshipHierarchy } from "@hightouch/formkit";
import get from "lodash/get";
import noop from "lodash/noop";
import uniq from "lodash/uniq";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useFormkitContext } from "src/formkit/components/formkit-context";

import { OVERRIDE_CONFIG_FORM_KEY } from "./constants";

/**
 * Get all the ancestor or children keys recursively given a relationship diagram.
 */
export function getParentOrChildrenKeysRecursively({
  field,
  relationshipHierarchy,
  visited = new Set(),
}: {
  field: [fieldName: string, "ancestors" | "children"];
  relationshipHierarchy: RelationshipHierarchy;
  visited?: Set<string>;
}): string[] {
  const [key, relationship] = field;
  const node = get(relationshipHierarchy, key);

  if (visited.has(key)) {
    return [];
  }
  visited.add(key);

  if (relationship === "children") {
    if (!node.children) {
      return [];
    }

    return Array.from(node.children).flatMap((key) => [
      key,
      ...getParentOrChildrenKeysRecursively({
        field: [key, "children"],
        relationshipHierarchy,
        visited,
      }),
    ]);
  }

  if (node.ancestors.size === 0) {
    return [];
  }

  const result: string[] = [];
  for (const parentKey of node.ancestors) {
    result.push(
      parentKey,
      ...getParentOrChildrenKeysRecursively({
        field: [parentKey, "ancestors"],
        relationshipHierarchy,
        visited,
      }),
    );
  }

  return uniq(result);
}

/**
 * A hook that determines the rules around locking a field when using a sync template.
 *
 * @param node The formkit component
 */
export function useSyncTemplateLockingRules(
  node: FormkitComponent,
  numberOfSyncLevelOverrides: number = 0,
): {
  disabledMessage: string | { label: string; children: string[] } | null;
  onUnlockChildNodes: (parentKey: string) => void;
} {
  const { allowOverrides, relationshipHierarchy } = useFormkitContext();
  const form = useFormContext();

  // TODO(samuel): Unlocking all nodes, regardless of visibility, will end up showing the sidebar
  // in the sync details page since that depends on keys existing in the override config, regardless of node visibility.
  // This needs more thought.
  // Response: add a util that checks to see which nodes are visible. If none, then don't show the form.
  const unlockAllChildNodes = (parentKey: string) => {
    if (!relationshipHierarchy) {
      return;
    }

    const childrenToUnlock = getParentOrChildrenKeysRecursively({
      field: [parentKey, "children"],
      relationshipHierarchy,
    });

    if (childrenToUnlock.length > 0) {
      form.setValue(
        OVERRIDE_CONFIG_FORM_KEY,
        {
          ...form.getValues(OVERRIDE_CONFIG_FORM_KEY),
          // Set all the children to be
          ...childrenToUnlock.reduce((all, child) => {
            all[child] = { overridable: true };
            return all;
          }, {}),
        },
        { shouldDirty: true },
      );
    }
  };

  if (!allowOverrides || !relationshipHierarchy) {
    return { disabledMessage: null, onUnlockChildNodes: unlockAllChildNodes };
  }

  if (numberOfSyncLevelOverrides > 0) {
    return {
      disabledMessage:
        "To lock this field, first clear all sync-level overrides",
      onUnlockChildNodes: noop,
    };
  }

  // TODO(samuel):
  // Not allowed to edit a field's _value_ if:
  // 1. Children fields have overrides

  const overrides = form.watch(OVERRIDE_CONFIG_FORM_KEY);
  const ancestorFields = useMemo(
    () =>
      getParentOrChildrenKeysRecursively({
        field: [node.key, "ancestors"],
        relationshipHierarchy,
      }),
    [node.key, relationshipHierarchy],
  );

  const hasParentRelationships = ancestorFields.length > 0;

  if (hasParentRelationships) {
    // This field may not be overriden if a child is unlocked
    const hasUnlockedParent = ancestorFields.some(
      (parentKey) => overrides?.[parentKey]?.overridable,
    );

    if (hasUnlockedParent) {
      return {
        disabledMessage: {
          label: "To lock this field, first lock all parent fields",
          children: ancestorFields
            .map((parentKey) => relationshipHierarchy[parentKey]?.heading)
            .filter(isPresent),
        },
        onUnlockChildNodes: unlockAllChildNodes,
      };
    }
  }

  return { disabledMessage: null, onUnlockChildNodes: unlockAllChildNodes };
}
