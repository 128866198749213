import ELK, { ElkNode } from "elkjs/lib/elk.bundled.js";
import sortBy from "lodash/sortBy";
import { Edge, Node } from "reactflow";

import {
  BASE_NODE_HEIGHT,
  JOURNEY_BRANCH_NODES,
  MAX_NODE_HEIGHT,
  MINI_NODE_MIN_HEIGHT,
  NODE_SPACING,
  NODE_WIDTH,
  VISIBLE_SYNC_COUNT,
} from "src/pages/journeys/constants";
import { JourneyNodeConfig, JourneyNodeType } from "src/types/journeys";
import { JourneyNodeDetails } from "src/pages/journeys/types";

const getSizeOfNodeByType = (node: JourneyNodeDetails<JourneyNodeConfig>) => {
  switch (node.config.type) {
    case JourneyNodeType.Sync:
      return (
        BASE_NODE_HEIGHT +
        (node.sync_configs?.slice(0, VISIBLE_SYNC_COUNT).length ?? 0) * 25
      );
    case JourneyNodeType.EntryCohort:
    case JourneyNodeType.WaitUntilEvent:
      return MAX_NODE_HEIGHT;
    default:
      return BASE_NODE_HEIGHT;
  }
};

export const reposition = async (
  nodes: Node[],
  edges: Edge[],
): Promise<Node[]> => {
  const elk = new ELK();

  const sortedNodes = sortNodesByTypeAndPriority(nodes);

  const elkEdges = edges.map((edge) => {
    return {
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    };
  });

  const graph: ElkNode = {
    id: "root",
    // https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
    layoutOptions: {
      "elk.layered.nodePlacement.bk.fixedAlignment": "RIGHTDOWN",
      "elk.algorithm": "layered",
      "elk.direction": "DOWN",
      "elk.layered.spacing.nodeNodeBetweenLayers": `${NODE_SPACING}`,
      "elk.layered.spacing.nodeNode": "30",
      "elk.layered.layering.strategy": "INTERACTIVE",
      "elk.layered.considerModelOrder.strategy": "NODES_AND_EDGES",
      "elk.layered.nodePlacement.strategy": "LINEAR_SEGMENTS",
    },
    children: sortNodesByTypeAndPriority(sortedNodes).map((node) => ({
      id: node.id,
      width: NODE_WIDTH,
      height: JOURNEY_BRANCH_NODES.includes(node.data.config.type)
        ? MINI_NODE_MIN_HEIGHT
        : (getSizeOfNodeByType(node.data) ?? MAX_NODE_HEIGHT),
    })),
    edges: elkEdges,
  };

  const layout = await elk.layout(graph);

  const repositionedNodes: Node[] = [];

  for (const node of sortedNodes) {
    const repositionedNode = layout.children?.find((n) => n.id === node.id);

    repositionedNodes.push({
      ...node,
      position: { x: repositionedNode?.x || 0, y: repositionedNode?.y || 0 },
    });
  }

  return repositionedNodes;
};

const sortNodesByTypeAndPriority = (nodes: Node[]): Node[] =>
  sortBy(nodes, [
    (n) => {
      switch (n.type) {
        case JourneyNodeType.EntryCohort:
          return 1;
        case JourneyNodeType.TimeDelay:
          return 2;
        case JourneyNodeType.WaitUntilEvent:
          return 3;
        case JourneyNodeType.WaitUntilEventBranch:
          return 4;
        case JourneyNodeType.Segments:
          return 5;
        case JourneyNodeType.SegmentBranch:
          return 6;
        case JourneyNodeType.Splits:
          return 7;
        case JourneyNodeType.SplitBranch:
          return 8;
        case JourneyNodeType.Sync:
          return 9;
        default:
          return -1;
      }
    },
    "data.config.segment_priority_rank",
  ]);
