import { v4 as uuidv4 } from "uuid";

import { JourneyNodeDetails } from "src/pages/journeys/types";
import { JourneyNodeConfig, JourneyNodeType } from "src/types/journeys";
import { ConditionType, IntervalUnit, exhaustiveCheck } from "src/types/visual";

export const getInitialNodeConfiguration = (
  type: JourneyNodeType,
): JourneyNodeDetails<JourneyNodeConfig> => {
  const id = uuidv4();

  switch (type) {
    case JourneyNodeType.EntryCohort:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "Start",
        config: {
          type: JourneyNodeType.EntryCohort,
          // Initial value is `undefined`, but type requires a number
          max_num_entries: undefined as any,
        },
      };
    case JourneyNodeType.Sync:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "Send to destination",
        config: { type: JourneyNodeType.Sync },
      };
    case JourneyNodeType.TimeDelay:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "Time delay",
        config: {
          type: JourneyNodeType.TimeDelay,
          delay: {
            unit: IntervalUnit.Day,
            // Initial value is `undefined`, but type requires a number
            quantity: undefined as any,
          },
        },
      };
    case JourneyNodeType.WaitUntilEvent:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "Hold until",
        config: {
          type: JourneyNodeType.WaitUntilEvent,
          timeout_duration: {
            unit: IntervalUnit.Day,
            // Initial value is `undefined`, but type requires a number
            quantity: undefined as any,
          },
          event_conditions: {
            type: ConditionType.And,
            conditions: [],
          },
        },
      };
    case JourneyNodeType.Segments:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "Segment",
        config: { type: JourneyNodeType.Segments },
      };
    case JourneyNodeType.Splits:
      return {
        id,
        segment_id: null,
        event_relationship_id: null,
        number_users: null,
        sync_configs: null,
        name: "A/B Split",
        config: { type: JourneyNodeType.Splits },
      };
    default:
      // Frontend only supports the above node types.
      exhaustiveCheck(type as never);
  }
};
