import { ScheduleType } from "src/components/schedule/types";

export const getCreateJourneySyncParams = ({
  segmentId,
  hasDownstream,
}: {
  segmentId: string;
  hasDownstream?: boolean;
}) => {
  return `model=${segmentId}&schedule=${ScheduleType.JOURNEY_TRIGGERED}&hasDownstream=${hasDownstream}`;
};
