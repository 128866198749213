import {
  type FormkitComponent,
  type FormkitForm,
  type FormkitModifier,
  type FormkitNode,
  type FormkitSection,
  LayoutType,
  NodeType,
} from "../api";

export function Form(props: {
  children: FormkitNode[];
  noSortEdges?: [string, string][];
}): FormkitForm {
  return {
    type: NodeType.Layout,
    layout: LayoutType.Form,
    ...props,
  };
}

export type SectionProps = {
  heading?: string;
  subheading?: string;
  optional?: boolean;
  children: FormkitNode[];
  size?: "small" | "large";
  parent?: boolean;
};

export type AccordionSectionProps = Omit<SectionProps, "parent">;

export function Section(props: SectionProps): FormkitSection {
  return {
    type: NodeType.Layout,
    layout: LayoutType.Section,
    ...props,
  };
}

export type FieldProps = {
  heading?: string;
  subheading?: string;
  optional?: boolean;
  component: FormkitComponent | FormkitModifier;
};

export function Field(props: FieldProps): FormkitSection {
  return {
    type: NodeType.Layout,
    layout: LayoutType.Section,
    children: [props.component],
    size: "small",
    ...props,
  };
}

export type MultiFieldProps = {
  heading?: string;
  subheading?: string;
  optional?: boolean;
  components: FormkitNode[];
};

/**
 * A MultiField is a field that can have more than one component, useful if you need to show two nodes together without a gap.
 * For example, this could be used to show a warning label underneath a text field.
 */
export function MultiField(props: MultiFieldProps): FormkitSection {
  return {
    type: NodeType.Layout,
    layout: LayoutType.Section,
    size: "small",
    children: props.components,
    ...props,
  };
}

export function AccordionSection(props: AccordionSectionProps): FormkitSection {
  return {
    type: NodeType.Layout,
    layout: LayoutType.Accordion,
    ...props,
    heading: props.heading ?? "Advanced",
  };
}
