import { FC, useEffect, useMemo, useRef, useState } from "react";

import { TestDestinationStepStatus } from "@hightouch/core/server/graphql/types";
import {
  Column,
  Box,
  FormField,
  Heading,
  TextInput,
  Row,
  Text,
} from "@hightouchio/ui";
import { FormProvider } from "react-hook-form";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { Link, useNavigate } from "src/router";

import { AccordionSection } from "src/components/accordion-section";
import { DestinationsCatalog } from "src/components/destinations/catalog/destinations-catalog";
import { SetupForm } from "src/components/destinations/destination-form";
import { TestConnectionTable } from "src/components/destinations/test-connection-status";
import {
  TestDestinationBadge,
  TestNewDestinationButton,
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { getTagsFromLabels, LabelForm } from "src/components/labels/label-form";
import { ResourceType } from "src/components/labels/use-labels";
import { SidebarForm } from "src/components/page";
import { SearchProvider } from "src/components/search/search-provider";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateDestinationV2Mutation,
  useDestinationQuery,
  useFormkitDestinationDefinitionQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { WizardStep, DeprecatedWizard } from "src/components/wizard";
import { SlugResourceType, useResourceSlug } from "src/utils/slug";
import * as storage from "src/utils/storage";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";
import { useHightouchForm } from "src/components/form";
import { useDestinationDefinitions } from "src/hooks/use-destination-definitions";
import { GrantSelect } from "src/components/grant-select";
import { Grants } from "src/components/grant-select/types";
import { convertGrants } from "src/components/grant-select/util";

type CreateDestinationWizardProps = {
  initialDestinationDefinition?: DestinationDefinition;
  onConnectClick?: (defintion: DestinationDefinition) => void;
  onCancel: () => void;
  onSubmit: ({
    id,
    definition,
  }: {
    id: string;
    definition: DestinationDefinition;
  }) => void;
};

type Config = Record<string, unknown>;

export const CreateDestinationWizard: FC<
  Readonly<CreateDestinationWizardProps>
> = (props) => {
  return (
    <SearchProvider>
      <CreateDestinationWizardInternal {...props} />
    </SearchProvider>
  );
};

const CreateDestinationWizardInternal: FC<
  Readonly<CreateDestinationWizardProps>
> = ({ initialDestinationDefinition, onConnectClick, onCancel, onSubmit }) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const {
    data: { id },
  } = useQueryString();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(SlugResourceType.Destinations);
  const [config, setConfig] = useState<Config>();
  const [credentialId, setCredentialId] = useState<string>();
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testConnectionResult, setTestConnectionResult] = useState<
    TestDestinationStepStatus[] | null
  >(null);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);
  const [definition, setDefinition] = useState<DestinationDefinition>();

  const [step, setStep] = useWizardStepper(0);

  const { mutateAsync: createDestination, isLoading: creating } =
    useCreateDestinationV2Mutation();
  const { mutateAsync: updateDestination, isLoading: updating } =
    useUpdateDestinationV2Mutation();

  const { data: formkitMethods } = useFormkitDestinationDefinitionQuery(
    { type: definition?.type || "" },
    {
      enabled: Boolean(definition),
      select: (data) => data.formkitDestinationDefinition,
    },
  );

  const { isOAuth, isPreOAuthForm } = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return {
          isOAuth: formkitMethods[0]?.method === "oauth",
          isPreOAuthForm: formkitMethods[0]?.isPreOAuthForm,
        };
      }
      const formkitMethod = formkitMethods.find(
        (s) => config?.methodKey === s.key,
      );
      return {
        isOAuth: formkitMethod?.method === "oauth",
        isPreOAuthForm: formkitMethod?.isPreOAuthForm,
      };
    }
    return {
      isOAuth: false,
      isPreOAuthForm: false,
    };
  }, [formkitMethods, config?.methodKey]);

  // Algolia hit selected when creating the destination (Step 1)
  const hit = useRef<any>(null);
  const { sendEvent } = useInfiniteHits();

  const { data: destinationDefinitions } = useDestinationDefinitions();

  const { data: destination } = useDestinationQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => data.destinations_by_pk,
      onSuccess: (data) => {
        if (data) {
          setDefinition(data.definition);
          setConfig((prev) => ({ ...prev, ...data.config }));
        }
      },
    },
  );

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Destination Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult, testConnectionResult]);

  useEffect(() => {
    if (id) {
      if (storage.load("onboarding")) {
        storage.remove("onboarding");
        navigate(`/onboarding/destination/new?id=${id}`, { replace: true });
      }
      if (initialDestinationDefinition) {
        setStep(0);
      } else {
        setStep(1);
      }
    }
  }, [id]);

  useEffect(() => {
    if (definition) {
      if (name.length > 0 && definition?.name !== name) {
        setConfig(undefined);
      }
      setName(definition.name);
    }
  }, [definition]);

  useEffect(() => {
    setDefinition(initialDestinationDefinition);
  }, [initialDestinationDefinition]);

  const form = useHightouchForm({
    onSubmit: async (data) => {
      let newId;
      const slug = await getSlug(name);

      if (id) {
        await updateDestination({
          id,
          destination: {
            slug,
            name,
            config,
            tags: getTagsFromLabels(data.labels),
            setup_complete: true,
            created_by: String(user?.id),
          },
          grants: convertGrants(data.grants),
        });
      } else {
        const destination = {
          slug,
          name,
          config,
          tags: getTagsFromLabels(data.labels),
          setup_complete: true,
          created_by: String(user?.id),
          type: definition?.type,
        };

        if (credentialId) {
          destination["credential_id"] = credentialId;
          if (!config) {
            destination["config"] = {};
          }
        }

        const { createDestinationWithSecrets } = await createDestination({
          destination,
          grants: convertGrants(data.grants),
        });

        newId = createDestinationWithSecrets?.id;
      }

      const destinationId = newId ?? id;

      analytics.track("Destination Created", {
        destination_id: destinationId,
        destination_name: name,
        destination_type: definition?.name,
      });

      if (definition) {
        onSubmit?.({ id: destinationId, definition });
      }
    },
    success: `Destination "${name || definition?.name}" was created`,
    defaultValues: {
      labels: [{ key: "", value: "" }],
      grants: {} as Grants<"destination">,
    },
  });

  const steps: WizardStep[] = [
    {
      title: "Connect destination",
      disabled: isOAuth ? !id : !config && !credentialId,
      submitting: testing,
      //Use original validation and continue button for special forms on frontend, EX: (Google Sheets SA)
      //Will delete as soon as frontend forms get converted to backend destination configuration.
      onContinue: () => {
        // Increment step only if both isOAuth and isPreOAuthForm are true
        // (no need for validation to run again in this case).
        // Otherwise, do not increment step so backend validation runs.
        if (isOAuth && isPreOAuthForm) {
          setStep(step + 1);
        }
      },
      continue: isOAuth && !id ? "Authorize connection to continue" : undefined,
      continueProps: { form: "destination-form", type: "submit" },
      actions:
        definition?.testConnection &&
        !isOAuth &&
        (id ? (
          <TestUpdatedDestinationButton
            credentialId={credentialId}
            destinationId={id}
            newConfiguration={{ ...(config || {}) }}
            size="lg"
            onError={setTestError}
            onLoading={setTesting}
            onResult={setTestResult}
            onTestResult={setTestConnectionResult}
          />
        ) : (
          <TestNewDestinationButton
            configuration={{ ...(config || {}) }}
            credentialId={credentialId}
            definition={definition}
            result={testResult}
            size="lg"
            onError={setTestError}
            onResult={setTestResult}
            onTestResult={setTestConnectionResult}
          />
        )),
      header: (
        <Row alignItems="center" gap={4}>
          <Box
            as="img"
            src={definition?.icon}
            sx={{ width: "32px", objectFit: "contain" }}
          />
          <Heading>{`Connect to ${definition?.name}`}</Heading>
          <TestDestinationBadge key={0} result={testResult} testing={testing} />
        </Row>
      ),
      render: () => (
        <>
          {definition && (
            <Row alignItems="flex-start" gap={8}>
              <Column width="100%" gap={6}>
                <SetupForm
                  destination={destination}
                  config={config}
                  credentialId={credentialId}
                  definition={definition}
                  disableAuthMethod={Boolean(id)}
                  error={testError}
                  isSetup={true}
                  setConfig={setConfig}
                  setCredentialId={setCredentialId}
                  onConnectClick={onConnectClick}
                  onSubmit={() => {
                    setStep(step + 1);
                    return Promise.resolve();
                  }}
                />
                {(testConnectionResult || testing) && (
                  <TestConnectionTable
                    result={testConnectionResult}
                    testing={testing}
                  />
                )}
              </Column>
              <SidebarForm
                docsUrl={definition.docs ?? ""}
                name={definition.name}
              />
            </Row>
          )}
        </>
      ),
    },
    {
      title: "Finalize destination",
      disabled: !name,
      submitting: creating || updating,
      header: <Heading>Finalize settings for this destination</Heading>,
      render: () => (
        <Column gap={8}>
          <FormField
            description="Including details about the destination's business purpose and owners"
            label="Destination name"
          >
            <TextInput
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </FormField>
          <FormProvider {...form}>
            {user?.permissions_v2_enabled && (
              <FormField
                label="Permissions"
                description={
                  <Text>
                    Use the checkboxes below to configure which actions each
                    user group can perform for this destination and its related
                    resources.{" "}
                    <Link
                      href={`${import.meta.env.VITE_DOCS_URL}/workspace-management/roles#destination`}
                      isExternal
                    >
                      See documentation
                    </Link>{" "}
                    for details.
                  </Text>
                }
              >
                <GrantSelect type="destination" />
              </FormField>
            )}
            <AccordionSection label="Advanced configuration (optional)">
              <LabelForm
                heading="Add labels"
                hint="Example keys: team, project, region, env."
                resourceType={ResourceType.Destination}
              />
            </AccordionSection>
          </FormProvider>
        </Column>
      ),
    },
  ];

  if (!initialDestinationDefinition) {
    steps.unshift({
      title: "Select destination",
      disabled:
        !definition ||
        definition?.status === "alpha" ||
        definition?.status === "coming_soon" ||
        definition?.status === "shadow" ||
        Boolean(definition?.requiresInternalApproval),
      header: <Heading>Select a destination</Heading>,
      render: () =>
        destinationDefinitions ? (
          <DestinationsCatalog
            definitions={destinationDefinitions}
            error={null}
            selection={definition}
            onSelect={(selectedDefinition, selectedHit) => {
              setDefinition(selectedDefinition);
              hit.current = selectedHit;
            }}
            onClear={() => setDefinition(undefined)}
          />
        ) : null,
      onContinue: () => {
        if (hit.current) {
          sendEvent(
            "conversion",
            hit.current,
            "Destination Catalog Continue Clicked",
          );
        }
        if (definition) {
          analytics.track("Destination Catalog Continue Clicked", {
            destination_name: definition.name,
            destination_slug: definition.type,
            destination_status: definition.status,
          });
        }
        setStep(1);
      },
    });
  }

  return (
    <DeprecatedWizard
      title="Create destination"
      step={step}
      steps={steps}
      setStep={setStep}
      onSubmit={form.submit}
      onCancel={onCancel}
    />
  );
};
