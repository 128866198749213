import { FC } from "react";

import {
  Row,
  Text,
  Box,
  Checkbox,
  ArrowRightIcon,
  Column,
} from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";

import { valueIsEmpty } from "src/components/resource-alert-triggers/utils";
import { MonitorDefinitions } from "src/components/resource-alert-triggers/definitions";

export const CustomField: FC<
  Readonly<{
    conditionType: MonitorConditionTypes;
  }>
> = ({ conditionType }) => {
  const definition = MonitorDefinitions[conditionType];
  const Field = definition.field;

  if (Field) {
    return (
      <Controller
        name="condition"
        render={({ field }) => {
          const isErrorChecked = !valueIsEmpty(
            conditionType,
            field.value.error_value,
          );
          const isWarningChecked = !valueIsEmpty(
            conditionType,
            field.value.warning_value,
          );

          return (
            <Column ml={6} gap={2} mt={2} pos="relative">
              <Box
                pos="absolute"
                top={-8}
                right={0}
                bg="forest.200"
                opacity={0.5}
                w="100%"
                h="100%"
                p={4}
                pb={6}
                boxSizing="content-box"
                borderRadius="md"
                border="1px"
                borderColor="forest.500"
                zIndex={0}
              />
              <Row
                gap={2}
                align="center"
                zIndex={1}
                opacity={isErrorChecked ? 1 : 0.5}
              >
                <Checkbox
                  isChecked={isErrorChecked}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      error_value: e.target.checked
                        ? definition.defaultValue()
                        : null,
                    });
                  }}
                />
                <Field name="condition.error_value" />
                <Box as={ArrowRightIcon} boxSize={5} />
                <Text>
                  Send <Text color="danger.base">CRITICAL</Text> alert
                </Text>
              </Row>
              <Row
                gap={2}
                align="center"
                zIndex={1}
                opacity={isWarningChecked ? 1 : 0.5}
              >
                <Checkbox
                  isChecked={isWarningChecked}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      warning_value: e.target.checked
                        ? definition.defaultValue()
                        : null,
                    });
                  }}
                />
                <Field name="condition.warning_value" />
                <Box as={ArrowRightIcon} boxSize={5} />
                <Text>
                  Send <Text color="warning.border">WARNING</Text> alert
                </Text>
              </Row>
            </Column>
          );
        }}
      />
    );
  }
  return null;
};
