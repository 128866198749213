import {
  Avatar,
  ButtonGroup,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  EditableDescription,
  EditableHeading,
  FormField,
  IconButton,
  Row,
  Select,
  Text,
} from "@hightouchio/ui";
import noop from "lodash/noop";
import uniqBy from "lodash/uniqBy";
import { Controller } from "react-hook-form";
import { useOutletContext } from "src/router";

import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";

import { useMemo } from "react";
import { Warning } from "src/components/warning";
import {
  GoldenRecordIdentifier,
  GoldenRecordOutletContext,
  SurvivorshipRules,
} from "src/pages/identity-resolution/types";
import { formatDate } from "src/utils/time";

export const Identifier = () => {
  const { record, onSubmit, onClose } =
    useOutletContext<GoldenRecordOutletContext>();

  const form = useHightouchForm<GoldenRecordIdentifier>({
    onSubmit: (data) => {
      onSubmit(data);
      onClose();

      return Promise.resolve();
    },
    values: record ?? {},
  });

  const columnOptions = useMemo(
    () =>
      uniqBy(
        record ? record.models.flatMap((model) => model.columns) : [],
        "name",
      ),
    [form.getValues, record],
  );

  if (!record) {
    return (
      <Column>
        <Row px={4} pt={6} justify="flex-end">
          <IconButton
            aria-label="Close drawer"
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
        <Warning
          title="Identifier not found"
          subtitle="It may have been deleted"
        />
      </Column>
    );
  }

  return (
    <Form form={form}>
      <DrawerHeader>
        <Column gap={4} flex={1} minWidth={0}>
          <Column>
            <Row justify="space-between">
              <Controller
                control={form.control}
                name="columnName"
                render={({ field }) => (
                  <EditableHeading
                    value={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
              <IconButton
                aria-label="Close drawer"
                icon={CloseIcon}
                onClick={onClose}
              />
            </Row>
            <EditableDescription value="" onChange={noop} />
          </Column>
          <Row
            gap={4}
            flexShrink={0}
            sx={{
              "& > div": {
                ":not(:last-of-type)": {
                  borderRight: "1px",
                  pr: 4,
                  borderColor: "base.border",
                },
                justifyContent: "space-between",
                whiteSpace: "nowrap",
              },
            }}
          >
            <Row gap={1} align="center">
              <Text color="text.secondary" fontWeight="normal">
                Identifier:
              </Text>
              <TextWithTooltip fontWeight="normal">
                {/* identifier is static, so `watch` is unnecessary */}
                {form.getValues("identifier")}
              </TextWithTooltip>
            </Row>
            <Row gap={1} align="center">
              <Text fontWeight="normal">
                Last updated: {formatDate(new Date().toISOString())} by{" "}
              </Text>
              <Avatar name="Andrew Jesien" size="xs" />
            </Row>
          </Row>
        </Column>
      </DrawerHeader>

      <DrawerBody>
        <Column gap={6}>
          <FormField
            label="Source priority"
            description="Rank the sources to select a golden record field from. If no rank is provided, the value will be selected from all sources. If a rank is provided, we'll try to select a value from the highest ranked source first before trying lower ranked sources."
          >
            <Select
              placeholder="Select a rule..."
              isOptionDisabled={({ value }) => value === "custom-priority"}
              options={[
                { label: "No priority", value: "no-priority" },
                {
                  label: "Custom",
                  value: "custom-priority",
                  description: "Coming soon!",
                },
              ]}
              value="no-priority"
              onChange={noop}
              width="100%"
            />
          </FormField>

          <FormField
            label="Survivorship rule"
            description="Choose the survivorship rule to determine which value will be stored in the golden record."
          >
            <Controller
              control={form.control}
              name="survivorshipRule"
              render={({ field }) => (
                <Select
                  placeholder="Select a rule..."
                  options={Object.values(SurvivorshipRules)}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    if (value !== "select-by") {
                      form.setValue("column", null);
                    }
                  }}
                  width="100%"
                />
              )}
            />
          </FormField>

          {form.watch("survivorshipRule") === "select-by" && (
            <FormField
              label="Select by column"
              description="The value for the current field will come from the same row as the selected field below."
            >
              <Controller
                control={form.control}
                name="column"
                render={({ field }) => (
                  <Select
                    placeholder="Select a column..."
                    // Find the columns that are related to the model.
                    options={columnOptions}
                    optionValue={(option) => option}
                    optionLabel={(option) => option.alias ?? option.name}
                    value={field.value}
                    onChange={field.onChange}
                    width="100%"
                  />
                )}
              />
            </FormField>
          )}

          {/* TODO: drag and drop tables... */}
        </Column>
      </DrawerBody>

      <DrawerFooter>
        <ButtonGroup>
          <SaveButton enablePristineSubmit>Save changes</SaveButton>
          <DiscardButton />
        </ButtonGroup>

        {/* <Button size="lg" variant="warning">
          Delete
        </Button> */}
      </DrawerFooter>
    </Form>
  );
};
