import { FC } from "react";

import {
  MonitoredResourceType,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

import { useOutletContext } from "src/router";
import { ResourceTriggers } from "src/components/resource-alert-triggers/resource-triggers";
import { Context } from ".";

export const Alerting: FC = () => {
  const { sync } = useOutletContext<Context>();
  const destination = sync.destination;

  return (
    <ResourceTriggers
      resourceType={MonitoredResourceType.Sync}
      resourceId={sync.id.toString()}
      parentResourceType={ParentResourceTypes.Destination}
      parentResourceId={destination!.id.toString()}
    />
  );
};
