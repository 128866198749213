import { Box, Column, Row, SectionHeading, Text } from "@hightouchio/ui";
import {
  MonitorStatus,
  ParentResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import { isEqual } from "lodash";

import notificationsPlaceholder from "src/assets/placeholders/notifications.svg";
import {
  useCreateNotificationTemplateChannelsMutation,
  useDeleteNotificationChannelTemplatesByDestinationMutation,
  useOverrideNotificationChannelTemplatesMutation,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { useNavigate } from "src/router";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { useRecipients } from "src/pages/alerting/utils";
import { Card } from "src/components/card";
import { AddRecipientButton } from "./add-recipient-button";
import { Channel } from "src/components/notification-channels/channel-definitions";
import { useMemo } from "react";

export const Recipients = ({
  destinationIds,
}: {
  destinationIds: string[];
}) => {
  const navigate = useNavigate();
  const { getRecipientsByParentResourceId, getRecipientsForParentResourceIds } =
    useRecipients();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      suspense: true,
      select: (data) => data.workspace_notification_channels,
    },
  );

  const { mutateAsync: deleteParentRecipients } =
    useDeleteNotificationChannelTemplatesByDestinationMutation();

  const recipients = getRecipientsForParentResourceIds(destinationIds);
  const recipientsByDestination =
    getRecipientsByParentResourceId(destinationIds);

  // Conflict if either destinations have mismatched recipients or if a destination has none
  const isConflicting = Object.values(recipientsByDestination).some(
    (destinationRecipients: any) =>
      !isEqual(
        recipients.map((r) => r.channel.id),
        destinationRecipients.map((r) => r.channel.id),
      ),
  );

  const { mutateAsync: createNotificationTemplateChannels } =
    useCreateNotificationTemplateChannelsMutation();
  const { mutateAsync: overrideNotificationChannelTemplates } =
    useOverrideNotificationChannelTemplatesMutation();

  const overrideRecipient = async (channelId: string) => {
    return overrideNotificationChannelTemplates({
      resourceIds: destinationIds,
      objects: destinationIds.map((id) => ({
        channel_id: channelId,
        parent_resource_id: id,
        parent_resource_type: ParentResourceTypes.Destination,
        status: MonitorStatus.Unhealthy,
      })),
    });
  };

  const addRecipient = async (channelId: string) => {
    return createNotificationTemplateChannels({
      objects: destinationIds.map((id) => ({
        channel_id: channelId,
        parent_resource_id: id,
        parent_resource_type: ParentResourceTypes.Destination,
        status: MonitorStatus.Unhealthy,
      })),
    });
  };

  const onCreate = () => {
    navigate(
      `/alerting/recipients/new?destinations=${destinationIds.join(",")}`,
    );
  };

  // Workspace channels contains all possible notification channels
  // We need to filter out the ones that are already subscribed to by this resource
  const channels = useMemo(
    () =>
      (workspaceChannels ?? []).filter(
        (c) => !recipients.map((r) => r.channel.id).includes(c.id),
      ),
    [workspaceChannels, recipients],
  );

  return (
    <Column gap={2}>
      <Column>
        <SectionHeading>Recipients</SectionHeading>
        {!isConflicting && recipients.length > 0 && (
          <Text color="text.secondary">
            Send all alerts to the following recipients:
          </Text>
        )}
      </Column>
      {isConflicting ? (
        <Card>
          <Column gap={4} align="center">
            <Box as="img" src={notificationsPlaceholder} boxSize={24} />
            <Column align="center">
              <Text size="lg" color="text.secondary" fontWeight="medium">
                These destinations have conflicting recipients
              </Text>
              <Text color="text.secondary">
                Adding a recipient will override the existing recipients
              </Text>
            </Column>
            <AddRecipientButton
              onCreate={onCreate}
              onAdd={overrideRecipient}
              channels={workspaceChannels ?? []}
            />
          </Column>
        </Card>
      ) : recipients.length === 0 ? (
        <NoRecipients
          onAdd={addRecipient}
          onCreate={onCreate}
          channels={channels}
        />
      ) : (
        <>
          <Row gap={2} flexWrap="wrap" maxH="400px">
            {recipients.map((r) => (
              <SubscribedResourceItem
                key={r.id}
                channel={r.channel}
                onRemove={async () => {
                  await deleteParentRecipients({
                    channelId: r.channel.id,
                    destinationIds,
                  });
                }}
              />
            ))}
            <AddRecipientButton
              isIcon
              onAdd={addRecipient}
              onCreate={onCreate}
              channels={channels}
            />
          </Row>
        </>
      )}
    </Column>
  );
};

export const NoRecipients = ({
  onAdd,
  onCreate,
  channels,
}: {
  onAdd: (channelId: string) => Promise<unknown>;
  onCreate: () => void;
  channels: Array<Channel>;
}) => {
  return (
    <Card>
      <Column gap={4} align="center">
        <Box as="img" src={notificationsPlaceholder} boxSize={24} />
        <Column align="center">
          <Text size="lg" color="text.secondary" fontWeight="medium">
            Add recipients to receive alerts via Slack, PagerDuty, SMS, or email
          </Text>
          <Text color="text.secondary">
            You will only see alerts in Hightouch if you don’t add any
            recipients.
          </Text>
        </Column>
        <AddRecipientButton
          onAdd={onAdd}
          onCreate={onCreate}
          channels={channels}
        />
      </Column>
    </Card>
  );
};
