import {
  AggregationOption,
  AudienceAggregationType,
  DecisionEngineAnalyticsCampaignMetricType,
  DecisionEngineAnalyticsMetricDefinition,
  DecisionEngineInteractionColumnNames,
  isDecisionEngineAnalyticsAttributionMetricsDefinition,
  isDecisionEngineIncrementalityMetricDefinition,
  PerUserAggregationType,
  SyntheticMetricType,
} from "@hightouch/lib/query/visual/types";
import {
  ColumnType,
  RawColumn,
  RelatedColumn,
  Relationship,
} from "src/types/visual";
import {
  AggregationOptions,
  isLiftNormalizationType,
} from "../metrics/constants";
import { MetricResultMaybeFromCache } from "./hooks/use-metric-series";
import {
  isSyntheticMetric,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
  MeasuringSelectionColumn,
  MetricGroupOption,
  MetricSelection,
} from "./types";

export type DecisionEngineOutcome = {
  outcome: {
    id: string;
    segment_id: number;
    name: string;
  };
};

export type DecisionEngineFlow = {
  id: string;
  name: string;
  status: string;
};

export const formatDecisionEngineAnalyticsCampaignMetric = ({
  parentModelId,
  aggregationConfiguration,
  eventModelId,
  relationshipId,
  metricSelection,
  measuringSelection,
  measuringMode,
  column,
}: {
  parentModelId: string;
  aggregationConfiguration: {
    aggregation: PerUserAggregationType;
    audienceAggregation: AudienceAggregationType;
  };
  eventModelId: string;
  relationshipId: string;
  metricSelection: MetricSelection;
  measuringSelection: MeasuringSelection;
  measuringMode: MeasuringMode | undefined;
  column: RawColumn | RelatedColumn | undefined;
}): DecisionEngineAnalyticsMetricDefinition => {
  const metricType = getFlowMetricType(measuringMode, metricSelection);

  // TODO @jenn-chan: add unit tests for this
  if (metricType === DecisionEngineAnalyticsCampaignMetricType.Interactions) {
    return {
      type: "decision_engine_flow",
      flowId: measuringSelection.id,
      parentModelId,
      ...aggregationConfiguration,
      metricType,
      config: {
        column: column ?? metricSelection.column?.column_reference,
        filter: {
          subconditions: metricSelection.conditions,
        },
        // Let resolver validation handle undefined values since these aren't
        // set by the users (the source and resource ID should be specified in the option)
        source: metricSelection.source as SyntheticMetricType,
        resourceId: metricSelection.resourceId as string,
      },
    };
  }

  return {
    type: "decision_engine_flow",
    flowId: measuringSelection.id,
    parentModelId,
    ...aggregationConfiguration,
    metricType,
    config: {
      eventModelId,
      relationshipId,
      column: column ?? metricSelection.column?.column_reference,
      filter: {
        subconditions: metricSelection.conditions,
      },
      normalization: metricSelection.normalization,
    },
  };
};

function getFlowMetricType(
  measuringMode: MeasuringMode | undefined,
  metricSelection: MetricSelection,
) {
  if (measuringMode === MeasuringMode.Incrementality) {
    return DecisionEngineAnalyticsCampaignMetricType.Incrementality;
  } else if (isSyntheticMetric(metricSelection)) {
    return DecisionEngineAnalyticsCampaignMetricType.Interactions;
  }

  return DecisionEngineAnalyticsCampaignMetricType.AttributedEvents;
}

export const decisionEngineFlowMetricOptions = (
  flowId: string,
  measuringMode: MeasuringMode | undefined,
  events: Relationship[] | undefined,
  outcomes: DecisionEngineOutcome[] | undefined,
): MetricGroupOption[] => {
  const metrics: MetricGroupOption[] = [
    {
      label: "Outcomes",
      options: (events ?? [])
        .map((event) => ({
          id: event.id,
          eventModelId: event.to_model?.id,
          name: event.to_model?.name ?? event.name,
          description: event?.to_model?.description,
        }))
        .filter((event) =>
          outcomes?.some(
            ({ outcome }) => outcome.segment_id == event.eventModelId,
          ),
        ),
    },
  ];

  if (measuringMode !== MeasuringMode.Incrementality) {
    metrics.push({
      label: "Interactions",
      options: [
        {
          // IDs will reference the resource's ID (SyntheticUserDefinedMetricConfig)
          id: flowId,
          eventModelId: null,
          resourceId: flowId,
          source: SyntheticMetricType.DecisionEngineInteractions,
          name: "AI Decisioning Interactions",
          description: null,
        },
      ],
    });
  }

  return metrics;
};

const supportedAggregations = [
  AggregationOption.Count,
  AggregationOption.SumOfProperty,
  AggregationOption.UniqueUsers,
  AggregationOption.AverageOfProperty,
];

export const DecisionEngineAggregationOptions = AggregationOptions.filter(
  (opt) => supportedAggregations.includes(opt.value),
);

export const formatGroupsFromDecisionEngineMetricResult = (
  result: MetricResultMaybeFromCache[],
) => {
  return result.map((series) => {
    const isAttributionResult =
      isDecisionEngineAnalyticsAttributionMetricsDefinition(
        series.ids.metricDefinition,
      );

    const isIncrementalityResult =
      isDecisionEngineIncrementalityMetricDefinition(
        series.ids.metricDefinition,
      );

    // XXX: Temporary solution to remove holdout group from metric result from the
    // client side by default
    if (isAttributionResult && "data" in series.result) {
      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data
            .filter(
              ({ splitId }) => splitId !== "holdout" && splitId !== "uniform",
            )
            .map((seriesData) => ({ ...seriesData, splitId: null })),
        },
      };
    }

    if (isIncrementalityResult && "data" in series.result) {
      const liftNormalized = isLiftNormalizationType(
        series.ids.metricDefinition.config.normalization,
      );

      return {
        ...series,
        result: {
          ...series.result,
          data: series.result.data.map(({ splitId, ...seriesData }) => ({
            ...seriesData,
            // Make it clear that we're measuring treatment lift vs specified group
            splitId: splitId && liftNormalized ? "vs " + splitId : splitId,
          })),
        },
      };
    }

    return series;
  });
};

const DEFAULT_INTERACTION_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "User ID",
    columnReference: {
      type: "decision_engine_interaction",
      name: DecisionEngineInteractionColumnNames.UserId,
    },
    columnType: ColumnType.String,
  },
];

const DEFAULT_ACTION_FEATURES_COLUMNS: MeasuringSelectionColumn[] = [
  {
    alias: "Channel",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "channel",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Day of week",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "day_of_week",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Time of day",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "time_of_day",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Frequency",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "frequency_arm",
    },
    columnType: ColumnType.String,
  },
  {
    alias: "Message",
    columnReference: {
      type: "decision_engine_interaction_action_features",
      name: "message",
    },
    columnType: ColumnType.String,
  },
];

type DecisionEngineInteractionFeatureColumnsQueryResult =
  | {
      config: any;
      flows: Array<{
        messages: Array<{
          message: {
            variables: any | null;
          };
        }>;
      }>;
    }
  | undefined;

/**
 * Extract and format the columns from the decision engine columns query. These
 * column schemas come from different resources but fetched in one query.
 *
 * Within a decision engine flow, we have these different types of interaction columns:
 * - Interactions
 * - User features
 * - Action features
 *
 * User features have different types but we only want to expose categorical (ie. text)
 *  and booleans for now.
 * Action features are always *string* type
 */
export function decisionEngineFlowSyntheticColumns(
  measurmentMode: MeasuringMode | undefined,
  result: DecisionEngineInteractionFeatureColumnsQueryResult,
): {
  columns: MeasuringSelectionColumn[];
  additionalUserColumns: MeasuringSelectionColumn[];
} {
  const config = result?.config;
  const customUserFeaturesColumns = (config?.user_feature_schema ?? [])
    .filter((u) => u.type === "categorical" || u.type === "boolean")
    .map((u) => ({
      alias: u.name,
      columnReference: {
        type: "decision_engine_interaction_user_features",
        name: `${u.name.toLowerCase()}`,
      },
      columnType: getUserFeatureColumnType(u.type),
    }));

  if (measurmentMode === MeasuringMode.Incrementality) {
    return {
      columns: [],
      additionalUserColumns: customUserFeaturesColumns,
    };
  }

  const messages = result?.flows[0]?.messages ?? [];
  const customActionFeatures = new Set();

  for (const m of messages) {
    const variables = m.message.variables ?? [];
    for (const v of variables) {
      // Only want to include if there are values specified for the feature
      if (v?.name && v?.values.length) customActionFeatures.add(v.name);
    }
  }

  const customActionFeaturesColumns = Array.from(customActionFeatures).map(
    (action) => ({
      alias: action?.toString() ?? "",
      columnReference: {
        type: "decision_engine_interaction_action_features" as const,
        name: action?.toString() ?? "",
      },
      columnType: ColumnType.String,
    }),
  );

  const interactionColumns = [
    ...DEFAULT_INTERACTION_COLUMNS,
    ...DEFAULT_ACTION_FEATURES_COLUMNS,
    ...customActionFeaturesColumns,
  ];

  return {
    columns: interactionColumns,
    additionalUserColumns: customUserFeaturesColumns,
  };
}

function getUserFeatureColumnType(type: "categorical" | "boolean") {
  switch (type) {
    case "categorical":
      return ColumnType.String;
    case "boolean":
    default:
      return ColumnType.String;
  }
}

export const isMeasuringDecisionEngineIncrementality = (
  scope: MeasurementScope | undefined,
  mode: MeasuringMode | undefined,
) => {
  return (
    scope === MeasurementScope.DecisionEngineFlow &&
    mode === MeasuringMode.Incrementality
  );
};
