import {
  MonitorConditionEvaluationProperties,
  ParentResourceTypes,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";

import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { Text, Column, Row, SectionHeading } from "@hightouchio/ui";
import { useMemo } from "react";
import {
  useResourceTypeMonitorConditionsQuery,
  useSubscribeableResourcesQuery,
  useUpsertMonitorConditionTemplatesMutation,
} from "src/graphql";
import { MonitorConditionForm } from "./monitor-condition-forms/monitor-condition-form";

export const Triggers = ({ destinationIds }: { destinationIds: string[] }) => {
  const upsert = useUpsertMonitorConditionTemplatesMutation();

  const { data } = useResourceTypeMonitorConditionsQuery(
    {
      resourceType: MonitoredResourceType.Sync,
      parentResourceType: ParentResourceTypes.Destination,
      includeTemplates: true,
    },
    { suspense: true },
  );

  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const filteredDestinations = useMemo(
    () =>
      syncsAndDestinations?.destinations.filter((destination) =>
        destinationIds?.includes(destination?.id.toString()),
      ) || [],
    [syncsAndDestinations, destinationIds],
  );

  const onSubmit = async ({ condition }) => {
    await upsert.mutateAsync({
      objects: destinationIds.map((id) => ({
        evaluation_trigger:
          MonitorConditionEvaluationProperties[condition.type]
            .EvaluationTrigger,
        evaluation_type:
          MonitorConditionEvaluationProperties[condition.type].EvaluationType,
        type: condition.type,
        enabled: condition.enabled,
        error_value: condition.error_value,
        warning_value: condition.warning_value,
        parent_resource_id: id,
        parent_resource_type: ParentResourceTypes.Destination,
      })),
    });
  };

  return (
    <Column gap={2}>
      <Row gap={4} align="center" justify="space-between">
        <Column>
          <SectionHeading>Triggers</SectionHeading>
          <Text color="text.secondary">Send alerts when:</Text>
        </Column>
      </Row>
      {filteredDestinations.length > 0 &&
        SupportedConditionsForResourceTypes[MonitoredResourceType.Sync].map(
          (conditionType) => {
            const conditions =
              data!.monitor_condition_templates?.filter(
                (condition) =>
                  condition.type === conditionType &&
                  destinationIds?.includes(condition.parent_resource_id),
              ) ?? [];
            return (
              <MonitorConditionForm
                onSubmit={onSubmit}
                key={conditionType}
                destinationIds={destinationIds}
                conditionType={conditionType}
                conditions={conditions}
              />
            );
          },
        )}
    </Column>
  );
};
