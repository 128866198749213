import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Alert,
  AudienceIcon,
  Box,
  Button,
  ButtonGroup,
  ChevronRightIcon,
  CloseIcon,
  CodeIcon,
  Column,
  Combobox,
  Dialog,
  IconButton,
  InformationIcon,
  RedoIcon,
  RefreshIcon,
  Row,
  Spinner,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  UndoIcon,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { useQueryClient } from "react-query";

import { ActionBar } from "src/components/action-bar";
import AudienceIllustration from "src/components/audiences/audience-illustration.svg";
import { Editor } from "src/components/editor";
import { QueryBuilder } from "src/components/explore/query-builder";
import { PermissionedButton } from "src/components/permission";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  AudienceBreakdownResult,
  AudienceComparisonResult,
  CompareAudiencesQueryVariables,
  useAudienceBreakdownQuery,
  useAudiencesForDropdownsQuery,
  useCompareAudiencesQuery,
  useVisualQuerySqlQuery,
} from "src/graphql";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import * as analytics from "src/lib/analytics";
import {
  AndCondition,
  isMergedColumn,
  OrCondition,
  RootCondition,
  SizeCap,
} from "src/types/visual";
import { SIZES } from "src/ui/box";
import { EMPTY_AUDIENCE_DEFINITION, useModelRun } from "src/utils/models";
import { commaNumber } from "src/utils/numbers";
import { useCalculateAudienceSize } from "src/utils/use-calculate-audience-size";

import { Magic } from "src/components/explore/magic";
import { SubsetSelector } from "src/components/explore/subset-selector";
import {
  cssVariableBottomSectionHeight,
  cssVariableTopOffset,
  cssVariableTopSectionHeight,
} from "src/components/layout/detail-page";
import { DefaultPageContainerPadding } from "src/components/layout/page-container";
import {
  ResourcePermissionInput,
  useResourcePermission,
} from "src/components/permission/use-resource-permission";

import { AudienceBreakdowns, BreakdownColumn } from "./audience-breakdowns";
import { AudienceOverlap } from "./audience-overlap";
import { AudienceResults } from "./audience-results";
import { AudienceSizeCap } from "./audience-size-cap";
import { NumericFontStyles } from "./constants";
import { MembersDrawer } from "./member-details-drawer/member-drawer";
import { AudienceExplorePageProps } from "./types";
import { hasQueryChanged, useLastUsedQuery } from "./use-last-used-query";
import { toSingleCondition } from "./utils";

enum TabOptions {
  Results = "Results",
  Overlap = "Overlap",
  Breakdowns = "Breakdown",
}

const defaultSidebarWidth = 400;
const actionBarDefaultHeight = 48;
const borderWidth = 1;
const footerDefaultHeight = 0;

export const AudienceExplore: FC<Readonly<AudienceExplorePageProps>> = ({
  audience,
  bodyOverflow = "",
  parentModel,
  source,
  onSave,
  modelState,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const {
    aiAudienceGeneration,
    appDestinationRulesEnabled,
    enableMemberDetails,
  } = useFlags();
  const tabs = [
    ...(enableMemberDetails ? [] : [TabOptions.Results]),
    TabOptions.Overlap,
    TabOptions.Breakdowns,
  ];

  const { hasValidationErrors } = useFormErrorContext();

  const hasSampledSegments = Boolean(
    parentModel?.enabled_sampled_segments.length,
  );
  const [isFastQueryEnabled, setIsFastQueryEnabled] =
    useState(hasSampledSegments);
  const [destinationId, setDestinationId] = useState<string | undefined>();

  const [showMergedColumnsInPreview, setShowMergedColumnsInPreview] =
    useState(false);

  const {
    runQuery,
    cancelQuery,
    resetRunState,
    rows,
    numRowsWithoutLimit,
    loading,
    error,
    usedSampledModels: usedFastQueryForRun,
  } = useModelRun(
    modelState.state,
    // Arguments for the model run query
    {
      columns: parentModel?.columns,
      includeMergedColumns: enableMemberDetails && showMergedColumnsInPreview,
      mergedColumns: (parentModel?.filterable_audience_columns ?? []).filter(
        (col) => isMergedColumn(col.column_reference),
      ),
      useSampledModels: isFastQueryEnabled,
      onCompleted: (data, error) => {
        if (!error) {
          setTransformedSql((state) => ({
            ...state,
            sql: data?.transformedSql,
            fetchedAt: Date.now(),
            loading: false,
          }));
        }
      },
    },
  );

  useEffect(() => {
    if (showMergedColumnsInPreview) {
      resetRunState();
      runQuery({ limit: true, disableRowCounter: true });
    }
  }, [showMergedColumnsInPreview]);

  const visualQueryFilter = modelState.state.visual_query_filter;
  const subsetIds = useMemo(
    () =>
      modelState.state.subsets?.map((subset) => subset.subset_value.id) ?? [],
    [modelState.state.subsets],
  );
  const sizeCap = visualQueryFilter?.sizeCap;

  const {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: calculatingAudienceSize,
    lastUsedQuery: lastUsedAudienceSizeQuery,
    calculateAudienceSize,
    cancelCalculateAudienceSize,
    updateAudienceSize,
  } = useCalculateAudienceSize({
    audienceId: modelState.state.id?.toString(),
    parentModelId: parentModel?.id?.toString() ?? "",
    sourceId: source?.id?.toString() ?? "",
    visualQueryFilter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    subsetIds,
    isFastQuery: isFastQueryEnabled,
    destinationId,
  });

  const sizeLoading = loading || calculatingAudienceSize;

  const cancelQueryAndSizeCalculation = () => {
    cancelCalculateAudienceSize();
    cancelQuery();
  };

  const saveSizeCap = async (payload: SizeCap | undefined): Promise<void> => {
    const newVisualQueryFilter = {
      ...(visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION),
      sizeCap: payload,
    };

    // If the audience is being created or cloned, we don't want to actually "save" the size cap:
    //   1) the onSave callback prop is undefined
    //   2) that would attempt to save the full audience entity prematurely
    // Instead, we'll just update the visual query object.
    if (onSave == undefined) {
      modelState.onChange({ visual_query_filter: newVisualQueryFilter });
    } else {
      setSaveLoading(true);
      await onSave({ visual_query_filter: newVisualQueryFilter }, false);
      setSaveLoading(false);
    }

    resetRunState();
  };

  const { refetch: refetchVisualQuerySql } = useVisualQuerySqlQuery(
    {
      audience_id: modelState.state.id?.toString(),
      connection_id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parent_model_id:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      subset_ids: subsetIds,
      useSampledModels: isFastQueryEnabled,
      destinationId,
    },
    {
      enabled: false,
    },
  );

  const [transformedSql, setTransformedSql] = useState<{
    sql: string | undefined | null;
    fetchedAt: number | undefined;
    loading: boolean;
    visible: boolean;
  }>({
    sql: undefined,
    fetchedAt: undefined,
    loading: false,
    visible: false,
  });

  const sentinelRef = useRef<HTMLDivElement>(null);
  const actionBarRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tab, setTab] = useState<TabOptions>(tabs[0]!);
  const [isInsightsDrawerOpen, setIsInsightsDrawerOpen] = useState(false);
  const [isMembersDrawerOpen, setIsMembersDrawerOpen] = useState(false);
  const [overlapQueryError, setOverlapQueryError] = useState<string | null>(
    null,
  );
  const [showCancelButton, setShowCancelButton] = useState(false);
  const [isActionBarStuckToTop, setIsActionBarStuckToTop] = useState(false);

  // Add box shadow to action bar when it's stuck to top
  useEffect(() => {
    const sentinelElementIntersectionObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isStuck =
            entry.boundingClientRect.top <= 0 && !entry.isIntersecting;

          setIsActionBarStuckToTop(isStuck);
        });
      },
      {
        threshold: [1],
      },
    );

    if (sentinelRef.current) {
      sentinelElementIntersectionObserver.observe(sentinelRef.current);
    }

    return () => {
      sentinelElementIntersectionObserver.disconnect();
    };
  }, []);

  const actionBarHeight = useMeasureHeightOnce(
    actionBarRef,
    actionBarDefaultHeight,
  );

  const footerBarHeight = useMeasureHeightOnce(footerRef, footerDefaultHeight);

  const calculateSizePermission: ResourcePermissionInput<"model", "audience"> =
    {
      v2: {
        resource: "model",
        grant: "can_create",
        creationOptions: {
          type: "audience",
          parentModelId: parentModel?.id?.toString(),
        },
      },
      v1: { resource: "audience", grant: "preview", id: modelState.state.id },
    };

  const accessDataPermission: ResourcePermissionInput<"model", "audience"> = {
    v2: modelState.state.id
      ? { resource: "model", id: modelState.state.id, grant: "can_preview" }
      : {
          resource: "model",
          grant: "can_preview",
          creationOptions: {
            type: "audience",
            parentModelId: parentModel?.id?.toString(),
          },
        },
    v1: { resource: "audience", grant: "preview", id: modelState.state.id },
  };

  const viewInsightsPermissions: ResourcePermissionInput<"model", "audience"> =
    {
      v2: modelState.state.id
        ? { resource: "model", id: modelState.state.id, grant: "can_update" }
        : {
            resource: "model",
            grant: "can_create",
            creationOptions: {
              type: "audience",
              parentModelId: parentModel?.id?.toString(),
            },
          },
      v1: { resource: "audience", grant: "preview", id: modelState.state.id },
    };

  const { isPermitted: isCalculateSizePermitted } = useResourcePermission(
    calculateSizePermission,
  );
  const { isPermitted: isAccessDataPermitted } =
    useResourcePermission(accessDataPermission);

  const [comparedAudienceIds, setComparedAudienceIds] = useState<string[]>([]);
  const [comparisonData, setComparisonData] =
    useState<AudienceComparisonResult>();
  const [comparisonsLoading, setComparisonsLoading] = useState(false);

  const refetchBreakdown = useRef(false);
  const [breakdownColumns, setBreakdownColumns] = useState<BreakdownColumn[]>(
    [],
  );
  const [breakdownData, setBreakdownData] = useState<AudienceBreakdownResult>();

  const [lastUsedInsightsQuery, setLastUsedInsightsQuery] =
    useLastUsedQuery(null);

  const initialPreviewRun = isInsightsDrawerOpen
    ? Boolean(lastUsedInsightsQuery)
    : Boolean(lastUsedAudienceSizeQuery);

  const currentQuery = {
    filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    subsetIds,
    isFastQuery: isFastQueryEnabled,
    destinationId,
  };

  // Once audience members is 100%: refactor to be 2 variables -
  // one for isCountStale and another for isPreviewStale
  const isDataStale =
    initialPreviewRun &&
    hasQueryChanged(
      currentQuery,
      isInsightsDrawerOpen ? lastUsedInsightsQuery : lastUsedAudienceSizeQuery,
    );

  const copySQLToClipboard = () => {
    if (transformedSql.sql) {
      navigator.clipboard.writeText(transformedSql.sql);

      toast({
        id: "copy-sql",
        title: "SQL copied to clipboard",
        variant: "success",
      });
    }
  };

  const {
    error: breakdownError,
    isLoading: breakdownsInitialLoading,
    isRefetching: breakdownsRefetching,
    refetch: refetchBreakdowns,
  } = useAudienceBreakdownQuery(
    {
      id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parentModelId:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      columns: breakdownColumns,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: modelState.state.id?.toString(),
      perColumnLimit: 10,
      subsetIds,
      useSampledModels: isFastQueryEnabled,
      destinationId,
    },
    {
      enabled: false,
      keepPreviousData: breakdownColumns?.length > 0 ? false : true,
    },
  );

  const { data: audiencesData, isLoading: audiencesLoading } =
    useAudiencesForDropdownsQuery(
      { limit: 10000 },
      {
        refetchOnWindowFocus: true,
        staleTime: 1000 * 60, // 1 min
        notifyOnChangeProps: ["data", "isLoading"],
      },
    );

  const fetchBreakdowns = async () => {
    try {
      const visualQueryUsedToRunBreakdown = cloneDeep(visualQueryFilter);
      const { data } = await refetchBreakdowns();

      if (!data) {
        return;
      }

      setLastUsedInsightsQuery({
        filter: visualQueryUsedToRunBreakdown ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });
      updateAudienceSize({
        count: data.audienceBreakdown.audienceSize,
        isEstimate: Boolean(data.audienceBreakdown.usedSampledModels),
      });
      const formattedColumns: AudienceBreakdownResult["columns"] = [];

      for (const column of data?.audienceBreakdown.columns ?? []) {
        // replace name with alias
        const columnData = breakdownColumns.find(
          ({ name }) => name === column.name,
        );
        const modelId = columnData?.modelId || "";
        const columnName = columnData?.alias || column.name;

        const sumOfValues = column.values.reduce(
          (sum, value) => sum + value.count,
          0,
        );
        let newValues = column.values.map((value) => ({
          ...value,
          percentage: sumOfValues ? value.count / sumOfValues : "unknown",
        }));
        const numberOfRemainingValues =
          data.audienceBreakdown.audienceSize - sumOfValues;

        if (numberOfRemainingValues > 0) {
          newValues = newValues.map((value) => ({
            ...value,
            percentage: value.count / data.audienceBreakdown.audienceSize,
          }));
          newValues.push({
            value: "Other",
            count: numberOfRemainingValues,
            percentage:
              numberOfRemainingValues / data.audienceBreakdown.audienceSize,
          });
          newValues.sort((valueA, valueB) => valueB.count - valueA.count);
        }

        formattedColumns.push({
          ...column,
          name: columnName,
          modelId: modelId,
          values: newValues,
        });
      }

      setBreakdownData({
        ...data?.audienceBreakdown,
        columns: formattedColumns,
      });
    } catch (error) {
      toast({
        id: "audience-breakdowns",
        title: "Breakdown calculation failed",
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const breakdownsLoading = breakdownsInitialLoading || breakdownsRefetching;
  const audiences = audiencesData?.segments ?? [];

  const columnsForBreakdowns = (
    parentModel?.filterable_audience_columns ?? []
  ).filter(
    ({ column_reference }) =>
      !column_reference?.disable_preview &&
      (column_reference?.type === "raw" || isMergedColumn(column_reference)) &&
      !breakdownColumns.some((selectedColumn) =>
        isEqual(omit(selectedColumn, "alias"), column_reference),
      ),
  );

  const destinationOptions =
    parentModel?.destination_rules.map(({ destination }) => ({
      label: destination.name ?? destination.definition.name,
      value: String(destination.id),
      logo: destination.definition.icon,
    })) ?? [];

  const splitTestGroupName =
    visualQueryFilter?.splitTestDefinition?.groupColumnName;

  const shouldFetchQuerySql = useMemo(() => {
    /**
     * If we have previewed or saved the query, then the generated SQL will have been cached in local state.
     * So we only need to regenerate the SQL if the filter conditions have changed.
     */
    if (transformedSql.sql && transformedSql.fetchedAt && isDataStale) {
      return audienceSizeUpdatedAt !== null
        ? audienceSizeUpdatedAt > transformedSql.fetchedAt
        : false;
    }

    /**
     * If the query hasn't been saved:
     * we don't have a way of knowing whether the query conditions have changed so we refetch to be safe.
     */
    return !transformedSql.sql || !rows;
  }, [
    audienceSizeUpdatedAt,
    transformedSql.sql,
    transformedSql.fetchedAt,
    isDataStale,
    rows,
  ]);

  const showSqlPreview = useCallback(async () => {
    if (shouldFetchQuerySql) {
      setTransformedSql((state) => ({
        ...state,
        loading: true,
        visible: true,
      }));
      try {
        const result = await refetchVisualQuerySql();
        setTransformedSql((state) => ({
          ...state,
          sql: result?.data?.visualQuerySQL?.sql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      } catch (error) {
        toast({
          id: "sql-preview",
          title: "SQL preview failed",
          message: "Please try again.",
          variant: "error",
        });
      }
    } else {
      setTransformedSql((state) => ({ ...state, visible: true }));
    }
  }, [shouldFetchQuerySql, refetchVisualQuerySql, setTransformedSql]);

  const handleSave = async () => {
    if (hasValidationErrors()) {
      toast({
        id: "save-audience",
        title: "Unable to save audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    setSaveLoading(true);
    if (typeof onSave === "function") {
      await onSave();
    }
    setSaveLoading(false);
  };

  const resetOverlapData = () => {
    setComparedAudienceIds([]);
    setComparisonData(undefined);
  };

  const addBreakdown = (column: BreakdownColumn) => {
    if (hasValidationErrors()) {
      toast({
        id: "add-breakdown",
        title: "Unable to breakdown audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    const newBreakdownColumns = [...breakdownColumns, column];
    setBreakdownColumns(newBreakdownColumns);
  };

  const removeBreakdown = (columnName: string) => {
    refetchBreakdown.current = false;

    // remove data
    setBreakdownData((prevData) => {
      if (!prevData) {
        return undefined;
      }

      const newColumns = [...(prevData?.columns ?? [])].filter(
        (column) => column.name !== columnName,
      );
      if (newColumns.length === 0) {
        return undefined;
      }

      return {
        ...prevData,
        columns: newColumns,
      };
    });

    // remove column
    setBreakdownColumns((prevColumns) =>
      prevColumns.filter(
        (column) => column.name !== columnName && column.alias !== columnName,
      ),
    );
  };

  useEffect(() => {
    if (refetchBreakdown.current && breakdownColumns.length > 0) {
      fetchBreakdowns();
    }

    refetchBreakdown.current = true;
  }, [breakdownColumns.length]);

  const compareAudiences = async (
    audienceIds: string[],
    { invalidateQuery }: { invalidateQuery: boolean } = {
      invalidateQuery: false,
    },
  ) => {
    if (hasValidationErrors()) {
      toast({
        id: "compare-audiences",
        title: "Unable to compare audiences",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (audienceIds.length === 0) {
      setComparedAudienceIds([]);
      setComparisonData(undefined);
      setOverlapQueryError(null);
      return;
    }

    setComparisonsLoading(true);

    const variables: CompareAudiencesQueryVariables = {
      id:
        modelState.state.connection?.id?.toString() ??
        source?.id?.toString() ??
        "",
      parentModelId:
        modelState.state.visual_query_parent_id?.toString() ??
        parentModel?.id?.toString() ??
        "",
      compareToAudiences: audienceIds || comparedAudienceIds,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: modelState.state.id?.toString(),
      subsetIds,
      destinationId,
      // Audience comparison doesn't support using Fast Query
      // so we intentionally omit passing the variable to the mutation
    };

    const queryKey = useCompareAudiencesQuery.getKey(variables);

    if (invalidateQuery) {
      await client.invalidateQueries(queryKey);
    }

    try {
      const { audienceComparison } = await client.fetchQuery({
        queryFn: useCompareAudiencesQuery.fetcher(variables),
        queryKey,
      });

      setComparedAudienceIds(audienceIds || comparedAudienceIds);
      setLastUsedInsightsQuery({
        filter: variables.filter,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });
      updateAudienceSize({
        count: audienceComparison.audienceSize,
        // Audience comparison doesn't support fast query,
        // so the size that's returned will never be an estimate.
        isEstimate: false,
      });
      setComparisonData(audienceComparison);
      setOverlapQueryError(null);
    } catch (error) {
      // TODO: Log only 500 errors to sentry
      setOverlapQueryError(error.message);

      toast({
        id: "compare-audiences",
        title: "Audience comparison failed",
        message: error.message,
        variant: "error",
      });
    }

    setComparisonsLoading(false);
  };

  const removeComparison = (idToRemove: string) => {
    setComparedAudienceIds((previousComparedIds) => {
      const newIds = [...previousComparedIds];
      const indexToRemove = newIds.findIndex(
        (comparedId) => comparedId === idToRemove,
      );

      if (indexToRemove > -1) {
        newIds.splice(indexToRemove, 1);
      }

      return newIds;
    });

    setComparisonData((previousComparedData) => {
      if (!previousComparedData) {
        return previousComparedData;
      }

      const newComparedData = {
        ...previousComparedData,
        comparisons: [...previousComparedData.comparisons],
      };

      const indexToRemove = newComparedData.comparisons.findIndex(
        (comparedAudience) => comparedAudience.audienceId === idToRemove,
      );

      if (indexToRemove > -1) {
        newComparedData.comparisons.splice(indexToRemove, 1);
      }

      return newComparedData;
    });
  };

  const handleRunQuery = useCallback(
    ({
      limitResults,
      recalculateSize = true,
    }: {
      limitResults: boolean;
      recalculateSize?: boolean;
    }) => {
      if (hasValidationErrors()) {
        toast({
          id: "preview-audience",
          title: "Unable to preview audience",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      setLastUsedInsightsQuery({
        filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
        subsetIds,
        isFastQuery: isFastQueryEnabled,
        destinationId,
      });

      // Run both the member preview and the size calculation in parallel
      runQuery({ limit: limitResults, disableRowCounter: true });
      if (recalculateSize) {
        calculateAudienceSize();
      }
    },
    [runQuery, visualQueryFilter],
  );

  const previewQuery = async (
    { recalculateSize }: { recalculateSize: boolean } = {
      recalculateSize: true,
    },
  ) => {
    // If the user can't access row level data, then just return early from this call
    // They won't be able to view the member details anyway, but good to also skip fetching the data
    if (!isAccessDataPermitted) return;

    if (hasValidationErrors()) {
      toast({
        id: "preview-audience",
        title: "Unable to preview audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    await handleRunQuery({ limitResults: true, recalculateSize });
    analytics.track("Model Query Previewed", {
      model_type: source?.definition?.type,
      query_mode: "visual",
    });
  };

  const toggleDrawer = () => {
    if (!rows && !error) {
      previewQuery({ recalculateSize: false });
    }
    setIsInsightsDrawerOpen((prevValue) => !prevValue);
  };

  useEffect(() => {
    if (numRowsWithoutLimit) {
      updateAudienceSize(
        {
          count: numRowsWithoutLimit,
          isEstimate: Boolean(usedFastQueryForRun),
        },
        {
          filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
          subsetIds,
          isFastQuery: isFastQueryEnabled,
          destinationId,
        },
      );
    }
  }, [numRowsWithoutLimit]);

  const refreshResults = () => {
    if (hasValidationErrors()) {
      toast({
        id: "refresh-audience-results",
        title: "Unable to refresh results",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (tab === TabOptions.Results && !enableMemberDetails) {
      previewQuery();
    }

    if (tab === TabOptions.Overlap && comparedAudienceIds.length) {
      compareAudiences(comparedAudienceIds, { invalidateQuery: true });
    }
    if (tab === TabOptions.Breakdowns && breakdownColumns.length > 0) {
      fetchBreakdowns();
    }
  };

  const refresh = () => {
    if (hasValidationErrors()) {
      toast({
        id: "refresh-audience-results",
        title: "Unable to refresh results",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    calculateAudienceSize();
    refreshResults();
  };

  const clickTab = (index: number) => {
    setTab(
      tabs?.[index] ||
        (enableMemberDetails ? TabOptions.Overlap : TabOptions.Results),
    );
  };

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleRunQuery({ limitResults: true });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [handleRunQuery]);

  useEffect(() => {
    return resetRunState();
  }, []);

  const displayBreakdownError =
    breakdownError &&
    !breakdownError?.message.includes("missing columns for breakdown") &&
    !breakdownsLoading;

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      const metaKeyPressed = evt.metaKey || evt.ctrlKey;
      const shiftKeyPressed = evt.shiftKey;

      if (
        modelState.canRedo &&
        evt.key === "z" &&
        metaKeyPressed &&
        shiftKeyPressed
      ) {
        modelState.redo();
      } else if (
        modelState.canUndo &&
        evt.key === "z" &&
        metaKeyPressed &&
        !shiftKeyPressed
      ) {
        modelState.undo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modelState]);

  // Set body height to stick action bar to top
  useEffect(() => {
    document.body.style.overflowY = bodyOverflow;

    return () => {
      // defaults are empty strings
      document.body.style.overflowY = "";
    };
  }, [bodyOverflow]);

  const setConditions = (
    conditions: AndCondition | OrCondition | null,
  ): void => {
    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: conditions ? ([conditions] as RootCondition[]) : [],
      },
    });
  };

  // This is used for Copilot ONLY, not well tested for other use cases
  const addConditions = (
    conditions: AndCondition | OrCondition | null,
  ): void => {
    const existingConditions = toSingleCondition(
      visualQueryFilter?.conditions,
    )?.[0] ?? {
      type: "and",
      conditions: [],
    };
    const addedCondition = conditions ? conditions?.conditions : [];
    const newConditions = [
      {
        type: existingConditions.type,
        conditions: [...existingConditions.conditions, ...addedCondition],
      },
    ];

    modelState.onChange({
      visual_query_filter: {
        ...modelState.state.visual_query_filter,
        conditions: newConditions as RootCondition[],
      },
    });
  };

  return (
    <>
      {/* Detail page scroll container is fixed in the viewport. Add an invisible element to track the scrolling position */}
      <Box ref={sentinelRef} style={{ height: "1px" }} />
      <Row
        ref={actionBarRef}
        height={`${actionBarDefaultHeight}px`}
        position="sticky"
        sx={{ top: `var(${cssVariableTopOffset})` }}
        boxShadow={isActionBarStuckToTop ? "sm" : undefined}
        transition="boxShadow 0.3s ease-in-out"
        width="100%"
        zIndex={1}
      >
        <Row flex={1} minWidth={0} bg="base.lightBackground">
          <Row
            alignItems="center"
            color="text.secondary"
            flex={1}
            height="48px"
            justifyContent="space-between"
            maxWidth={SIZES.page}
            minWidth={0}
            mx="auto"
            position="relative"
            px={DefaultPageContainerPadding.X}
          >
            <Row gap={2}>
              <Tooltip message="Undo" keyboardShortcut="mod+z" placement="top">
                <IconButton
                  aria-label="Undo audience change."
                  icon={UndoIcon}
                  isDisabled={!modelState.canUndo}
                  onClick={modelState.undo}
                />
              </Tooltip>
              <Tooltip
                message="Redo"
                keyboardShortcut="mod+shift+z"
                placement="top"
              >
                <IconButton
                  aria-label="Redo audience change."
                  icon={RedoIcon}
                  isDisabled={!modelState.canRedo}
                  onClick={modelState.redo}
                />
              </Tooltip>
              <Tooltip message="View SQL" placement="top">
                <IconButton
                  aria-label="Open SQL preview."
                  icon={CodeIcon}
                  onClick={showSqlPreview}
                />
              </Tooltip>
              {hasSampledSegments && (
                <Row alignItems="center" gap={2} px={2}>
                  <Row gap={1}>
                    <Text fontWeight="medium">Fast queries</Text>
                    <Tooltip message="Fast queries generate an estimated size which is a close approximate to the exact size">
                      <InformationIcon />
                    </Tooltip>
                  </Row>
                  <Switch
                    size="sm"
                    isChecked={isFastQueryEnabled}
                    onChange={setIsFastQueryEnabled}
                  />
                </Row>
              )}
            </Row>

            <Row alignItems="center" gap={2}>
              {appDestinationRulesEnabled &&
                Boolean(destinationOptions.length) &&
                (isCalculateSizePermitted || isAccessDataPermitted) && (
                  <Tooltip
                    message="Preview the calculated size and membership list based on a destination rule"
                    placement="top"
                  >
                    <Column>
                      <Combobox
                        isClearable
                        optionAccessory={(option) => ({
                          type: "image",
                          url: option.logo,
                        })}
                        options={destinationOptions}
                        placeholder="Preview a destination..."
                        value={destinationId}
                        width="4xs"
                        onChange={setDestinationId}
                      />
                    </Column>
                  </Tooltip>
                )}

              <AudienceSizeCap
                properties={parentModel?.filterable_audience_columns ?? []}
                traits={parentModel?.traits ?? []}
                data={sizeCap}
                permission={
                  modelState.state.id
                    ? {
                        v1: {
                          resource: "audience",
                          grant: "update",
                          id: modelState.state.id,
                        },
                        v2: {
                          resource: "model",
                          grant: "can_update",
                          id: modelState.state.id,
                        },
                      }
                    : {
                        v1: {
                          resource: "audience",
                          grant: "create",
                        },
                        v2: {
                          resource: "model",
                          grant: "can_create",
                          creationOptions: {
                            type: "audience",
                            parentModelId: parentModel?.id?.toString(),
                          },
                        },
                      }
                }
                onSave={saveSizeCap}
              />

              {!calculatingAudienceSize &&
                audienceSize === null &&
                !sizeLoading && (
                  <PermissionedButton
                    tooltip="Calculate the size of this audience"
                    placement="top"
                    icon={AudienceIcon}
                    onClick={refresh}
                    unauthorizedTooltip="You do not have permission to calculate the size of this audience"
                    permission={calculateSizePermission}
                  >
                    Calculate size
                  </PermissionedButton>
                )}

              {(audienceSize !== null || sizeLoading) && (
                <Row align="center" gap={2} fontSize="20px">
                  {!sizeLoading && isDataStale && (
                    <Box
                      sx={{
                        button: {
                          color: "text.secondary",
                          _hover: { color: "text.secondary" },
                        },
                      }}
                    >
                      <Tooltip
                        message={
                          isInsightsDrawerOpen
                            ? "Refresh results"
                            : "Recalculate the size of this audience"
                        }
                        placement="top"
                      >
                        <IconButton
                          aria-label={
                            isInsightsDrawerOpen
                              ? "Refresh results."
                              : "Recalculate the size of this audience."
                          }
                          icon={RefreshIcon}
                          variant="secondary"
                          onClick={refresh}
                        />
                      </Tooltip>
                    </Box>
                  )}

                  {!enableMemberDetails &&
                    !calculatingAudienceSize &&
                    audienceSize === null &&
                    !sizeLoading && (
                      <Tooltip
                        message="Calculate the size of this audience"
                        placement="top"
                        openSpeed="slow"
                      >
                        <Button icon={AudienceIcon} onClick={refresh}>
                          Calculate size
                        </Button>
                      </Tooltip>
                    )}

                  {(audienceSize !== null || sizeLoading) &&
                    !enableMemberDetails && (
                      <Box fontSize="20px">
                        <Row alignItems="center" gap={2} px={2}>
                          {sizeLoading ? (
                            <Row align="center">
                              <Row
                                justifyContent="center"
                                width="32px"
                                onMouseEnter={() => setShowCancelButton(true)}
                                onMouseLeave={() => setShowCancelButton(false)}
                              >
                                {showCancelButton ? (
                                  <Row justifyContent="center">
                                    <Box
                                      sx={{
                                        button: {
                                          color: "danger.base",
                                          _hover: { color: "danger.base" },
                                        },
                                      }}
                                    >
                                      <Tooltip
                                        message="Cancel query"
                                        placement="top"
                                        openSpeed="slow"
                                      >
                                        <IconButton
                                          aria-label="Cancel query."
                                          icon={CloseIcon}
                                          mr={2}
                                          onClick={
                                            cancelQueryAndSizeCalculation
                                          }
                                        />
                                      </Tooltip>
                                    </Box>
                                  </Row>
                                ) : (
                                  <Box as={Spinner} size="sm" />
                                )}
                              </Row>
                              <Text
                                color="text.secondary"
                                fontWeight="semibold"
                              >
                                Calculating...
                              </Text>
                            </Row>
                          ) : (
                            <AudienceIcon />
                          )}

                          {!sizeLoading && (
                            <Box
                              as={Text}
                              fontWeight="semibold"
                              color={
                                sizeLoading ? "text.tertiary" : "text.primary"
                              }
                              size="lg"
                              sx={NumericFontStyles}
                            >
                              {audienceSize !== null
                                ? `${
                                    audienceSize.isEstimate ? "~" : ""
                                  }${commaNumber(audienceSize.count)}`
                                : "--"}
                            </Box>
                          )}
                        </Row>
                      </Box>
                    )}
                  {enableMemberDetails && (
                    <ShowMembersButton
                      sizePermission={calculateSizePermission}
                      previewPermission={accessDataPermission}
                      sizeLoading={sizeLoading}
                      audienceSize={audienceSize}
                      onCancel={cancelQueryAndSizeCalculation}
                      onClick={() => {
                        if (audienceSize === null) {
                          refresh();
                          previewQuery();
                        } else {
                          const isPreviewDataStale = hasQueryChanged(
                            currentQuery,
                            lastUsedInsightsQuery,
                          );

                          if ((!rows && !error) || isPreviewDataStale) {
                            resetRunState();
                            // Only recalculate size if the query has changed since last run
                            previewQuery({
                              recalculateSize: isDataStale,
                            });
                          }

                          setIsMembersDrawerOpen((prev) => !prev);
                        }
                      }}
                    />
                  )}
                </Row>
              )}

              <ShowInsightsButton
                permission={viewInsightsPermissions}
                isInsightsOpen={isInsightsDrawerOpen}
                onClick={toggleDrawer}
              />
            </Row>
          </Row>
        </Row>
      </Row>
      <Row
        maxWidth={SIZES.page}
        mb={footerBarHeight ? `${footerBarHeight}px` : undefined}
        mx="auto"
        position="relative"
        px={DefaultPageContainerPadding.X}
        width="100%"
      >
        <Column flex={1} minWidth={0} overflowX="auto" my={4} gap={4}>
          <Text size="lg" fontWeight="medium">
            Include in audience if customer...
          </Text>
          <SubsetSelector
            parentModelId={parentModel?.id}
            value={subsetIds}
            onChange={(subsets) => {
              modelState.onChange({
                subsets: subsets.map((id) => ({ subset_value: { id } })),
              });
            }}
          />
          <Column pb={6}>
            <QueryBuilder
              audience={audience}
              // To allow for top level switching between and/or the conditions are nested within one single condition.
              filter={toSingleCondition(visualQueryFilter?.conditions)?.[0]}
              parent={parentModel}
              setConditions={setConditions}
            />
          </Column>
          {aiAudienceGeneration && (
            <Magic
              updateFilter={addConditions}
              parentModelId={String(parentModel?.id)}
            />
          )}
        </Column>

        <>
          <Box
            maxHeight={
              isInsightsDrawerOpen
                ? `calc(100vh - var(${cssVariableTopSectionHeight}) - ${actionBarHeight}px - ${footerBarHeight}px - var(${cssVariableBottomSectionHeight}) - ${
                    borderWidth * 2
                  }px)`
                : 0
            }
            flex="none"
            overflowY="auto"
            overscrollBehaviorY="contain"
            pl={isInsightsDrawerOpen ? 4 : 0}
            position="sticky"
            right={0}
            top={`calc(var(${cssVariableTopOffset}) + ${actionBarHeight}px)`}
            transition="all 120ms ease-in-out"
            transform={isInsightsDrawerOpen ? undefined : "translate(100%, 0)"}
            opacity={isInsightsDrawerOpen ? 1 : 0}
            width={isInsightsDrawerOpen ? `${defaultSidebarWidth}px` : 0}
          >
            <Column flex={1} minHeight={0} mt={4} position="relative">
              <Tabs
                index={tabs.findIndex((tabOption) => tab === tabOption)}
                onChange={clickTab}
              >
                <TabList>
                  {!enableMemberDetails && isAccessDataPermitted && (
                    <Tab>Members</Tab>
                  )}
                  <Tab>Overlap</Tab>
                  <Tab>Breakdown</Tab>
                </TabList>

                <Box as={TabPanels} height="100%">
                  {!enableMemberDetails && isAccessDataPermitted && (
                    <Box as={TabPanel} height="100%" overflowY="auto">
                      {(rows || error) && !loading ? (
                        <AudienceResults
                          error={error}
                          parentModel={parentModel}
                          rows={rows}
                          splitTestGroupName={splitTestGroupName}
                        />
                      ) : (
                        <Column
                          sx={{
                            alignItems: "center",
                            borderTopRightRadius: 3,
                            flex: 1,
                            justifyContent: "center",
                            overflowY: "auto",
                            p: 4,
                          }}
                        >
                          {loading ? (
                            <>
                              <Spinner size="lg" />
                              <Text mt={6} color="base.6">
                                Querying your data...
                              </Text>
                              <Button
                                mt={6}
                                variant="secondary"
                                onClick={cancelQueryAndSizeCalculation}
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              <Box as="img" src={AudienceIllustration} />
                              <Text color="text.secondary" mt={8} mb={4}>
                                Preview this audience to see a sample of results
                              </Text>
                              <PermissionedButton
                                permission={{
                                  v1: {
                                    resource: "audience",
                                    grant: "preview",
                                    id: modelState.state.id,
                                  },
                                  v2: modelState.state.id
                                    ? {
                                        resource: "model",
                                        grant: "can_preview",
                                        id: modelState.state.id,
                                      }
                                    : {
                                        resource: "model",
                                        grant: "can_preview",
                                        creationOptions: {
                                          type: "audience",
                                          parentModelId:
                                            parentModel?.id?.toString(),
                                        },
                                      },
                                }}
                                isLoading={loading}
                                mb={8}
                                onClick={() => previewQuery()}
                              >
                                Preview results
                              </PermissionedButton>
                            </>
                          )}
                        </Column>
                      )}
                    </Box>
                  )}
                  <Box as={TabPanel} height="100%">
                    {!overlapQueryError || comparisonsLoading ? (
                      <AudienceOverlap
                        audienceId={Number(modelState.state.id)}
                        audienceName="Current audience"
                        audiences={audiences ?? []}
                        comparedAudienceIds={comparedAudienceIds}
                        comparisonData={comparisonData}
                        loading={comparisonsLoading || audiencesLoading}
                        parentModelId={parentModel?.id}
                        onAddComparison={compareAudiences}
                        onClearComparisons={resetOverlapData}
                        onRemoveComparison={removeComparison}
                      />
                    ) : (
                      <Column flex={1} overflowY="auto">
                        <Alert
                          variant="inline"
                          actions={
                            <Button
                              variant="secondary"
                              onClick={() =>
                                compareAudiences([], {
                                  invalidateQuery: true,
                                })
                              }
                            >
                              Reset
                            </Button>
                          }
                          type="error"
                          message={overlapQueryError}
                          title="Error"
                          my={4}
                        />
                      </Column>
                    )}
                  </Box>
                  <Box as={TabPanel} height="100%">
                    {!displayBreakdownError ? (
                      <AudienceBreakdowns
                        breakdownData={breakdownData?.columns ?? []}
                        columns={
                          // Selected columns are filtered out.
                          // Pass unfiltered list while loading so that name
                          // may be displayed in combobox.
                          breakdownsLoading
                            ? (parentModel?.filterable_audience_columns ?? [])
                            : columnsForBreakdowns
                        }
                        graphWidth={defaultSidebarWidth}
                        loading={breakdownsLoading || breakdownsRefetching}
                        isFastQueryEnabled={isFastQueryEnabled}
                        onRemoveBreakdown={removeBreakdown}
                        onSubmit={addBreakdown}
                      />
                    ) : (
                      <Column flex={1} overflowY="auto">
                        <Alert
                          variant="inline"
                          actions={
                            <Button
                              variant="secondary"
                              onClick={() => setBreakdownColumns([])}
                            >
                              Reset
                            </Button>
                          }
                          type="error"
                          message={breakdownError.message}
                          title="Error"
                          my={4}
                        />
                      </Column>
                    )}
                  </Box>
                </Box>
              </Tabs>
            </Column>
          </Box>
        </>
      </Row>

      {onSave && (
        <ActionBar ref={footerRef}>
          <ButtonGroup>
            <PermissionedButton
              permission={{
                v1: {
                  resource: "audience",
                  grant: "update",
                  id: modelState.state.id,
                },
                v2: {
                  resource: "model",
                  grant: "can_update",
                  id: modelState.state.id ?? "",
                },
              }}
              isLoading={saveLoading}
              // TODO(nishad): we probably want to track updates to subset changes so we can undo them.
              // We may need to merge the visual query and subset states if possible.
              // Otherwise only undo visual query changes.
              //isDisabled={!canUndo}
              size="lg"
              variant="primary"
              onClick={handleSave}
              isDisabled={!modelState.isDirty}
            >
              Save audience
            </PermissionedButton>
            <Button
              isDisabled={!modelState.canUndo || !modelState.isDirty}
              size="lg"
              onClick={() => {
                modelState.reset();
              }}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      )}

      <Dialog
        isOpen={transformedSql.visible}
        variant="info"
        width="xl"
        title="Transformed SQL"
        actions={
          <ButtonGroup>
            <Button onClick={copySQLToClipboard}>Copy SQL</Button>
            <Button
              variant="primary"
              onClick={() =>
                setTransformedSql((state) => ({ ...state, visible: false }))
              }
            >
              OK
            </Button>
          </ButtonGroup>
        }
        onClose={() =>
          setTransformedSql((state) => ({ ...state, visible: false }))
        }
      >
        {loading || transformedSql.loading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Spinner size="lg" />
            <Text mt={4}>Fetching transformed SQL...</Text>
          </Box>
        ) : (
          <Editor readOnly language="sql" value={transformedSql.sql ?? ""} />
        )}
      </Dialog>
      <MembersDrawer
        audienceSize={audienceSize}
        audienceRows={rows ?? []}
        includeMergedColumns={enableMemberDetails && showMergedColumnsInPreview}
        isOpen={isMembersDrawerOpen}
        isError={Boolean(error)}
        parentModel={parentModel}
        isLoading={loading}
        onToggleMergedColumns={() => {
          setShowMergedColumnsInPreview((value) => !value);
        }}
        onClose={() => setIsMembersDrawerOpen(false)}
      />
    </>
  );
};

const ShowInsightsButton: FC<{
  isInsightsOpen: boolean;
  onClick: () => void;
  permission: ResourcePermissionInput<"model", "audience">;
}> = ({ isInsightsOpen, onClick, permission }) => (
  <Box
    as={PermissionedButton}
    placement="top-end"
    tooltip="View breakdowns and overlaps"
    icon={
      isInsightsOpen
        ? ChevronRightIcon
        : () => (
            <Box
              alignItems="end"
              display="grid"
              height="15px"
              width="15px"
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              mr="7px"
            >
              <Box backgroundColor="cyan.400" height="3.75px" />
              <Box backgroundColor="electric.600" height="7.5px" />
              <Box backgroundColor="cyan.400" height="11.25px" />
              <Box backgroundColor="electric.600" height="15px" />
            </Box>
          )
    }
    permission={permission}
    height={8}
    width="160px"
    onClick={onClick}
  >
    {isInsightsOpen ? "Hide insights" : "Show insights"}
  </Box>
);

const ShowMembersButton: FC<{
  audienceSize: { count: number; isEstimate: boolean } | null;
  sizeLoading: boolean;
  onClick: () => void;
  onCancel: () => void;
  sizePermission: ResourcePermissionInput<"model", "audience">;
  previewPermission: ResourcePermissionInput<"model", "audience">;
}> = ({
  audienceSize,
  sizeLoading,
  onClick,
  onCancel,
  sizePermission,
  previewPermission,
}) => {
  const { isPermitted: isPreviewPermitted } =
    useResourcePermission(previewPermission);
  if (sizeLoading) {
    return (
      <Row align="center" pr={2}>
        <Row
          justifyContent="center"
          width="32px"
          _hover={{
            ".hovered": { display: "block" },
            ".unhovered": { display: "none" },
          }}
        >
          <Box className="unhovered" display="block" as={Spinner} size="sm" />
          <Row
            className="hovered"
            display="none"
            justifyContent="center"
            sx={{
              button: {
                color: "danger.base",
                _hover: { color: "danger.base" },
              },
            }}
          >
            <Tooltip message="Cancel query" placement="top" openSpeed="slow">
              <IconButton
                aria-label="Cancel query."
                icon={CloseIcon}
                mr={2}
                onClick={onCancel}
              />
            </Tooltip>
          </Row>
        </Row>
        <Text color="text.secondary" fontWeight="semibold">
          Calculating...
        </Text>
      </Row>
    );
  }

  const sizeText =
    audienceSize === null
      ? "Calculate size"
      : `${audienceSize.isEstimate ? "~" : ""}${commaNumber(
          audienceSize.count,
        )} members`;

  if (!isPreviewPermitted && audienceSize !== null) {
    return (
      <Row gap={1} px={1}>
        <AudienceIcon />
        <Text fontWeight="semibold">{sizeText}</Text>
      </Row>
    );
  }

  return (
    <Tooltip message="Preview members" placement="top" openSpeed="slow">
      <PermissionedButton
        tooltip="Preview members"
        placement="top"
        onClick={onClick}
        icon={AudienceIcon}
        permission={audienceSize === null ? sizePermission : previewPermission}
      >
        {sizeText}
      </PermissionedButton>
    </Tooltip>
  );
};
