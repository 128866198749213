import { useEffect } from "react";

import { DrawerProps, useToken } from "@hightouchio/ui";

import { hidePylon, showPylon, updatePylonPosition } from "src/lib/pylon";

const drawerSizeToPylonOffset: Record<
  NonNullable<DrawerProps["size"]>,
  string
> = {
  // 100% hides the pylon widget
  full: "100%",
  "2xl": "100%",
  xl: "4xl",
  lg: "2xl",
  md: "lg",
  sm: "md",
  xs: "sm",
};

export const usePylonDrawerOffset = ({
  isOpen,
  size,
}: {
  isOpen?: boolean;
  size: NonNullable<DrawerProps["size"]>;
}) => {
  // width is determined by the max width defined on the drawer
  const pylonOffset = drawerSizeToPylonOffset[size];
  const [value] = useToken("sizes", [pylonOffset]);

  const isHidden = value === "100%";

  useEffect(() => {
    const numericalValue = parseInt(value.replace("px", ""));

    if (isOpen) {
      if (isHidden) {
        hidePylon();
      } else {
        updatePylonPosition(isNaN(numericalValue) ? 0 : numericalValue);
      }
    } else {
      if (isHidden) {
        showPylon();
      } else {
        updatePylonPosition(0);
      }
    }

    return () => {
      updatePylonPosition(0);
    };
  }, [isOpen, value]);
};
