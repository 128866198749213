import { FC, ReactNode, useEffect, useMemo } from "react";

import {
  Box,
  Button,
  Column,
  InformationIcon,
  MeetingIcon,
  Row,
  SectionHeading,
  Select,
  Text,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import { noop } from "ts-essentials";
import { isPresent } from "ts-extras";

import CrossAudienceGraph from "src/components/analytics/cross-audience-graph";
import { GraphScale } from "src/components/analytics/cross-audience-graph/constants";
import { DateRangePicker } from "src/components/analytics/date-range-picker";
import { FunnelGraph } from "src/components/analytics/funnel-graph";
import { usePermissionContext } from "src/components/permission";
import {
  Graph,
  GraphSeries,
} from "src/components/analytics/cross-audience-graph/types";
import { useHeaderHeight } from "src/contexts/header-height-context";
import { FunnelMetricDataForCohort } from "src/graphql";
import { PredefinedMetric } from "src/types/visual";
import { useRowSelect } from "src/ui/table/use-row-select";

import { AnalyticsBreakdown } from "./analytics-breakdown";
import { AnalyticsSidebar } from "./sidebar";
import { AnalyticsTable } from "./analytics-table";
import { placeholderContentWidthPx } from "./constants";
import {
  AnalyticsPlaceholderImage,
  FunnelsPlaceholder,
  UnconfiguredPlaceholder,
  PermissionsPlaceholder,
} from "./placeholders";
import { useAnalyticsContext } from "./state";
import {
  AccumulationTypes,
  LookbackOptions,
  RollupFrequencies,
} from "./state/constants";
import { SummaryStats } from "./summary-stats";
import { ChartFormState, GraphType, TimeOptions } from "./types";
import { formatDatePickerLabel, shouldUsePercentFormat } from "./utils";

type AnalyticsContentProps = {
  errorMessage?: ReactNode;
  funnelsData: FunnelMetricDataForCohort[];
  funnelsErrors?: Record<string, string>;
  graph: Graph;
  isLoading?: boolean;
  isSavedView: boolean;
  metricSeriesErrors?: Record<string, string>;
  ignoreHeaderHeight?: boolean;
};

const shouldUsePercentScale = (series: GraphSeries[]) => {
  return series.every(({ aggregation, normalization }) =>
    shouldUsePercentFormat(aggregation, normalization),
  );
};

export const AnalyticsContent: FC<AnalyticsContentProps> = ({
  errorMessage,
  funnelsErrors = {},
  funnelsData,
  graph,
  isLoading: externalLoading = false,
  isSavedView = false,
  metricSeriesErrors = {},
  ignoreHeaderHeight = false,
}) => {
  const { headerHeight } = useHeaderHeight();
  const { selectedRows, onRowSelect } = useRowSelect();

  const { setLookbackWindow, setSelectedDates } = useAnalyticsContext();

  const form = useFormContext<ChartFormState>();

  const funnelSteps = form.watch("funnelSteps");
  const graphType = form.watch("graphType");
  const groupByColumns = form.watch("groupByColumns");
  const metricSelection = form.watch("metricSelection");
  const selectedAudiences = form.watch("selectedAudiences");
  const selectedDateStrings = form.watch("selectedDates");
  const timeValue = form.watch("timeValue");

  const selectedDates = useMemo(
    () => selectedDateStrings.map((dateStr) => new Date(dateStr)),
    [selectedDateStrings?.[0], selectedDateStrings?.[1]],
  );

  const { unauthorized } = usePermissionContext();

  const isLoading = externalLoading;

  const scale = shouldUsePercentScale(graph.series)
    ? GraphScale.Percent
    : GraphScale.Linear;

  const hasSummaryStats = Boolean(graph.summary.length);
  const hasMetricOrEventSelected = metricSelection.some(({ name }) => name);
  const audiencesSelected = Boolean(
    selectedAudiences &&
      selectedAudiences.filter(({ name }) => Boolean(name)).length > 0,
  );
  const hasFunnelStepsSelected =
    funnelSteps.length > 1 &&
    funnelSteps.every(({ eventModelId }) => Boolean(eventModelId));
  const queryConfigured = hasMetricOrEventSelected && audiencesSelected;
  const funnelQueryConfigured = hasFunnelStepsSelected && audiencesSelected;

  const disableAccumulationSelection = metricSelection.some(
    ({ id }) => id === PredefinedMetric.AudienceSize,
  );
  useEffect(() => {
    if (disableAccumulationSelection) {
      form.setValue("cumulative", false);
    }
  }, [disableAccumulationSelection]);

  useEffect(() => {
    // TODO(Samuel): could have a bug here when other loading states are triggered...
    if (!isLoading) {
      onRowSelect(graph.series.map(({ key }) => key));
    }
  }, [isLoading, graph.series]);

  const isPerformance = graphType === GraphType.Performance;
  const isBreakdown = graphType === GraphType.Breakdown;
  const isFunnels = graphType === GraphType.Funnel;
  const isTable = graphType === GraphType.Table;

  if (unauthorized) {
    return <PermissionsPlaceholder />;
  }

  return (
    <Row flex={1} pos="relative">
      <AnalyticsSidebar
        ignoreHeaderHeight={ignoreHeaderHeight}
        funnelsErrors={funnelsErrors}
        isLoading={isLoading}
        isSavedView={isSavedView}
        metricSeriesErrors={metricSeriesErrors}
      />

      <Column
        // Summary Stats height is not fixed. If we try to fit all of the cotent in one viewport,
        // the graph will likely be too small, so just make the content scrollable instead.
        height={
          ignoreHeaderHeight
            ? undefined
            : hasSummaryStats
              ? "100%"
              : `calc(100vh - ${headerHeight}px)`
        }
        width="100%"
        overflowX="auto"
      >
        {isFunnels &&
          (funnelQueryConfigured ? (
            <FunnelGraph
              data={funnelsData}
              hasErrors={Object.values(funnelsErrors).length > 0}
              isLoading={isLoading}
            />
          ) : (
            <FunnelsPlaceholder />
          ))}
        {isBreakdown &&
          (queryConfigured ? (
            <AnalyticsBreakdown
              data={graph.series}
              groupByColumns={groupByColumns.filter(isPresent)}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isTable &&
          (queryConfigured ? (
            <AnalyticsTable
              data={graph.series}
              isLoading={isLoading}
              lookbackWindow={timeValue}
              selectedDates={selectedDates}
              onSelectDateRange={setSelectedDates}
              onUpdateLookbackWindow={setLookbackWindow}
            />
          ) : (
            <UnconfiguredPlaceholder />
          ))}
        {isPerformance &&
          (queryConfigured ? (
            <>
              <Row
                align="center"
                justify="space-between"
                mt={4}
                pl="30px"
                pr="50px"
                gap={2}
                width="100%"
              >
                <Row gap={2}>
                  <Controller
                    control={form.control}
                    name="rollupFrequency"
                    render={({ field }) => (
                      <Select
                        options={RollupFrequencies}
                        value={field.value}
                        width="auto"
                        onChange={field.onChange}
                        size="sm"
                      />
                    )}
                  />
                  <ToggleButtonGroup
                    size="sm"
                    value={timeValue}
                    onChange={(value) => {
                      setLookbackWindow(value as TimeOptions);
                    }}
                  >
                    {LookbackOptions.map((option) => (
                      <ToggleButton key={option.value} {...option} />
                    ))}
                  </ToggleButtonGroup>
                  <DateRangePicker
                    selectedDates={selectedDates}
                    onChange={(dates) => {
                      setSelectedDates(dates);
                    }}
                  >
                    <Box
                      as={Button}
                      background={
                        timeValue === TimeOptions.Custom ? "gray.200" : "unset"
                      }
                      fontWeight={
                        timeValue === TimeOptions.Custom ? "semibold" : "normal"
                      }
                      icon={MeetingIcon}
                      size="sm"
                      onClick={noop}
                    >
                      {formatDatePickerLabel(selectedDates, timeValue)}
                    </Box>
                  </DateRangePicker>
                </Row>
                <Row align="center" gap={1}>
                  <Controller
                    control={form.control}
                    name="cumulative"
                    render={({ field }) => (
                      <Select
                        isDisabled={disableAccumulationSelection}
                        optionAccessory={({ icon }) => ({
                          type: "icon",
                          icon,
                        })}
                        options={AccumulationTypes}
                        value={field.value}
                        size="sm"
                        width="3xs"
                        onChange={field.onChange}
                      />
                    )}
                  />
                  {disableAccumulationSelection && (
                    <Tooltip
                      message='Audience size can only be displayed "over time"'
                      placement="top-end"
                    >
                      <Text color="color.secondary">
                        <InformationIcon />
                      </Text>
                    </Tooltip>
                  )}
                </Row>
              </Row>
              {!isLoading && <SummaryStats summaryStats={graph.summary} />}
              <Column flex={1} minHeight={0}>
                <CrossAudienceGraph
                  graph={graph}
                  isLoading={isLoading}
                  scale={scale}
                  selectedRows={selectedRows}
                  onSelect={onRowSelect}
                  placeholder={
                    <>
                      <Column
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Column
                          textAlign="center"
                          width={placeholderContentWidthPx}
                        >
                          <AnalyticsPlaceholderImage />
                        </Column>
                        <SectionHeading>No data</SectionHeading>
                        {errorMessage}
                      </Column>
                    </>
                  }
                />
              </Column>
            </>
          ) : (
            <UnconfiguredPlaceholder />
          ))}
      </Column>
    </Row>
  );
};
