import { Text, Box, Column, Row } from "@hightouchio/ui";
import { Link } from "src/router";
import { useUser } from "src/contexts/user-context";
import { HightouchLogo } from "src/ui/icons";
import { Logo as MobileLogo } from "src/ui/brand";
import { useFlags } from "launchdarkly-react-client-sdk";

export const Logo = () => {
  const { user } = useUser();
  const { organizationLogoCobrandingHidden } = useFlags();

  return (
    <>
      <Box
        display={["none", "none", "none", "block"]}
        width="100%"
        mx="auto"
        px={5}
        sx={{
          a: { display: "block" },
        }}
      >
        <Link aria-label="Home" href="/">
          {user?.organization?.logo_url ? (
            <Column align="flex-start" gap={2}>
              <Box
                as="img"
                src={user.organization.logo_url}
                alt={user.organization.name}
                height="32px"
                objectFit="contain"
              />
              {!organizationLogoCobrandingHidden && (
                <Row align="center" gap={2}>
                  <Text color="white" size="sm" fontWeight="medium">
                    Powered by
                  </Text>
                  <HightouchLogo height="100%" width="72px" />
                </Row>
              )}
            </Column>
          ) : (
            <HightouchLogo width={112} height="100%" />
          )}
        </Link>
      </Box>
      <Box
        display={["block", "block", "block", "none"]}
        mx="auto"
        px="auto"
        sx={{
          a: { display: "block", p: 2 },
        }}
      >
        <Link aria-label="Home" href="/">
          <MobileLogo size="22px" theme="light" />
        </Link>
      </Box>
    </>
  );
};
