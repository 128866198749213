import { FC, useState } from "react";

import {
  Box,
  Button,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
  LimitIcon,
  MergeIcon,
  NumberInput,
  Row,
  Select,
  Text,
  Tooltip,
  TraitIcon,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import orderBy from "lodash/orderBy";
import { Controller } from "react-hook-form";
import * as Yup from "yup";

import { FieldError } from "src/components/field-error";
import { Form, SaveButton, useHightouchForm } from "src/components/form";
import {
  FilterableColumn,
  isMergedColumn,
  isRelatedColumn,
  isTraitColumn,
  SizeCap,
  TraitDefinition,
} from "src/types/visual";
import { accurateCommaNumber } from "src/utils/numbers";

import { PreviewableColumn } from "./types";
import { traitDefinitionToPreviewableColumn } from "./utils";
import { ResourcePermissionInput } from "../permission/use-resource-permission";
import { PermissionedIconButton } from "../permission";

type FormValues = {
  bottom: SizeCap["bottom"];
  limit: SizeCap["limit"] | undefined;
  orderByProperty: SizeCap["orderByProperty"] | undefined;
};

const defaultFormValues: FormValues = {
  bottom: false,
  limit: undefined,
  orderByProperty: undefined,
};

const validationSchema = Yup.object().shape({
  bottom: Yup.bool().required("direction is required"),
  limit: Yup.number()
    .integer()
    .min(0)
    .typeError("limit must be a number")
    .required("limit is required"),
  orderByProperty: Yup.object().required("property is required"),
});

type AudienceSizeCapFormProps = {
  properties: FilterableColumn[];
  traits: TraitDefinition[];
  onSave: (payload: SizeCap | undefined) => Promise<void>;
  data: SizeCap | undefined;
};

const DirectionOptions = [
  { label: "Top", value: false, description: "Descending (∞->0, Z->A)" },
  { label: "Bottom", value: true, description: "Ascending (0->∞, A->Z)" },
];

export const AudienceSizeCapForm: FC<Readonly<AudienceSizeCapFormProps>> = ({
  properties,
  traits,
  data,
  onSave,
}) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const previewableTraits = traits.map(traitDefinitionToPreviewableColumn);
  const formattedColumns: PreviewableColumn[] =
    properties.map((column) => ({
      alias: column.alias,
      name: column.name,
      model_name: column.model_name,
      column_reference: column.column_reference,
    })) ?? [];

  const previewableColumns: PreviewableColumn[] = [
    ...formattedColumns,
    ...previewableTraits,
  ];

  const columnOptions = orderBy(
    previewableColumns,
    ["column_reference.column.type", "alias", "name"],
    ["desc", "asc", "asc"],
  );

  const form = useHightouchForm<SizeCap>({
    defaultValues: defaultFormValues,
    values: data,
    resolver: yupResolver(validationSchema),
    onSubmit: onSave,
  });

  const {
    control,
    submit,
    formState: { isDirty, isSubmitting },
    reset,
  } = form;

  const removeSizeCap = async () => {
    setIsLoading(true);

    try {
      await onSave(undefined);
      reset(defaultFormValues);

      toast({
        id: "save",
        title: "Audience size cap removed",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "save",
        title: "Failed to remove size cap",
        message: error.message,
        variant: "error",
      });

      captureException(error);
    }

    setIsLoading(false);
  };

  return (
    <Form form={form}>
      <Column gap={4} p={4}>
        <Column>
          <Text fontWeight="medium" size="lg">
            Limit audience size
          </Text>
          <Row color="text.secondary">
            Cap your audience to a subset of members.
          </Row>
        </Column>
        <Row gap={2}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-ignore - Circular reference problem with Column types */}
          <Controller
            control={control}
            name="bottom"
            render={({ field, fieldState: { error } }) => (
              <Box width="110px">
                <Select
                  removePortal
                  isDisabled={isLoading}
                  isInvalid={Boolean(error)}
                  value={field.value}
                  options={DirectionOptions}
                  onChange={field.onChange}
                />
                <FieldError error={error?.message} />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="limit"
            render={({ field, fieldState: { error } }) => {
              return (
                <Column width="100px">
                  <NumberInput
                    isDisabled={isLoading}
                    placeholder="Limit"
                    isInvalid={Boolean(error?.message)}
                    value={field.value}
                    onChange={field.onChange}
                  />
                  <FieldError error={error?.message} />
                </Column>
              );
            }}
          />
          <Box py={1.5} sx={{ span: { textWrap: "nowrap" } }}>
            <Text>members, sorted by</Text>
          </Box>
          <Controller
            control={control}
            name="orderByProperty"
            render={({ field, fieldState: { error } }) => (
              <Box width="180px">
                <Select
                  isDisabled={isLoading}
                  isInvalid={Boolean(error)}
                  placeholder="Select a property..."
                  value={field.value}
                  options={columnOptions}
                  optionValue={(column) => column.column_reference}
                  _internalRenderLabelContent={(column) => (
                    <>
                      {column.alias || column.name}
                      {isMergedColumn(column.column_reference) && (
                        <Row
                          as={Text}
                          color="text.secondary"
                          align="center"
                          display="contents"
                        >
                          <Box as={MergeIcon} flexShrink={0} ml={1} />{" "}
                          {column.model_name}
                        </Row>
                      )}
                      {isRelatedColumn(column.column_reference) &&
                        isTraitColumn(column.column_reference.column) && (
                          <Row
                            as={Text}
                            color="text.secondary"
                            align="center"
                            display="contents"
                          >
                            <Box as={TraitIcon} flexShrink={0} mx={1} />
                            {column.model_name}
                          </Row>
                        )}
                    </>
                  )}
                  onChange={field.onChange}
                />
                <FieldError error={error?.message} />
              </Box>
            )}
          />
        </Row>
        <Row gap={2} justifyContent="right" pt={2}>
          <Button
            isLoading={isLoading}
            isDisabled={data == null || isSubmitting || isLoading}
            onClick={removeSizeCap}
            variant="warning"
          >
            Remove limit
          </Button>
          <SaveButton
            isDisabled={!isDirty || isSubmitting || isLoading}
            onClick={submit}
            size="md"
          >
            Save
          </SaveButton>
        </Row>
      </Column>
    </Form>
  );
};

export const AudienceSizeCap: FC<
  AudienceSizeCapFormProps & {
    permission: ResourcePermissionInput<"model", "audience">;
  }
> = ({ onSave, data, permission, ...props }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const save = async (payload: SizeCap | undefined) => {
    await onSave(payload);
    onClose();
  };

  return (
    <ChakraPopover
      isLazy
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom-start"
    >
      <ChakraPopoverTrigger>
        <Column>
          {data ? (
            <Tooltip
              message="Cap the size of the audience"
              placement="top"
              openSpeed="slow"
            >
              <Button icon={LimitIcon} variant="secondary" onClick={onToggle}>
                Limit: {accurateCommaNumber(data.limit)}
              </Button>
            </Tooltip>
          ) : (
            <PermissionedIconButton
              placement="top"
              tooltip="Cap the size of the audience"
              permission={permission}
              aria-label="Open menu."
              icon={LimitIcon}
              variant="secondary"
              onClick={onToggle}
            />
          )}
        </Column>
      </ChakraPopoverTrigger>

      <ChakraPopoverContent width="min-content">
        <AudienceSizeCapForm {...props} data={data} onSave={save} />
      </ChakraPopoverContent>
    </ChakraPopover>
  );
};
