import { Column, Row, SectionHeading, Text } from "@hightouchio/ui";

import { Link, useParams } from "src/router";
import { ActionBar } from "src/components/action-bar";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { ResourceGrantSelect } from "src/components/grant-select";
import { Grants } from "src/components/grant-select/types";
import { convertGrants } from "src/components/grant-select/util";
import { useUpdateSourceV2Mutation } from "src/graphql";

export const SourceGrants = () => {
  const { id } = useParams<{ id: string }>();

  const { mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  const form = useHightouchForm({
    onSubmit: async (data) => {
      await updateSource({
        id: String(id),
        grants: convertGrants(data.grants),
      });
    },

    defaultValues: {
      grants: {} as Grants<"source">,
    },
  });

  return (
    <Row justifyContent="space-between">
      <Form form={form}>
        <Column flex={1}>
          <SectionHeading>Permissions</SectionHeading>
          <Text mb={2}>
            Use the checkboxes below to configure which actions each user group
            can perform for this source and its related resources.{" "}
            <Link
              href={`${import.meta.env.VITE_DOCS_URL}/workspace-management/roles#source`}
              isExternal
            >
              See documentation
            </Link>{" "}
            for details.
          </Text>
          <ResourceGrantSelect id={id!} type="source" />
        </Column>
        <ActionBar>
          <FormActions
            permission={{
              v2: { resource: "source", grant: "can_update", id: id! },
            }}
          />
        </ActionBar>
      </Form>
    </Row>
  );
};
