import { FC } from "react";

import {
  MonitorConditionEvaluationProperties,
  MonitoredResourceType,
  ParentResourceTypes,
  SupportedConditionsForResourceTypes,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Text,
  Column,
  Heading,
  Row,
  SectionHeading,
  ExternalLinkIcon,
} from "@hightouchio/ui";

import { LinkButton } from "src/router";
import { MonitorConditionForm } from "src/components/resource-alert-triggers/monitor-condition-forms/monitor-condition-form";
import {
  useAllMonitorConditionsForResourceQuery,
  useDeleteResourceMonitorConditionMutation,
  useUpsertMonitorConditionsMutation,
} from "src/graphql";
import { ResourceRecipients } from "./resource-recipients";
import { MonitoredResourceTypeToLabel } from "./utils";

type Props = {
  resourceType: MonitoredResourceType;
  resourceId: string;
  parentResourceType?: ParentResourceTypes;
  parentResourceId?: string;
};

export const ResourceTriggers: FC<Props> = ({
  resourceType,
  resourceId,
  parentResourceType,
  parentResourceId,
}) => {
  const deleteCondition = useDeleteResourceMonitorConditionMutation();

  const { data } = useAllMonitorConditionsForResourceQuery({
    resourceId,
    resourceType,
    parentResourceType,
    parentResourceId,
    hasParentResource: Boolean(parentResourceType && parentResourceId),
  });

  const conditions = data?.resource_monitor_conditions ?? [];
  const templates = data?.monitor_condition_templates ?? [];

  const upsert = useUpsertMonitorConditionsMutation();

  const onSubmit = async ({ condition }) => {
    await upsert.mutateAsync({
      objects: [
        {
          evaluation_trigger:
            MonitorConditionEvaluationProperties[condition.type]
              .EvaluationTrigger,
          evaluation_type:
            MonitorConditionEvaluationProperties[condition.type].EvaluationType,
          type: condition.type,
          enabled: condition.enabled,
          error_value: condition.error_value,
          warning_value: condition.warning_value,
          resource_id: resourceId,
          resource_type: resourceType,
        },
      ],
    });
  };

  const label = MonitoredResourceTypeToLabel[resourceType];

  return (
    <Column gap={6}>
      <Row justify="space-between">
        <Column gap={1}>
          <Heading>{label}-level alert settings</Heading>
          {parentResourceType && (
            <Text color="text.secondary">
              {`${label} inherits alerts from the ${parentResourceType.toLowerCase()} defaults. You can view and override them here.`}
            </Text>
          )}
        </Column>
        <LinkButton href="/alerting/alerts" directionIcon={ExternalLinkIcon}>
          See alerts for all destinations
        </LinkButton>
      </Row>
      <ResourceRecipients
        resourceId={resourceId}
        resourceType={resourceType}
        parentResourceId={parentResourceId}
      />
      <Column gap={2}>
        <Row gap={4} align="center" justify="space-between">
          <Column>
            <SectionHeading>Triggers</SectionHeading>
            <Text color="text.secondary">Send alerts when:</Text>
          </Column>
        </Row>
        {SupportedConditionsForResourceTypes[resourceType].map(
          (conditionType) => {
            const condition = conditions.find((c) => c.type === conditionType);
            const template = templates.find((t) => t.type === conditionType);
            const onRestoreDefault = async () => {
              await deleteCondition.mutateAsync({
                conditionType,
                resourceIds: [resourceId],
                resourceType,
              });
            };
            return (
              <MonitorConditionForm
                key={conditionType}
                conditionType={conditionType}
                conditions={[condition ?? template]}
                onSubmit={onSubmit}
                onRestoreDefault={condition ? onRestoreDefault : undefined}
              />
            );
          },
        )}
      </Column>
    </Column>
  );
};
