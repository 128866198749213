import {
  Box,
  ClipboardButton,
  Column,
  EditableDescription,
  ExternalLinkIcon,
  Row,
  Skeleton,
  Text,
  useToast,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { Sync } from "src/pages/syncs/sync/utils/types";
import { MetadataLabel } from "src/components/metadata-bar";
import { useUser } from "src/contexts/user-context";
import { describeWhenSyncRuns } from "src/utils/syncs";
import { useResourcePermission } from "src/components/permission/use-resource-permission";
import {
  LatestJourneyVersionFromDestinationInstanceIdQuery,
  SequencesForSyncQuery,
  useLatestJourneyVersionFromDestinationInstanceIdQuery,
  useSequencesForSyncQuery,
  useUpdateSyncMutation,
} from "src/graphql";
import { Labels } from "src/components/labels/labels";
import { formatFriendlyDistanceToNow } from "src/utils/time";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useState } from "react";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { ResourceType } from "src/components/labels/use-labels";
import * as analytics from "src/lib/analytics";
import { SyncHealthOrLastStatusBadge } from "src/pages/syncs/sync/components/sync-health-or-last-status-badge";
import { useDestinationMetadata } from "src/pages/syncs/sync/utils/use-destination-metadata";
import {
  isAudienceStatusProcessing,
  isMatchedUsersCountSettling,
} from "src/utils/match-boosting";
import {
  DestinationMetadataDrawer,
  processAudienceSize,
} from "src/pages/syncs/sync/components/external-data";
import { Link, useNavigate } from "src/router";
import { Schedule, ScheduleType } from "src/components/schedule/types";
import { VisualCron } from "src/components/schedule/schedule";
import { ElementOf } from "ts-essentials";

const SIDEBAR_WIDTH = "288px";

type Props = {
  sync: Sync;
};

export const MetadataSidebar = ({ sync }: Props) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const navigate = useNavigate();

  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [isMetadataDrawerOpen, setMetadataDrawerOpen] = useState(false);

  const { mutateAsync: updateSync } = useUpdateSyncMutation();

  const {
    isPermitted: hasUpdatePermission,
    isLoading: hasPermissionIsLoading,
  } = useResourcePermission({
    v2: { resource: "sync", grant: "can_update", id: sync.id },
  });

  const {
    isSupported: isDestinationMetadataSupported,
    metadata: destinationMetadata,
    firstRun,
  } = useDestinationMetadata(sync.id);

  const { data: sequences, isLoading: sequencesIsLoading } =
    useSequencesForSyncQuery(
      { syncId: String(sync.id) },
      {
        enabled: Boolean(sync.id),
        select: (data) => data.sync_sequences,
      },
    );

  const isJourneyTriggered =
    sync.schedule?.type === ScheduleType.JOURNEY_TRIGGERED;

  const { data: journeys, isLoading: journeysIsLoading } =
    useLatestJourneyVersionFromDestinationInstanceIdQuery(
      { destination_instance_id: String(sync.id) },
      {
        enabled: isJourneyTriggered && Boolean(sync.id),
        select: (data) => data.journeys,
      },
    );

  const updateDescription = async (description: string) => {
    if (!sync) {
      return;
    }

    try {
      await updateSync({
        id: sync.id,
        object: {
          description,
        },
      });

      analytics.track("Sync Edited", {
        sync_id: sync.id,
        destination_type: sync.destination?.definition?.name,
        schedule_type: sync.schedule?.type,
        source_type: sync.segment?.connection?.type,
      });

      if (!workspace?.approvals_required) {
        toast({
          id: "update-sync",
          title: "Sync description updated",
          variant: "success",
        });
      }
    } catch (error) {
      toast({
        id: "update-sync-description",
        title: "Failed to update sync description",
        message: error.message,
        variant: "error",
      });
    }
  };

  const isLoading =
    hasPermissionIsLoading || sequencesIsLoading || journeysIsLoading;

  const hasLabels = Boolean(Object.keys(sync.tags).length);

  const hasSchedule =
    Boolean(sync.schedule) && sync.schedule?.type !== ScheduleType.MANUAL;
  const hasSequences = sequences && sequences.length > 0;

  return (
    <Skeleton isLoading={isLoading}>
      <Card gap={4} width={SIDEBAR_WIDTH} p={4}>
        <Column gap={3}>
          <MetadataLabel>Status</MetadataLabel>
          {sync && (
            <SyncHealthOrLastStatusBadge
              status={sync.status}
              health={sync.health}
              syncRequests={sync.sync_requests}
              schedulePaused={Boolean(sync.schedule_paused)}
              lastRunAt={sync.last_run_at}
              isInitialDraft={Boolean(sync.draft)}
            />
          )}
        </Column>
        <Divider />
        <Column gap={2}>
          <Row justifyContent="space-between" alignItems="center">
            <MetadataLabel>Schedule</MetadataLabel>
            <Link href={`/syncs/${sync.id}/schedule`} fontSize="sm">
              Edit schedule
            </Link>
          </Row>
          <Column>
            {!hasSchedule && hasSequences ? null : (
              <ScheduleDisplay
                schedule={sync.schedule}
                journey={journeys?.[0]}
              />
            )}
            {hasSequences && <SequenceDisplay sequences={sequences} />}
          </Column>
        </Column>
        <Divider />
        <Column gap={2} mb={-2}>
          <MetadataLabel>Description</MetadataLabel>
          <EditableDescription
            isDisabled={!hasUpdatePermission}
            value={sync.description ?? ""}
            onChange={updateDescription}
            width="100%"
          />
        </Column>
        <Divider />
        {sync.sync_template_id && (
          <>
            <Column gap={2}>
              <MetadataLabel>Template</MetadataLabel>
              <Link href={`/schema-v2/sync-templates/${sync.sync_template_id}`}>
                {sync.sync_template?.name ?? "Template"}
                <ExternalLinkIcon ml={2} />
              </Link>
            </Column>
            <Divider />
          </>
        )}
        <Column gap={2}>
          <Row justifyContent="space-between" alignItems="center">
            <MetadataLabel>Labels</MetadataLabel>
            {hasUpdatePermission && (
              <Link
                href=""
                fontSize="sm"
                onClick={() => setIsEditLabelModalOpen(true)}
              >
                {hasLabels ? "Edit labels" : "Add labels"}
              </Link>
            )}
          </Row>
          {hasLabels ? <Labels labels={{ ...sync.tags }} /> : <EmptyItem />}
        </Column>
        <Divider />
        {isDestinationMetadataSupported && Boolean(destinationMetadata) && (
          <>
            <Column gap={2}>
              <Row justifyContent="space-between" alignItems="center" gap={1}>
                <MetadataLabel>Matched users</MetadataLabel>
                <Link
                  href=""
                  fontSize="sm"
                  onClick={() => setMetadataDrawerOpen(true)}
                >
                  See details
                </Link>
              </Row>
              {isAudienceStatusProcessing(destinationMetadata) ||
              isMatchedUsersCountSettling(
                firstRun?.created_at,
                destinationMetadata,
                sync.config.useExistingSegment,
              )
                ? "Processing... check back later."
                : processAudienceSize(
                    destinationMetadata.audienceSize,
                    destinationMetadata.matchRate,
                    sync.config.useExistingSegment,
                    sync.config.deleteMode,
                  )}
            </Column>
            <Divider />
          </>
        )}
        <Column gap={0.5} mb={-2}>
          <MetadataLabel>Sync Id</MetadataLabel>
          <CopyableIdItem id={sync.id ?? ""} />
        </Column>
        <Divider />
        {sync.slug && (
          <>
            <Column gap={0.5} mb={-2}>
              <MetadataLabel>Sync slug</MetadataLabel>
              <CopyableIdItem id={sync.slug} />
            </Column>
            <Divider />
          </>
        )}
        {sync.updated_by_user && sync.updated_at && (
          <>
            <Column gap={2}>
              <MetadataLabel>Last edited by</MetadataLabel>
              <TimeDiffItem
                label={sync.updated_by_user.name}
                timestamp={sync.updated_at}
              />
            </Column>
            <Divider />
          </>
        )}
        <Column gap={2}>
          <MetadataLabel>Created by</MetadataLabel>
          {sync.created_by_user && sync.created_at ? (
            <TimeDiffItem
              label={sync.created_by_user.name}
              timestamp={sync.created_at}
            />
          ) : (
            <EmptyItem />
          )}
        </Column>
      </Card>
      <EditLabelModal
        isOpen={isEditLabelModalOpen}
        labels={sync.tags}
        resourceType={ResourceType.Sync}
        onClose={() => setIsEditLabelModalOpen(false)}
        onSubmit={(labels) =>
          updateSync({
            id: sync.id ?? "",
            object: {
              tags: labels,
            },
          })
        }
      />
      <DestinationMetadataDrawer
        isOpen={isMetadataDrawerOpen}
        onClose={() => {
          setMetadataDrawerOpen(false);
        }}
        model={sync.segment}
        sync={sync}
        metadata={destinationMetadata}
        syncConfigTabCallBack={() => {
          setMetadataDrawerOpen(false);
          navigate(`/syncs/${sync.id}/configuration`);
        }}
        firstSyncRun={firstRun}
      />
    </Skeleton>
  );
};

const ScheduleDisplay = ({
  schedule,
  journey,
}: {
  schedule: Schedule | null;
  journey:
    | ElementOf<LatestJourneyVersionFromDestinationInstanceIdQuery["journeys"]>
    | undefined;
}) => {
  if (schedule?.type === ScheduleType.CUSTOM) {
    const expressions = schedule.schedule?.expressions ?? [];

    // By default, the `VisualCron` component will roll up multiple expressions into a +X more popover.
    // We don't want that behavior here, so instead just render each expression individually.
    return (
      <Column gap={2}>
        {expressions.map((expression, index) => (
          <VisualCron key={index} expressions={[expression]} />
        ))}
      </Column>
    );
  }

  if (schedule?.type === ScheduleType.JOURNEY_TRIGGERED && journey) {
    return (
      <Text>
        Runs as part of a journey (
        <Link href={`/journeys/${journey.id}`}>{journey.name}</Link>)
      </Text>
    );
  }

  return <TextWithTooltip>{describeWhenSyncRuns(schedule)}</TextWithTooltip>;
};

const SequenceDisplay = ({
  sequences,
}: {
  sequences: SequencesForSyncQuery["sync_sequences"];
}) => {
  return (
    <Text>
      Runs as part of a sequence (
      {sequences.map((sequence, index) => (
        <>
          <Link href={`/sequences/${sequence.id}`} key={sequence.id}>
            {sequence.name}
          </Link>
          {index < sequences.length - 1 ? ", " : ""}
        </>
      ))}
      )
    </Text>
  );
};

const CopyableIdItem = ({ id }: { id: string }) => (
  <Row align="center" gap={1}>
    <TextWithTooltip isMonospace size="sm">
      {id}
    </TextWithTooltip>
    <ClipboardButton text={id} />
  </Row>
);

const TimeDiffItem = ({
  label,
  timestamp,
}: {
  label: string;
  timestamp: string;
}) => (
  <Row columnGap={2} flexWrap="wrap">
    <TextWithTooltip>{label}</TextWithTooltip>
    <TextWithTooltip color="text.secondary">
      {formatFriendlyDistanceToNow(timestamp)}
    </TextWithTooltip>
  </Row>
);

const EmptyItem = () => <Text color="text.secondary">--</Text>;

const Divider = () => <Box borderTop="1px solid" borderColor="base.border" />;
