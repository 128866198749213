import { createContext, useContext, FC, ReactNode } from "react";

import {
  BaseFormkitContextType,
  ColumnOption,
  useFormkitContext,
} from "src/formkit/components/formkit-context";

type Props = {
  children?: ReactNode;
  errors: any;
  setErrors: (error: any) => void;
  config: any;
  setConfig: (config: any) => void;
  setCustomValidation: (object: {
    validate: (config: any) => Promise<{ yupError?: any; otherError?: any }>;
  }) => void;
};

export type DestinationFormContextType = Props &
  BaseFormkitContextType & {
    hightouchColumns: ColumnOption[];
  };

export const DestinationFormContext = createContext<DestinationFormContextType>(
  {} as DestinationFormContextType,
);

export const useDestinationForm = () => useContext(DestinationFormContext);

export const DestinationFormProvider: FC<Readonly<Props>> = ({
  children,
  config,
  errors,
  setConfig,
  setCustomValidation,
  setErrors,
}) => {
  const {
    columns,
    destination,
    destinationDefinition,
    loadingModel,
    loadingRows,
    model,
    reloadModel,
    reloadRows,
    queryRowsError,
    rows,
    slug,
    sourceDefinition,
    supportsMatchboosting,
    sync,
    syncTemplate,
    validate,
    isModelDraft,
    draftChanges,
  } = useFormkitContext();

  return (
    <DestinationFormContext.Provider
      value={{
        config,
        destination,
        destinationDefinition,
        errors,
        hightouchColumns: columns,
        supportsMatchboosting,
        loadingModel,
        loadingRows,
        model,
        reloadModel,
        reloadRows,
        queryRowsError,
        rows,
        setConfig,
        setCustomValidation,
        setErrors,
        slug,
        sourceDefinition,
        sync,
        syncTemplate,
        validate,
        isModelDraft,
        draftChanges,
      }}
    >
      {children}
    </DestinationFormContext.Provider>
  );
};
