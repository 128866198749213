import { Navigate, RouteObject, useParams } from "src/router";

import { SourceLinking } from "./source/linking";
import { SourceGrants } from "./source/grants";
import { Sources } from "./sources";
import { SourceSyncLog } from "./source/sync-log";
import { SourceConfiguration } from "./source/configuration";
import * as Source from "./source";
import { CreateSource } from "./create-source";
import { DemoSourceConfiguration } from "./source/demo-configuration";
import { PermissionedRoute } from "src/components/router/permissioned-route";
import { useSourceQuery } from "src/graphql";

const ConfigurationRouter = () => {
  const { id } = useParams<{ id: string }>();
  const { data: source } = useSourceQuery(
    { id: String(id) },
    { select: (data) => data.connections_by_pk },
  );

  if (source!.definition.isSampleDataSource) {
    return <DemoSourceConfiguration />;
  }
  return <SourceConfiguration />;
};

export const routes: Array<RouteObject> = [
  { path: "sources", element: <Sources /> },
  {
    path: "sources/new",
    element: (
      <PermissionedRoute
        permission={{ v2: { resource: "source", grant: "can_create" } }}
        redirect="/sources"
      >
        <CreateSource />
      </PermissionedRoute>
    ),
  },
  {
    path: "sources/:id/*",
    element: <Source.Element />,
    children: [
      { index: true, element: <Navigate to="configuration" replace /> },
      { path: "configuration", element: <ConfigurationRouter /> },
      { path: "linking", element: <SourceLinking /> },
      { path: "permissions", element: <SourceGrants /> },
      { path: "sync-logs", element: <SourceSyncLog /> },
    ],
  },
];
