import { Node } from "reactflow";

import { JourneyNodeDetails } from "src/pages/journeys/types";
import {
  JourneyNodeConfig,
  JourneyNodeType,
  SegmentBranchConfig,
  SplitBranchConfig,
  WaitUntilEventBranchConfig,
} from "src/types/journeys";

export function isSegmentBranchNodeDetails(
  nodeData: JourneyNodeDetails<JourneyNodeConfig>,
): nodeData is JourneyNodeDetails<SegmentBranchConfig> {
  return nodeData.config.type === JourneyNodeType.SegmentBranch;
}

export function isSplitBranchNodeDetails(
  nodeData: JourneyNodeDetails<JourneyNodeConfig>,
): nodeData is JourneyNodeDetails<SplitBranchConfig> {
  return nodeData.config.type === JourneyNodeType.SplitBranch;
}

export function isSegmentBranchNode(
  node: Node<JourneyNodeDetails<JourneyNodeConfig>>,
): node is Node<JourneyNodeDetails<SegmentBranchConfig>> {
  return node.data.config.type === JourneyNodeType.SegmentBranch;
}

export function isSplitBranchNode(
  node: Node<JourneyNodeDetails<JourneyNodeConfig>>,
): node is Node<JourneyNodeDetails<SplitBranchConfig>> {
  return node.data.config.type === JourneyNodeType.SplitBranch;
}

export function isWaitUntilBranchNode(
  node: Node<JourneyNodeDetails<JourneyNodeConfig>>,
): node is Node<JourneyNodeDetails<WaitUntilEventBranchConfig>> {
  return node.data.config.type === JourneyNodeType.WaitUntilEventBranch;
}
