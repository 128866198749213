import { Box, NumberInput, Row, Text } from "@hightouchio/ui";
import { Controller } from "react-hook-form";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";

import { valueIsEmpty } from "src/components/resource-alert-triggers/utils";
import { ConditionSummary, Critical, Value, Warning } from "./utils";

export const SlowSyncsField = ({ name }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Row flexWrap="wrap" gap={1} align="center">
          <Text>Sync duration greater than</Text>
          <Box
            isInvalid={Boolean(error)}
            as={NumberInput}
            size="sm"
            display="inline-block"
            width="50px"
            value={value}
            onChange={onChange}
          />
          <Text>minutes</Text>
        </Row>
      )}
    />
  );
};

const Summary = ({ value }) => {
  return (
    <>
      alert if sync duration greater than <Value>{value}</Value> minutes
    </>
  );
};

export const SlowSyncsSummary: ConditionSummary<number> = ({
  prefix = true,
  condition,
}) => {
  return (
    <>
      {!valueIsEmpty(
        MonitorConditionTypes.SyncDuration,
        condition.error_value,
      ) && (
        <Critical prefix={prefix}>
          <Summary value={condition.error_value} />
        </Critical>
      )}
      {!valueIsEmpty(
        MonitorConditionTypes.SyncDuration,
        condition.warning_value,
      ) && (
        <Warning prefix={prefix}>
          <Summary value={condition.warning_value} />
        </Warning>
      )}
    </>
  );
};
