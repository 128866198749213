import { FC, ReactNode, useRef } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { ChakraUnorderedList, ChakraListItem, Box } from "@hightouchio/ui";

type UnorderedListProps = {
  items: {
    key: string | number;
    render: () => ReactNode;
  }[];
} & React.ComponentProps<typeof ChakraUnorderedList>;

export const VirtualizedUnorderedList: FC<UnorderedListProps> = ({
  items,
  ...props
}) => {
  const parentRef = useRef<HTMLUListElement>(null);

  const rowVirtualizer = useVirtualizer<HTMLElement, Element>({
    count: items.length,
    getScrollElement() {
      return parentRef.current;
    },
    estimateSize() {
      return 35;
    },
  });

  return (
    <ChakraUnorderedList
      ref={parentRef}
      height="100%"
      overflowY="auto"
      {...props}
    >
      <Box
        height={`${rowVirtualizer.getTotalSize()}px`}
        flexShrink={0}
        position="relative"
      >
        {rowVirtualizer.getVirtualItems().map((virtualRow) => {
          const item = items[virtualRow.index];

          if (!item) {
            return null;
          }

          return (
            <ChakraListItem
              key={item.key}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                height: `${virtualRow.size}px`,
                transform: `translateY(${virtualRow.start}px)`,
              }}
            >
              {item.render()}
            </ChakraListItem>
          );
        })}
      </Box>
    </ChakraUnorderedList>
  );
};
