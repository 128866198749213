import orderBy from "lodash/orderBy";
import { Edge, getOutgoers } from "reactflow";

import {
  JourneyNode,
  JourneyNodeDetails,
  SegmentPriorityList,
  SplitBranch,
} from "src/pages/journeys/types";
import {
  isSegmentBranchNodeDetails,
  isSplitBranchNodeDetails,
} from "src/pages/journeys/utils";
import {
  JourneyNodeType,
  SegmentBranchConfig,
  SplitBranchConfig,
} from "src/types/journeys";
import {
  AndOrCondition,
  ConditionType,
  PropertyCondition,
} from "src/types/visual";

/**
 * Ensure that the property conditions are wrapped in a top level AND condition
 */
export const getFormattedSubconditions = (
  subcondition: AndOrCondition<PropertyCondition>,
): AndOrCondition<PropertyCondition> => {
  // If top level condition is an AND condition, return
  if (subcondition.type === ConditionType.And) {
    return subcondition;
  }

  return {
    type: ConditionType.And,
    conditions: [subcondition],
  };
};

export const getOrderedEditableSegments = (
  nodes: JourneyNode[],
): SegmentPriorityList => {
  const nodeDetails = nodes.map(({ data }) => data);

  const segmentBranchNodes: JourneyNodeDetails<SegmentBranchConfig>[] =
    nodeDetails.filter(isSegmentBranchNodeDetails);

  const branchNodes = orderBy(
    segmentBranchNodes
      // Catch all node is omitted
      .filter((node) => !node.config.segment_is_catch_all),
    ["config.segment_priority_rank"],
    ["asc"],
  );

  return branchNodes.map((node) => ({
    id: node.id,
    name: node.name,
  }));
};

export const getSplitGroups = (nodes: JourneyNode[]): SplitBranch[] => {
  const nodeDetails = nodes.map(({ data }) => data);

  const splitBranchNodes: JourneyNodeDetails<SplitBranchConfig>[] =
    nodeDetails.filter(isSplitBranchNodeDetails);

  const branchNodes = orderBy(splitBranchNodes, ["asc"]);

  return branchNodes.map((node) => ({
    id: node.id,
    name: node.name,
    percentage: node.config.percentage,
  }));
};

/**
 * Traverse down and find all downstream nodes from the given one.
 **/
export const getAllDownstreamNodes = (
  sourceNode: JourneyNode,
  nodes: JourneyNode[],
  edges: Edge[],
  visited?: Map<string, JourneyNode[]>,
): JourneyNode[] => {
  const visitedNodes = visited || new Map<string, JourneyNode[]>();

  // If sourceNode has already been visited, return the stored downstream nodes
  if (visitedNodes.has(sourceNode.id)) {
    return visitedNodes.get(sourceNode.id)!;
  }

  // Get all the downstream (outgoing) nodes
  const downstreamNodes = sourceNode
    ? getOutgoers(sourceNode, nodes, edges)
    : [];
  const allChildren: JourneyNode[] = downstreamNodes;

  // Recursively get downstream nodes for each child
  downstreamNodes.forEach((childNode) => {
    if (visitedNodes.has(childNode.id)) {
      return;
    }

    allChildren.push(
      ...getAllDownstreamNodes(childNode, nodes, edges, visitedNodes),
    );
  });

  // Store the result in the visitedNodes map
  visitedNodes.set(sourceNode.id, allChildren);
  return allChildren;
};

function isTimeDelayNode(node: JourneyNode): boolean {
  return (
    node.type === JourneyNodeType.WaitUntilEvent ||
    node.type === JourneyNodeType.WaitUntilEventBranch ||
    node.type === JourneyNodeType.TimeDelay
  );
}

/**
 * Returns true/false based on if any of the downstream nodes have a time-based
 * or condition-based wait.
 **/
export const hasWaitNodeDownstream = (
  currentNode: JourneyNode,
  nodes: JourneyNode[],
  edges: Edge[],
): boolean => {
  const downstreamNodes = currentNode
    ? getAllDownstreamNodes(currentNode, nodes, edges)
    : [];

  if (downstreamNodes.length == 0) {
    return false;
  }

  return downstreamNodes.find(isTimeDelayNode) != null;
};
