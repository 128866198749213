import { FC, useState } from "react";

import {
  Alert,
  Box,
  ButtonGroup,
  CloseIcon,
  Column,
  ConfirmationDialog,
  DeleteIcon,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  IconButton,
  NumberInput,
  Paragraph,
  PlusIcon,
  Row,
  Text,
  TextInput,
  Tooltip,
} from "@hightouchio/ui";
import { v4 as uuidv4 } from "uuid";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { getOutgoers } from "reactflow";
import {
  DiscardButton,
  Form,
  SaveButton,
  useHightouchForm,
} from "src/components/form";
import { ErrorMessage } from "src/components/explore/visual/error-message";

import {
  PermissionedButton,
  PermissionedEditableHeading,
} from "src/components/permission";
import { JOURNEY_UPDATE_PERMISSION } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import {
  JourneyGraph,
  NodeDetailFormProps,
  SplitBranch,
} from "src/pages/journeys/types";
import { SplitsConfig } from "src/types/journeys";

import { getSplitGroups } from "./utils";
import { journeySplitConfigValidationResolver } from "./validation-schemas";
import { useFormErrorContext } from "src/contexts/form-error-context";
import { differenceBy } from "lodash";
import { isSplitBranchNodeDetails } from "src/pages/journeys/utils";

export const SplitsForm: FC<NodeDetailFormProps<SplitsConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const parentForm = useFormContext<JourneyGraph>();
  const {
    isEditMode,
    onAddSplitBranch,
    onCleanUp,
    onRemoveNode,
    onUpdateNode,
    onUpdateSplitGroups,
  } = useGraphContext();
  const { hasValidationErrors } = useFormErrorContext();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const nodes = parentForm.watch("nodes");
  const currentNode = nodes.find((node) => node.id === id);
  const edges = parentForm.watch("edges");

  const parentSplitsNode = currentNode
    ? getOutgoers(currentNode, nodes, edges)
    : [];

  const splits = getSplitGroups(parentSplitsNode);

  const splitsForm = useHightouchForm({
    reValidateMode: "onSubmit",
    onSubmit: async (splitNodeData) => {
      if (hasValidationErrors()) {
        throw new Error("Form has validation errors");
      }
      // Process deletions
      const splitsToRemove = differenceBy(
        parentSplitsNode.map(({ data: nodeData }) => nodeData),
        splitNodeData.splits,
        "id",
      ).filter((nodeData) => isSplitBranchNodeDetails(nodeData));
      splitsToRemove.forEach((split) => onRemoveNode(split.id));

      // Process additions
      const splitsToAdd = differenceBy(
        splitNodeData.splits,
        parentSplitsNode.map(({ data: nodeData }) => nodeData),
        "id",
      );
      splitsToAdd.forEach((split) => onAddSplitBranch(id, split));

      onUpdateNode(id, splitNodeData.node);
      onUpdateSplitGroups(splitNodeData.splits);

      await onCleanUp();
      onClose();

      return Promise.resolve();
    },
    success: "Tile was saved",
    onError: () => {
      // Keep empty to not trigger sentry validation
    },
    values: { node: { ...data }, splits: splits },
    resolver: (data, context, options) =>
      journeySplitConfigValidationResolver(data, context, options),
  });
  const { setValue, control, watch, getFieldState, formState, clearErrors } =
    splitsForm;

  const {
    fields: splitGroup,
    remove: removeSplitsFields,
    append: addSplitsFields,
    update: updateSplitFields,
  } = useFieldArray({
    control: control,
    name: "splits",
  });

  const splitGroupValues = watch("splits");
  const splitsErrors = formState?.errors?.splits;

  const addSplitGroup = async () => {
    // If we add a new split group, "stage" the changes on the form/drawer.
    // Only update the graph after we validate and click "update".
    const newId = uuidv4();
    const newSplitBranch: SplitBranch = {
      id: newId,
      name: `Group ${splitGroupValues.length + 1}`,
      percentage: 1,
    };

    addSplitsFields(newSplitBranch);
  };

  const deleteSplitGroup = (index: number) => {
    // Deletes an individual branch/group within the given split.
    // Only updates the graph after we validate and click "update".
    removeSplitsFields(index);

    const removedPercentage: number = splitGroupValues[index]
      ? splitGroupValues[index].percentage
      : 0;

    const sum =
      splitGroupValues.reduce(
        (partialSum, split) => partialSum + split.percentage,
        0,
      ) - removedPercentage;
    if (sum != 100) {
      return;
    }

    // Reallocate the percentage from the deleted group only if the current sum is 100.
    const splitIndexToUpdate = Math.max(index - 1, 0);
    if (splitGroup[splitIndexToUpdate] != null) {
      const updatedPercentage =
        removedPercentage + splitGroup[splitIndexToUpdate].percentage;
      setValue(`splits.${splitIndexToUpdate}.percentage`, updatedPercentage);
    }
  };

  const deleteSplitNode = (index: string) => {
    // Deletes the entire split node and its branches.
    splits.forEach((split) => {
      // Delete branches
      onRemoveNode(split.id);
    });

    // Delete main split node
    onRemoveNode(index);
  };

  const handleUpdateSplitGroup = (index: number, updatedSplit: SplitBranch) => {
    updateSplitFields(index, updatedSplit);
    clearErrors();

    // Check if we can rebalance the percentages
    if (splitGroupValues.length == 2 && updatedSplit.percentage != null) {
      const remainingPercentage = 100 - updatedSplit.percentage;

      splitGroupValues.forEach((_, otherIndex) => {
        if (otherIndex !== index) {
          setValue(`splits.${otherIndex}.percentage`, remainingPercentage);
        }
      });
    }
  };

  return (
    <Form form={splitsForm}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Controller
            name="node.name"
            control={control}
            render={({ field, fieldState }) => (
              <Column>
                <PermissionedEditableHeading
                  isDisabled={!isEditMode}
                  permission={JOURNEY_UPDATE_PERMISSION}
                  value={field.value}
                  onChange={field.onChange}
                />
                {fieldState.error?.message && (
                  <ErrorMessage fontWeight="normal">
                    {fieldState.error.message}
                  </ErrorMessage>
                )}
              </Column>
            )}
          />
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody backgroundColor="base.lightBackground">
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" justify="space-between">
            <Column>
              <Text fontWeight="medium" size="lg">
                Split groups
              </Text>
            </Column>

            <PermissionedButton
              isDisabled={!isEditMode}
              permission={JOURNEY_UPDATE_PERMISSION}
              icon={PlusIcon}
              onClick={addSplitGroup}
            >
              Add split group
            </PermissionedButton>
          </Row>

          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore - Circular reference problem with Condition types
            splitsErrors && splitsErrors?.at(0)?.percentage && (
              <Alert
                type="error"
                title={splitsErrors.at(0)?.percentage?.message || ""}
              />
            )
          }

          <Column gap={4}>
            {splitGroupValues.map((split, index) => {
              const percentageError = getFieldState(
                `splits.${index}.percentage`,
              ).error;

              const nameError = getFieldState(`splits.${index}.name`).error;

              return (
                <Row
                  display="flex"
                  background="white"
                  border="1px solid"
                  borderColor="base.border"
                  borderRadius="md"
                  boxShadow="sm"
                  justifyContent="space-between"
                  opacity={1}
                  position="relative"
                  key={split.id}
                  align="flex-start"
                  justify="space-between"
                  minWidth={0}
                  flex={1}
                  px={4}
                  gap={1}
                >
                  <Box
                    alignItems="flex-start"
                    display="grid"
                    justifyContent="center"
                    gridTemplateColumns="64px 1fr min-content"
                    columnGap={4}
                    p={4}
                    position="relative"
                  >
                    <Column justify="center" gap={0}>
                      <NumberInput
                        isDisabled={!isEditMode}
                        formatOptions={{ style: "percent" }}
                        min={0.01}
                        max={0.99}
                        isInvalid={Boolean(percentageError)}
                        placeholder="Enter a percentage between 1-99"
                        width="xs"
                        size="md"
                        {...split}
                        value={split.percentage / 100}
                        onChange={(value) => {
                          if (value == undefined) return;
                          handleUpdateSplitGroup(index, {
                            ...split,
                            percentage: Number((value * 100).toFixed(0)),
                          });
                        }}
                      />
                    </Column>

                    <Column justify="center" gap={0}>
                      <TextInput
                        {...split}
                        name={`splits.${index}.name`}
                        isDisabled={!isEditMode}
                        isInvalid={Boolean(nameError)}
                        value={split.name}
                        placeholder="Split group name"
                        onChange={(event) => {
                          handleUpdateSplitGroup(index, {
                            ...split,
                            name: event.target.value,
                          });
                        }}
                      />

                      {nameError?.message && (
                        <ErrorMessage>{nameError?.message}</ErrorMessage>
                      )}
                    </Column>
                  </Box>

                  {isEditMode && splitGroupValues.length > 2 && (
                    <Tooltip
                      aria-label="Remove split group."
                      message="Remove split group"
                      placement="top-end"
                    >
                      <IconButton
                        mt={4}
                        isDisabled={!isEditMode}
                        aria-label="Remove split group."
                        icon={DeleteIcon}
                        variant="danger"
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteSplitGroup(index);
                        }}
                      />
                    </Tooltip>
                  )}
                </Row>
              );
            })}
          </Column>
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <ButtonGroup>
            <SaveButton
              isDisabled={!isEditMode}
              aria-label="Update split group."
              permission={JOURNEY_UPDATE_PERMISSION}
            >
              Update
            </SaveButton>
            <DiscardButton
              onClick={() => {
                clearErrors();
              }}
            />
          </ButtonGroup>
        </DrawerFooter>
      )}

      <ConfirmationDialog
        isOpen={showConfirmationModal}
        confirmButtonText="Delete"
        title={`Delete segment '${splitsForm.watch("node.name")}'?`}
        variant="danger"
        onClose={() => setShowConfirmationModal(false)}
        onConfirm={() => deleteSplitNode(id)}
      >
        <Paragraph>Are you sure you want to delete this split group?</Paragraph>
      </ConfirmationDialog>
    </Form>
  );
};
