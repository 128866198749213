import { FC, useMemo } from "react";

import { Column, Row, Text, useToast } from "@hightouchio/ui";
import { useNavigate } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  JourneysBoolExp,
  JourneysOrderBy,
  useJourneysQuery,
} from "src/graphql";
import { JourneyStatus } from "src/types/journeys";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";

import { JourneyStatusBadge } from "./components";
import { JourneyFilterType } from "./constants";
import { getJourneyState, getNumberOfUsersInJourney } from "./utils";

enum SortKeys {
  Name = "name",
  Status = "status",
  UpdatedAt = "updated_at",
  CreatedAt = "created_at",
  ParentModel = "parent_model.name",
}

type JourneysTableProps = {
  type: JourneyFilterType;
};

export const JourneysTable: FC<JourneysTableProps> = ({ type }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { orderBy, onSort } = useTableConfig<JourneysOrderBy>({
    sortOptions: Object.values(SortKeys),
  });

  const { limit, offset, page, setPage } = useTableConfig({ limit: 50 });

  const filters: JourneysBoolExp = useMemo(() => {
    const baseFilter: JourneysBoolExp = {
      is_latest: { _eq: true },
    };
    const offAndDraftsSharedFilters: JourneysBoolExp = {
      status: { _eq: JourneyStatus.Disabled },
      nodes_aggregate: {
        count: {
          filter: {
            _and: [
              { number_users: { _neq: "0" } },
              { number_users: { _neq: null } },
            ],
          },
          predicate: { _eq: 0 },
        },
      },
    };

    switch (type) {
      case JourneyFilterType.LIVE:
        return {
          ...baseFilter,
          deleted: { _neq: true },
          status: { _eq: JourneyStatus.Enabled },
        };
      case JourneyFilterType.DRAFTS:
        return {
          ...baseFilter,
          ...offAndDraftsSharedFilters,
          deleted: { _neq: true },
          _or: [{ reset: { _neq: true } }, { reset: { _neq: null } }],
          runs_aggregate: { count: { predicate: { _eq: 0 } } },
        };
      case JourneyFilterType.OFF:
        return {
          ...baseFilter,
          ...offAndDraftsSharedFilters,
          runs_aggregate: { count: { predicate: { _gt: 0 } } },
        };
      case JourneyFilterType.ALL:
      default:
        return baseFilter;
    }
  }, [type]);

  const journeysQuery = useJourneysQuery({
    orderBy,
    filters,
    limit,
    offset,
  });

  const clickRow = (
    { id, deleted }: { id: string; deleted: boolean },
    event,
  ) => {
    if (deleted) {
      toast({
        id: "navigate-error",
        title: "This journey is being deleted and cannot be accessed",
        variant: "info",
      });
      return;
    }

    openUrl(`/journeys/${id}`, navigate, event);
  };

  const journeys = journeysQuery.data?.journeys;
  const count = journeysQuery.data?.journeys_aggregate?.aggregate?.count;

  return (
    <>
      <Table
        data={journeys}
        columns={[
          {
            onClick: () => onSort(SortKeys.Name),
            sortDirection: orderBy?.name,
            name: "Name",
            cell: ({ name, deleted, reset }) => (
              <Column>
                <TextWithTooltip fontWeight="semibold">{name}</TextWithTooltip>
                {deleted ? (
                  <Text color="text.secondary" size="sm">
                    Deletion in progress
                  </Text>
                ) : reset ? (
                  <Text color="text.secondary" size="sm">
                    Reset in progress
                  </Text>
                ) : null}
              </Column>
            ),
          },
          {
            onClick: () => onSort(SortKeys.Status),
            sortDirection: orderBy?.status,
            name: "Status",
            cell: ({ reset, status, nodes, runs_aggregate }) => (
              <JourneyStatusBadge
                status={getJourneyState({
                  reset: Boolean(reset),
                  status: status as JourneyStatus,
                  numberOfUsersInJourney: getNumberOfUsersInJourney(nodes),
                  numberOfPreviousRuns: runs_aggregate.aggregate?.count ?? 0,
                })}
              />
            ),
          },
          {
            onClick: () => onSort(SortKeys.ParentModel),
            sortDirection: orderBy?.parent_model?.name,
            name: "Parent model",
            cell: ({ parent_model }) => {
              return (
                <Row align="center" gap={2} overflow="hidden">
                  <IntegrationIcon
                    src={parent_model?.connection?.definition?.icon}
                    name={parent_model?.connection?.definition?.name}
                  />
                  <TextWithTooltip fontWeight="semibold">
                    {parent_model?.name ?? ""}
                  </TextWithTooltip>
                </Row>
              );
            },
          },
          {
            onClick: () => onSort(SortKeys.UpdatedAt),
            sortDirection: orderBy?.updated_at,
            ...LastUpdatedColumn,
            breakpoint: "md",
          },
        ]}
        loading={journeysQuery.isLoading || journeysQuery.isRefetching}
        error={Boolean(journeysQuery.error)}
        placeholder={{
          image: genericPlaceholder,
          title: `No ${
            type === JourneyFilterType.ALL ? "" : `${type.toLowerCase()} `
          }journeys`,
          body: "Create a new journey to start automating your workflows.",
          error: "Journeys failed to load, please try again.",
        }}
        onRowClick={clickRow}
      />
      <Row justifyContent="right" mt={4}>
        <Pagination
          count={count}
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </>
  );
};
