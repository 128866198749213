import { FC, useState } from "react";

import { ChannelType } from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  DeleteIcon,
  EditIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Row,
  Spinner,
  Text,
  useToast,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";

import { ChannelIcon } from "src/pages/alerting/recipients/components/channel-icon";
import { useNavigate } from "src/router";
import { channelName } from "./channel-definitions";

export const SubscribedResourceItem: FC<{
  channel: {
    channel_type: string;
    config: any;
    id: string;
  };
  onRemove?: () => Promise<unknown>;
}> = ({ channel, onRemove }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box width="max-content">
      <Menu closeOnSelect={false}>
        <MenuButton size="sm">
          <Row align="center" gap={1} fontSize="xl">
            <ChannelIcon
              channelType={channel.channel_type as ChannelType}
              size={4}
            />
            <Text color="text.secondary" fontWeight="medium">
              {channelName(channel)}
            </Text>
          </Row>
        </MenuButton>
        <MenuList>
          <MenuItem
            icon={EditIcon}
            onClick={() => {
              navigate(`/alerting/recipients?channel=${channel.id}`);
            }}
          >
            Edit recipient settings
          </MenuItem>
          {onRemove && (
            <MenuItem
              variant="danger"
              onClick={async () => {
                setIsLoading(true);
                try {
                  await onRemove();
                  toast({
                    id: "remove-recipient",
                    title: "Removed recipient",
                    variant: "success",
                  });
                } catch (e) {
                  captureException(e);
                  toast({
                    id: "remove-recipient",
                    title: "Error removing recipient",
                    variant: "error",
                  });
                } finally {
                  setIsLoading(false);
                }
              }}
            >
              <Row align="center" gap={3}>
                {isLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Box as={DeleteIcon} boxSize={6} />
                )}
                Remove recipient
              </Row>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

SubscribedResourceItem.displayName = SubscribedResourceItem.name;
