import { ComponentType, FC, SVGAttributes, useState } from "react";

import { useDisclosure } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "src/router";

import { PermissionedButton } from "src/components/permission";
import { useSyncTemplatesForParentModelQuery } from "src/graphql";
import { Audience } from "src/types/visual";

import { hasUnlockedFields } from "src/components/destinations/utils";
import { useSyncCreationPermissions } from "src/components/permission/creation/sync";
import { useSubsetPermissions } from "src/components/permission/use-subset-permissions";
import { useIsPermissionsV2Enabled } from "src/components/permission/utils";
import { ParentModel } from "src/pages/audiences/types";

import { AddSyncsFromTemplatesWizard } from "./add-syncs-from-templates-wizard";
import { AddSyncsFromTemplatesModal } from "./add-syncs-from-templates-modal";
import {
  SyncTemplateToCreateSync,
  useAddSyncTemplates,
} from "./use-add-sync-templates";
import { MinimalSyncTemplate } from "./types";

type Props = {
  audience: NonNullable<Audience>;
  parentModel?: NonNullable<ParentModel>;
  icon?: ComponentType<SVGAttributes<SVGElement>>;
  isDisabled?: boolean;
  tooltip?: string;
  variant?: "primary" | "tertiary";
};

export const AddSyncButton: FC<Readonly<Props>> = ({
  audience,
  parentModel,
  icon,
  isDisabled = false,
  tooltip,
  variant,
}) => {
  const { enableSyncTemplateOverrides } = useFlags();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const isPermissionsV2Enabled = useIsPermissionsV2Enabled();
  const navigate = useNavigate();
  const newSyncUrl = `/syncs/new?model=${audience.id}`;

  const [splitId, setSplitId] = useState<string | undefined>();
  const [syncTemplateIdsToConfigure, setSyncTemplateIdsToConfigure] = useState<
    number[]
  >([]);

  const { addSyncTemplates, isLoading: isAddingSyncs } = useAddSyncTemplates();

  const { data: syncTemplates, isLoading } =
    useSyncTemplatesForParentModelQuery(
      { parentModelId: parentModel?.id },
      { enabled: Boolean(parentModel), select: (data) => data.sync_templates },
    );

  const { permission: subsetPermission } = useSubsetPermissions(
    "audience",
    audience?.id,
  );

  const { destinationIds } = useSyncCreationPermissions(audience.id);

  const onClick = () => {
    if (syncTemplates?.length) {
      onToggle();
    } else {
      navigate(newSyncUrl);
    }
  };

  const selectSyncTemplates = async ({
    syncTemplates,
    splitId: incomingSplitId,
  }: {
    syncTemplates: MinimalSyncTemplate[];
    splitId?: string;
  }) => {
    if (
      enableSyncTemplateOverrides &&
      syncTemplates.some(({ override_config }) =>
        hasUnlockedFields(override_config),
      )
    ) {
      setSplitId(incomingSplitId);
      setSyncTemplateIdsToConfigure(syncTemplates.map(({ id }) => id));

      onClose();
    } else {
      await createSyncs({ splitId: incomingSplitId, syncTemplates });
    }
  };

  const createSyncs = async ({
    splitId: incomingSplitId,
    syncTemplates,
  }: {
    splitId?: string;
    syncTemplates: SyncTemplateToCreateSync[];
  }) => {
    await addSyncTemplates({
      audienceId: audience.id,
      splitId: incomingSplitId,
      syncTemplates,
    });

    setSyncTemplateIdsToConfigure([]);
    onClose();
  };

  return (
    <>
      <PermissionedButton
        icon={icon}
        isLoading={isLoading}
        tooltip={tooltip}
        isDisabled={
          isDisabled ||
          !subsetPermission ||
          (isPermissionsV2Enabled && !destinationIds.length)
        }
        permission={{ v1: { resource: "sync", grant: "create" } }}
        variant={variant}
        onClick={onClick}
      >
        Add sync
      </PermissionedButton>

      {isOpen && (
        <AddSyncsFromTemplatesModal
          isLoading={isAddingSyncs}
          audience={audience}
          syncTemplates={syncTemplates ?? []}
          onSubmit={selectSyncTemplates}
          onClose={onClose}
        />
      )}

      <AddSyncsFromTemplatesWizard
        isOpen={syncTemplateIdsToConfigure.length > 0}
        syncTemplateIds={syncTemplateIdsToConfigure}
        onSubmit={(data) => createSyncs({ syncTemplates: data, splitId })}
        onClose={() => setSyncTemplateIdsToConfigure([])}
      />
    </>
  );
};
