import {
  MonitorConditionProperties,
  MonitorConditionTypes,
  MonitoredResourceType,
  SupportedConditionsForResourceType,
  SupportedConditionsForResourceTypes,
  Threshold,
} from "@hightouch/lib/resource-monitoring/types";
import { isEqual, isNull, isUndefined, pick } from "lodash";
import { isArrayElement } from "src/types/utils";
import { MonitorDefinitions } from "./definitions";
import { GenericMonitorCondition } from "./monitor-condition-forms/monitor-condition-form";

export type MinimalCondition = {
  enabled: boolean;
  error_value?: any;
  warning_value?: any;
};

export function getMinimalCondition<T extends MinimalCondition>(condition: T) {
  return pick(condition, ["enabled", "error_value", "warning_value"]);
}

export const isOverride = (
  syncCondition: MinimalCondition,
  defaultCondition?: MinimalCondition,
) => {
  const syncConditionEnabled = syncCondition.enabled;
  const defaultConditionEnabled = defaultCondition?.enabled || false;

  if (
    (!syncConditionEnabled && !defaultConditionEnabled) || // If they're both disabled, it's not an override regardless of the values
    !defaultCondition // If there's no default at all, it's not an override
  ) {
    return false;
  }

  return !isEqual(syncCondition, defaultCondition);
};

// By default Yup errors look something like:
//   "SyncDuration.destinations.52495.error_value must be less than or equal to 1440"
// This doesn't really work for our purposes, so strip the prefix:
//   "Value must be less than or equal to 1440"
// Note: there are a lot of different patterns depending on the field, so regex has to be pretty generic
export const cleanFormError = (msg: string, valueName?: string) =>
  msg.replace(/\S*\s/, `${valueName ?? "Value"} `);

export const MonitoredResourceTypeToLabel: Record<
  MonitoredResourceType,
  string
> = {
  [MonitoredResourceType.Sync]: "Sync",
  [MonitoredResourceType.EventSource]: "Event source",
  [MonitoredResourceType.EventSync]: "Event sync",
};

export const isMandatoryCondition = ({
  conditionType,
  resourceType,
}: {
  conditionType: MonitorConditionTypes;
  resourceType: MonitoredResourceType;
}) => {
  return (
    resourceType === MonitoredResourceType.Sync &&
    (conditionType === MonitorConditionTypes.SyncSequentialFailures ||
      conditionType === MonitorConditionTypes.SyncRejectedRows)
  );
};

export function isSupportedConditionForResourceType<
  T extends MonitoredResourceType,
>(
  resourceType: T,
  condition: string,
): condition is SupportedConditionsForResourceType<T> {
  return isArrayElement(SupportedConditionsForResourceTypes[resourceType])(
    condition,
  );
}

export enum MonitorModes {
  CRITICAL = "CRITICAL",
  WARNING = "WARNING",
  CUSTOM = "CUSTOM",
  NONE = "NONE",
}

export type MonitorConditionFormType = {
  mode: MonitorModes;
  defaultModeSelection: MonitorModes.WARNING | MonitorModes.CRITICAL;
  condition: {
    error_value: GenericMonitorCondition["error_value"];
    warning_value: GenericMonitorCondition["warning_value"];
  };
};

export const valueIsEmpty = (
  conditionType: MonitorConditionTypes,
  value: any,
) => {
  if (conditionType === MonitorConditionTypes.SyncRejectedRows) {
    return (
      (isUndefined(value?.rejectedRowCount) ||
        isNull(value?.rejectedRowCount)) &&
      (isUndefined(value?.pctRejectedRows) || isNull(value?.pctRejectedRows))
    );
  }
  if (conditionType === MonitorConditionTypes.SyncThroughput) {
    return isUndefined(value?.count) && isUndefined(value?.sign);
  }
  return isUndefined(value) || isNull(value);
};

export type ThresholdType<T> = T extends Threshold<infer U> ? U : never;

export type MonitorValueType<T extends MonitorConditionTypes> = ThresholdType<
  MonitorConditionProperties[T]["Threshold"]
>;

export const getMonitorMode = (
  conditionType: MonitorConditionTypes,
  error_value,
  warning_value,
) => {
  const defaultValue = MonitorDefinitions[conditionType].defaultValue();
  if (
    valueIsEmpty(conditionType, warning_value) &&
    valueIsEmpty(conditionType, error_value)
  ) {
    return MonitorModes.NONE;
  }
  if (
    !valueIsEmpty(conditionType, warning_value) &&
    !valueIsEmpty(conditionType, error_value)
  ) {
    return MonitorModes.CUSTOM;
  }
  if (isEqual(error_value, defaultValue)) {
    return MonitorModes.CRITICAL;
  }
  if (isEqual(warning_value, defaultValue)) {
    return MonitorModes.WARNING;
  }
  return MonitorModes.CUSTOM;
};
