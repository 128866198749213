import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  useSubmitDraftSyncMutation,
  useUpdateSyncsMutation,
} from "src/graphql";

export const useClearSyncLevelOverrides = () => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const updateSyncsMutation = useUpdateSyncsMutation();
  const { mutateAsync: submitDraftSync, isLoading: submittingDraftSync } =
    useSubmitDraftSyncMutation();

  const clearSyncLevelOverrides = async (syncIds: string[]) => {
    const approvalsRequired = workspace?.approvals_required;

    try {
      const syncs = await updateSyncsMutation.mutateAsync({
        ids: syncIds,
        object: {
          sync_template_overrides: null,
        },
      });

      if (
        syncs.update_destination_instances?.returning.length !== syncIds.length
      ) {
        throw new Error(
          "One or more of the selected syncs cannot be updated. Ensure you have the required permissions to perform this action.",
        );
      }

      if (approvalsRequired) {
        const syncIds: string[] = (
          syncs.update_destination_instances?.returning ?? []
        ).map(({ id }) => id.toString());

        // Save updated syncs as new drafts without approvers.
        // Approvers will be requested later from the individual sync pages.
        await Promise.all(
          syncIds.map(async (syncId: string) => {
            return submitDraftSync(
              {
                resourceId: syncId,
                approverIds: [],
                operation: DraftOperation.Create,
                draft: {
                  _set: {
                    draft: false,
                  },
                },
              },
              {
                onError: (error) => {
                  Sentry.captureException(error);
                },
              },
            );
          }),
        );
      }

      toast({
        id: "clear-sync-level-overrides",
        title: `Cleared sync level overrides for ${pluralize(
          "sync",
          syncIds.length,
          true,
        )}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "clear-sync-level-overrides",
        title: `Failed to clear sync level overrides for ${pluralize(
          "sync",
          syncIds.length,
          true,
        )}. Please try again.`,
        message: error.message,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return {
    clearSyncLevelOverrides,
    isLoading: updateSyncsMutation.isLoading || submittingDraftSync,
  };
};
