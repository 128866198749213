import { Controller } from "react-hook-form";
import { Column, Row, Select, Text } from "@hightouchio/ui";
import { Link, Outlet } from "src/router";

import { useUser } from "src/contexts/user-context";
import {
  useUpsertUserGroupWorkspacesInWorkspaceMutation,
  useUserGroupsForWorkspaceQuery,
} from "src/graphql";
import { Table } from "src/ui/table";
import { Form, FormActions, useHightouchForm } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import { ActionBar } from "src/components/action-bar";
import { PermissionedLinkButton } from "src/components/permission";

import { isDefaultRole } from "src/pages/organizations/roles/utils";
import {
  getWorkspaceRole,
  PERMISSION_SET_OPTIONS,
} from "src/pages/organizations/roles/role/workspaces/components/workspaces";
import { DestinationsCell } from "src/pages/organizations/roles/role/workspaces/components/destinations-cell";
import { OrgSettingsBanner } from "./org-settings-banner";

export const Groups = () => {
  const { workspace } = useUser();

  const updateMutation = useUpsertUserGroupWorkspacesInWorkspaceMutation();

  const { data: userGroupWorkspaces, error } = useUserGroupsForWorkspaceQuery(
    {
      workspaceId: workspace?.id ?? "",
    },
    {
      enabled: Boolean(workspace?.organization),
      select: (data) =>
        data.user_group_workspaces.map(
          ({ user_group, is_workspace_admin, built_in_permission_set }) => ({
            name: user_group.name,
            builtInPermissionSet: built_in_permission_set,
            userGroupId: user_group.id,
            isEditable: !isDefaultRole(user_group.built_in_role_type),
            role: getWorkspaceRole({
              is_in_workspace: true,
              is_workspace_admin,
              built_in_permission_set,
            }),
          }),
        ),
      suspense: true,
    },
  );

  const form = useHightouchForm({
    onSubmit: async ({ userGroupWorkspaces }) => {
      const objects = userGroupWorkspaces
        .filter((w) => w.role !== "none")
        .map((ugw) => ({
          user_group_id: String(ugw.userGroupId),
          workspace_id: String(workspace?.id),
          is_workspace_admin: ugw.role === "workspace_admin",
          built_in_permission_set: ugw.role === "custom" ? null : ugw.role,
        }));

      await updateMutation.mutateAsync({
        objects,
        workspaceId: workspace?.id ?? "",
        userGroupIdsToKeep: objects.map((ugw) => ugw.user_group_id),
      });
    },
    values: { userGroupWorkspaces: userGroupWorkspaces ?? [] },
  });

  const isBusinessTier = workspace?.organization?.plan?.sku === "business_tier";

  return (
    <Column gap={4}>
      <OrgSettingsBanner />
      <Row alignItems="center" justifyContent="flex-end">
        <PermissionedLinkButton
          organizationPermission={{ resource: "organization" }}
          href="/organization/groups"
          variant="primary"
        >
          Manage organization permissions
        </PermissionedLinkButton>
      </Row>

      <Form form={form}>
        <Table
          data={userGroupWorkspaces ?? []}
          error={Boolean(error)}
          columns={[
            {
              name: "User group",
              cell: ({ name }) => (
                <TextWithTooltip fontWeight="medium">{name}</TextWithTooltip>
              ),
            },
            {
              name: "Role",
              max: "max-content",
              cell: ({ isEditable }, index) => {
                return (
                  <Controller
                    control={form.control}
                    name={`userGroupWorkspaces.${index}.role`}
                    render={({ field }) => (
                      <Select
                        options={PERMISSION_SET_OPTIONS}
                        isDisabled={!isEditable}
                        isOptionDisabled={(option) =>
                          option.value === "custom" && !isBusinessTier
                        }
                        optionDescription={(option) =>
                          option.value === "custom" && !isBusinessTier
                            ? "Upgrade to Business Tier for custom roles"
                            : ""
                        }
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                );
              },
            },
            {
              name: "Access",
              max: "max-content",
              cell: ({ role, userGroupId }) => {
                if (!workspace) return null;

                return (
                  <DestinationsCell
                    workspaceRole={role}
                    workspace={{
                      id: workspace.id,
                      name: workspace.name,
                      role: role,
                      isApprovalsRequired: workspace.approvals_required,
                    }}
                    userGroup={{ id: userGroupId }}
                  />
                );
              },
            },
            {
              name: "",
              cell: ({ role, isEditable, userGroupId }, index) => {
                // We need to compare the current form value to the data we fetched from the api.
                // If they're not equal then we can't open the custom grant drawer until the form is saved.
                const value = form.getValues(`userGroupWorkspaces.${index}`);

                return (
                  <>
                    {value.role === "custom" &&
                      role === "custom" &&
                      isEditable && (
                        <Link
                          href={`${userGroupId}/workspaces/${workspace?.id}`}
                        >
                          Edit custom role...
                        </Link>
                      )}
                    {value.role === "custom" && role !== "custom" && (
                      <Text color="text.secondary">
                        Save your changes before editing this custom role
                      </Text>
                    )}
                  </>
                );
              },
            },
          ]}
          placeholder={{
            title: "No user groups",
            error: "User groups failed to load, please try again.",
            body: "No user groups have been assigned to this workspace.",
          }}
        />
        <ActionBar center={false}>
          <FormActions
            organizationPermission={{
              resource: "workspace",
              id: workspace?.id,
            }}
            confirmation={{
              title: "Confirm changes",
              message:
                "Updating user group roles will affect user permissions in this workspace. Some changes cannot be undone without an organization admin, such as setting a user group role to 'no access' or modifying an admin role for a group you are a part of.",
            }}
          />
        </ActionBar>
      </Form>
      <Outlet />
    </Column>
  );
};
