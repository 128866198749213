import { Column, Row, SectionHeading, Text } from "@hightouchio/ui";

import {
  useCreateResourceNotificationChannelMutation,
  useDeleteResourceChannelMutation,
  useWorkspaceNotificationChannelsQuery,
} from "src/graphql";
import { useNavigate } from "src/router";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { useRecipients } from "src/pages/alerting/utils";
import { AddRecipientButton } from "./add-recipient-button";
import { NoRecipients } from "./recipients";
import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";
import { useMemo } from "react";

export const ResourceRecipients = ({
  resourceId,
  resourceType,
  parentResourceId,
}: {
  resourceId: string;
  resourceType: MonitoredResourceType;
  parentResourceId?: string;
}) => {
  const navigate = useNavigate();
  const { getRecipientsForParentResourceIds, getRecipientsForResourceIds } =
    useRecipients();

  const { data: workspaceChannels } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      suspense: true,
      select: (data) => data.workspace_notification_channels,
    },
  );

  const { mutateAsync: createResourceNotificationChannel } =
    useCreateResourceNotificationChannelMutation();
  const { mutateAsync: deleteResourceNotificationChannel } =
    useDeleteResourceChannelMutation();

  const parentRecipients = parentResourceId
    ? getRecipientsForParentResourceIds([parentResourceId])
    : [];

  const onCreate = () => {
    navigate("/alerting/recipients/new");
  };

  const onAdd = async (channelId: string) => {
    await createResourceNotificationChannel({
      resourceId,
      resourceType,
      channelId,
    });
  };

  const onRemove = async (resourceChannelId: string) => {
    await deleteResourceNotificationChannel({ resourceChannelId });
  };

  const resourceRecipients = getRecipientsForResourceIds([resourceId]);

  const recipients = [...parentRecipients, ...resourceRecipients];

  // Workspace channels contains all possible notification channels
  // We need to filter out the ones that are already subscribed to by this resource
  const channels = useMemo(
    () =>
      (workspaceChannels ?? []).filter(
        (c) => !recipients.map((r) => r.channel.id).includes(c.id),
      ),
    [workspaceChannels, recipients],
  );

  return (
    <Column gap={2}>
      <Column>
        <SectionHeading>Recipients</SectionHeading>
        <Text color="text.secondary">
          Send all alerts to the following recipients:
        </Text>
      </Column>
      {recipients.length === 0 ? (
        <NoRecipients onCreate={onCreate} onAdd={onAdd} channels={channels} />
      ) : (
        <>
          <Row gap={2} flexWrap="wrap">
            {recipients.map((r) => (
              <SubscribedResourceItem
                key={r.id}
                channel={r.channel}
                onRemove={
                  r.__typename === "resource_notification_channels"
                    ? () => onRemove(r.id)
                    : undefined
                }
              />
            ))}
            <AddRecipientButton
              isIcon
              onAdd={onAdd}
              onCreate={onCreate}
              channels={channels}
            />
          </Row>
        </>
      )}
    </Column>
  );
};
