import { FC } from "react";

import { Box } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import noop from "lodash/noop";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
  Legend as RechartsLegend,
  XAxisProps,
  YAxisProps,
} from "recharts";

import { GraphScale } from "./constants";
import CustomTooltip from "./tooltip";
import { GraphSeries } from "./types";
import { valueFormatter } from "./utils";
import CustomLegend from "../shared/legend";

export const MAX_LINES = 30;

const sharedStyles = {
  stroke: "#9AA6B2",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};

const tickStyles = {
  fontSize: "12px",
  color: "var(--chakra-colors-text-secondary)",
  fontWeight: 400,
};

const xAxisProps: XAxisProps = {
  ...sharedStyles,
  tick: tickStyles,
};

const yAxisProps: YAxisProps = {
  ...sharedStyles,
  tick: tickStyles,
};

type AnalyticsGraphProps = {
  data: GraphSeries[];
  hoveredLine?: string;
  onHoverLine?: (key: string | undefined) => void;
  height?: string;
  scale?: GraphScale;
};

export const AnalyticsGraph: FC<AnalyticsGraphProps> = ({
  data,
  height = "400px",
  hoveredLine,
  scale = GraphScale.Linear,
  onHoverLine = noop,
}) => {
  return (
    <Box position="relative" width="100%" height={height}>
      <Box position="absolute" inset={0}>
        <ResponsiveContainer>
          <LineChart
            margin={{
              top: 30,
              right: 60,
              left: 10,
            }}
            onMouseLeave={() => onHoverLine(undefined)}
          >
            <CartesianGrid vertical={false} stroke="#E5E9ED" />
            <XAxis
              axisLine={{ stroke: "#E5E9ED" }}
              dataKey="calculatedAt"
              allowDuplicatedCategory={false}
              minTickGap={50}
              tickFormatter={(timestamp: number | "auto") => {
                if (timestamp === "auto") return timestamp;

                return formatDate(timestamp, "LLL d");
              }}
              tickLine={false}
              {...xAxisProps}
            />
            <YAxis
              axisLine={false}
              dataKey="metricValue"
              tickFormatter={(value) => valueFormatter(value, scale)}
              tickLine={false}
              {...yAxisProps}
            />
            <RechartsTooltip
              active
              content={
                <CustomTooltip
                  hoveredLine={hoveredLine}
                  showPercentSign={scale === GraphScale.Percent}
                />
              }
              cursor={{ strokeWidth: 2, stroke: "#252D36" }}
            />
            {data.map(({ key, name, data, color }) => (
              <Line
                key={key}
                data={data}
                name={name}
                dataKey="metricValue"
                dot={false}
                activeDot={{
                  r: 6,
                  onMouseEnter: () => onHoverLine(key),
                }}
                strokeWidth={2}
                stroke={color}
                onMouseEnter={() => onHoverLine(key)}
                opacity={hoveredLine ? (hoveredLine === key ? 1 : 0.2) : 1}
              />
            ))}
            <RechartsLegend
              verticalAlign="bottom"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore -- overload type for payload so we can pass more information to Legend
              payload={data}
              content={({ payload }) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore -- overload type for payload so we can pass more information to Legend
                <CustomLegend payload={payload} onHover={onHoverLine} />
              )}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};
