import {
  MonitorConditionTypes,
  MonitoredResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import {
  SectionHeading,
  Column,
  Row,
  Text,
  Box,
  useToast,
  Switch,
} from "@hightouchio/ui";
import { Card } from "src/components/card";
import { ResourceQuickSubscribeMenu } from "src/components/notification-channels/resource-quick-subscribe-menu";
import { SubscribedResourceItem } from "src/components/notification-channels/subscribed-resource-item";
import { useMemo } from "react";
import {
  useAllResourceSubscriptionsQuery,
  useWorkspaceNotificationChannelsQuery,
  useCreateResourceNotificationChannelMutation,
  useDeleteResourceChannelMutation,
} from "src/graphql";
import { serializeEventSourceResourceId } from "@hightouch/lib/resource-monitoring/composite-ids";
import { Controller } from "react-hook-form";
import { EventSourceVolumeField } from "src/components/resource-alert-triggers/monitor-condition-forms/event-source-volume";

export const ConfigureAlertsForEventType = ({
  eventName,
  eventSourceId,
  eventType,
}: {
  eventType: string;
  eventSourceId: string;
  eventName: string | null;
}) => {
  const resourceType = MonitoredResourceType.EventSource;
  const resourceId = serializeEventSourceResourceId({
    eventSourceId,
    eventName,
    eventType,
  });

  const { data: subscriptions } = useAllResourceSubscriptionsQuery(
    {
      resourceId: resourceId?.toString(),
      resourceType,
    },
    { select: (data) => data.resource_notification_channels },
  );

  const enabledManualSubscriptions =
    subscriptions?.filter((sub) => !sub.disabled) ?? [];

  const { data: workspaceChannelsData } = useWorkspaceNotificationChannelsQuery(
    undefined,
    {
      select: (data) => data.workspace_notification_channels,
    },
  );

  const availableChannels = useMemo(
    () =>
      workspaceChannelsData?.filter(
        (wc) =>
          !subscriptions?.map((merged) => merged.channel.id).includes(wc.id),
      ),
    [workspaceChannelsData, subscriptions],
  );

  const { toast } = useToast();

  const { mutateAsync: subscribeResource } =
    useCreateResourceNotificationChannelMutation();
  const { mutateAsync: unsubscribeResource } =
    useDeleteResourceChannelMutation();

  return (
    <Card gap={4}>
      <SectionHeading>Configure</SectionHeading>
      <Column>
        <Text fontWeight="medium">Triggers</Text>
        <Text color="text.secondary">Send an alert when:</Text>
        <Box
          display="grid"
          gridTemplateColumns="3fr 8fr"
          borderBottom="1px"
          borderBottomColor="base.border"
          mb={4}
        >
          <Box
            p={4}
            pl={4}
            textTransform="uppercase"
            fontWeight="semibold"
            color="text.secondary"
            borderBottom="1px"
            borderColor="base.border"
          >
            Name
          </Box>
          <Box
            p={4}
            textTransform="uppercase"
            fontWeight="semibold"
            color="text.secondary"
            borderBottom="1px"
            borderColor="base.border"
          >
            Trigger
          </Box>
          {[
            {
              sign: "gt",
              description: "More than",
              title: "High volume",
            } as const,
            {
              sign: "lt",
              description: "Fewer than",
              title: "Low volume",
            } as const,
          ].map((comparator) => {
            const fieldName =
              `${MonitorConditionTypes.EventSourceVolume}.${comparator.sign}` as const;
            return (
              <>
                <Column
                  borderTop="1px"
                  borderTopColor="base.border"
                  gap={2}
                  p={4}
                  justify="space-between"
                >
                  <Row align="center" gap={4}>
                    <Controller
                      name={`${fieldName}.enabled`}
                      render={({ field }) => (
                        <Switch
                          size="sm"
                          isChecked={field.value}
                          onChange={(value) => {
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                    {comparator.title}
                  </Row>
                </Column>
                <Column
                  gap={2}
                  p={4}
                  borderTop="1px"
                  borderTopColor="base.border"
                >
                  <EventSourceVolumeField name={fieldName} />
                </Column>
              </>
            );
          })}
        </Box>
      </Column>
      <Column gap={2}>
        <Column>
          <Text fontWeight="medium">Recipients</Text>
          <Text color="text.secondary">Alert the following recipients:</Text>
        </Column>
        <Row gap={2} flexWrap="wrap">
          {enabledManualSubscriptions?.map((sub) => (
            <SubscribedResourceItem
              key={sub.id}
              channel={sub.channel}
              onRemove={async () => {
                await unsubscribeResource({
                  resourceChannelId: sub.id,
                });
                toast({
                  id: "resource-notifications",
                  title: "Recipient unassigned",
                  variant: "success",
                });
              }}
            />
          ))}
          <ResourceQuickSubscribeMenu
            resourceType={MonitoredResourceType.Sync}
            fullSize={!enabledManualSubscriptions?.length}
            resourceId={resourceId}
            onSelectChannel={async ({ channelId }) => {
              await subscribeResource({
                channelId,
                resourceId,
                resourceType,
              });
              toast({
                id: "resource-notifications",
                title: "Successfully subscribed",
                variant: "success",
              });
            }}
            availableDestinationChannels={availableChannels ?? []}
          />
        </Row>
      </Column>
    </Card>
  );
};
