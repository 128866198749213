import { FC, useMemo } from "react";

import {
  Alert,
  Box,
  CloseIcon,
  Column,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Heading,
  IconButton,
  Row,
  Text,
} from "@hightouchio/ui";
import { LinkButton } from "src/router";
import { yupResolver } from "@hookform/resolvers/yup";
import { Navigate, useNavigate, useSearchParams } from "src/router";

import { Form, SaveButton, useHightouchForm } from "src/components/form";
import { JOURNEY_UPDATE_PERMISSION } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import { JourneyGraph, NodeDetailFormProps } from "src/pages/journeys/types";
import { JourneySyncConfigForm } from "src/pages/syncs/create/journey-sync-config-form";
import { SyncConfig } from "src/types/journeys";

import { SyncFormSchema } from "./validation-schemas";
import { useFormContext } from "react-hook-form";
import { hasWaitNodeDownstream } from "./utils";
import { useJourneySyncQuery } from "src/graphql";

export const SyncConfigForm: FC<NodeDetailFormProps<SyncConfig>> = ({
  id, // id techincally lives in data too, redundant to have both :/
  data,
  onClose,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { isEditMode, onUpdateSyncConfig } = useGraphContext();

  const syncIdString = searchParams.get("sync");
  const syncId = syncIdString ? Number(syncIdString) : null;

  const syncConfig = data.sync_configs?.find(
    ({ destination_instance_id }) => destination_instance_id === syncId,
  );

  const parentForm = useFormContext<JourneyGraph>();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - Circular reference problem with Condition types
  const nodes = parentForm.watch("nodes");
  const currentNode = nodes.find((node) => node.id === id);
  const edges = parentForm.watch("edges");

  const hasDownstream = useMemo(() => {
    return currentNode && hasWaitNodeDownstream(currentNode, nodes, edges);
  }, [nodes, edges]);

  const journeySyncQuery = useJourneySyncQuery(
    {
      id: syncIdString ?? "",
    },
    {
      enabled: Boolean(syncIdString),
      select: (data) => data.syncs?.[0],
    },
  );

  const destination = journeySyncQuery.data?.destination;
  const destinationName = destination?.name;

  const form = useHightouchForm({
    onSubmit: (journeySyncConfig) => {
      if (syncId) {
        onUpdateSyncConfig(id, syncId, journeySyncConfig);
        navigate(".");
      }

      return Promise.resolve();
    },
    success: "Tile was saved",
    values: syncConfig,
    resolver: yupResolver(SyncFormSchema),
  });

  if (!syncConfig) {
    return <Navigate to="." />;
  }

  return (
    <Form form={form}>
      <DrawerHeader>
        <Row align="center" justify="space-between" flex={1} minWidth={0}>
          <Heading size="lg">{data.name}</Heading>
          <IconButton
            aria-label="Close drawer."
            icon={CloseIcon}
            onClick={onClose}
          />
        </Row>
      </DrawerHeader>

      <DrawerBody>
        <Column minHeight={0} flex={1} gap={6} pb={4}>
          <Row align="center" gap={2}>
            <Box
              as={LinkButton}
              href={data.id}
              px={2}
              mx={-2}
              fontWeight="normal"
              variant="tertiary"
            >
              <Text color="text.secondary">Sync</Text>
            </Box>
            <Text color="text.secondary" size="lg">
              /
            </Text>
            <Text color="text.secondary" fontWeight="medium">
              {destinationName ?? "Destination"}
            </Text>
          </Row>

          <Row as={Text} gap={1} fontWeight="medium" size="lg">
            Settings for sync to
            <Row gap={1}>
              {destination?.definition.icon && (
                <img
                  src={destination.definition.icon}
                  alt={destination?.definition.name}
                  height="24px"
                  width="24px"
                />
              )}
              {destinationName}
            </Row>
          </Row>

          <JourneySyncConfigForm
            isDisabled={!isEditMode}
            isJourneyExitWarningEligible={!hasDownstream}
          />

          <Alert
            type="subtle"
            variant="inline"
            title="Further sync configuration is managed on the sync details page"
            message={null}
            actions={
              <LinkButton
                target="_blank"
                rel="noreferrer noopener"
                href={`/syncs/${syncConfig.destination_instance_id}`}
              >
                Go to sync details
              </LinkButton>
            }
          />
        </Column>
      </DrawerBody>

      {isEditMode && (
        <DrawerFooter>
          <SaveButton permission={JOURNEY_UPDATE_PERMISSION}>Update</SaveButton>
        </DrawerFooter>
      )}
    </Form>
  );
};
