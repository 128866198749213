import { ReactNode, forwardRef } from "react";

import { Text, Box, Tooltip } from "@hightouchio/ui";

import { RouterLink } from "src/router";

import { useActiveLink } from "./utils";
import { withPermission } from "src/components/permission";

const bgGradient = {
  hover:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 50%, rgba(255, 255, 255, 0.02) 100%);",
  active:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.05) 50%, rgba(255, 255, 255, 0.03) 100%)",
  activeHover:
    "linear-gradient(90deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.07) 50%, rgba(255, 255, 255, 0.05) 100%)",
};

const boxShadow = "0px 0px 4px 0px rgba(0, 0, 0, 0.08)";

export type Props = {
  isSub?: boolean;
  isNested?: boolean;
  isActive?: boolean;
  isActiveSection?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
  href?: string;
  newTab?: boolean;
  icon?: any;
  label?: string;
  children?: ReactNode;
  onClick?: () => void;
};

export const NavItem = forwardRef<HTMLButtonElement, Props>(
  (
    {
      isSub,
      isNested,
      isActive,
      isActiveSection,
      isDisabled,
      href,
      newTab,
      icon: Icon,
      label,
      children,
      onClick,
    },
    ref,
  ) => {
    const isLinkActive = useActiveLink({ href, isSub });
    const isButton = onClick && !href;
    // Section headers should never have active styles
    const active =
      typeof isActiveSection !== "undefined"
        ? false
        : Boolean(typeof isActive === "undefined" ? isLinkActive : isActive);
    const content = (
      <Box
        ref={ref}
        aria-label={isButton ? label : undefined} // Aria-label is required for interactive elements
        as={isButton ? "button" : "div"}
        display="flex"
        alignItems="center"
        bg={active ? bgGradient.active : "transparent"}
        boxShadow={active ? boxShadow : "none"}
        borderLeftRadius={isNested ? 0 : "4px"}
        borderRightRadius="4px"
        color={
          isActiveSection || active ? "white !important" : "gray.300 !important"
        }
        gap={2}
        height="32px"
        justifyContent={["center", "center", "center", "flex-start"]}
        overflow="hidden"
        position="relative"
        pl={[0, 0, 0, isNested ? 6 : 2]}
        width="100%"
        _hover={{
          bg: active ? bgGradient.activeHover : bgGradient.hover,
          boxShadow: boxShadow,
        }}
        _focusVisible={{
          outline: "none",
          border: "none",
          boxShadow: "outline",
        }}
        _focus={{
          outline: "none",
        }}
        onClick={onClick}
      >
        {Icon && (
          <Icon color={active || isActiveSection ? "#40E398" : "#638989"} />
        )}
        {label ? (
          <Box
            as={Text}
            color="inherit"
            display={["none", "none", "none", "flex"]}
            fontFamily="'Sharp Sans Display No 1'"
            fontSize="13px"
            fontWeight="semibold"
            whiteSpace="nowrap"
          >
            {label}
          </Box>
        ) : (
          children
        )}
      </Box>
    );

    const container = isDisabled ? (
      <Tooltip message="You do not have permission to view this page">
        <Box
          cursor="not-allowed"
          // Do not include hover state on disabled nav items
          sx={{ div: { _hover: { bg: "none" } } }}
        >
          {content}
        </Box>
      </Tooltip>
    ) : href ? (
      <Box
        as={RouterLink}
        to={href}
        aria-label={label}
        {...(newTab ? { target: "_blank", rel: "noreferrer" } : {})}
      >
        {content}
      </Box>
    ) : (
      content
    );

    if (isNested) {
      return (
        <Box
          borderLeft="2px"
          borderColor="#638989"
          ml="5px" // 1 + 1px offset
          sx={{
            a: { borderLeftRadius: "0" },
          }}
        >
          {container}
        </Box>
      );
    }

    return container;
  },
);

NavItem.displayName = "NavItem";

export const PermissionedNavItem = withPermission(NavItem);
