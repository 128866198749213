import { FC } from "react";

import { MonitoredResourceType } from "@hightouch/lib/resource-monitoring/types";

import { ResourceTriggers } from "src/components/resource-alert-triggers/resource-triggers";
import { useOutletContext } from "src/router";
import { OutletContext } from ".";

export const EventSyncAlerting: FC = () => {
  const { sync } = useOutletContext<OutletContext>();

  return (
    <ResourceTriggers
      resourceType={MonitoredResourceType.EventSync}
      resourceId={sync.id.toString()}
    />
  );
};
