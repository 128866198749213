export const audienceSplitName = (
  audienceName: string,
  splitName: string | undefined,
) => {
  if (splitName) {
    return `${audienceName} / ${splitName}`;
  }

  return audienceName;
};
