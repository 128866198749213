import { useFlags } from "launchdarkly-react-client-sdk";
import { FC, useMemo } from "react";

import {
  Alert,
  Box,
  Button,
  ChakraAccordion,
  ChakraAccordionButton,
  ChakraAccordionItem,
  ChakraAccordionPanel,
  ChevronRightIcon,
  Column,
  MergeIcon,
  PlayIcon,
  Row,
  Text,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { useParams } from "src/router";
import { getOutgoers } from "reactflow";

import { IconBox } from "src/components/icon-box";
import { JOURNEY_NODES_WITH_BRANCH_NODES } from "src/pages/journeys/constants";
import { useGraphContext } from "src/pages/journeys/graph";
import {
  NODE_LIBRARY_OPTIONS,
  SPLIT_NODE_LIBRARY_OPTIONS,
} from "src/pages/journeys/node-library";
import { JourneyGraph } from "src/pages/journeys/types";
import { Table } from "src/ui/table";
import { abbreviateNumber } from "src/utils/numbers";

import { Card } from "./card";

type PerformanceCardProps = {
  numberOfUsersInJourney?: number;
};

export const PerformanceCard: FC<PerformanceCardProps> = ({
  numberOfUsersInJourney = 0,
}) => {
  const { splitNodeEnabled } = useFlags();
  const experimentalNodes = splitNodeEnabled ? SPLIT_NODE_LIBRARY_OPTIONS : [];
  const enabledNodes = [...NODE_LIBRARY_OPTIONS, ...experimentalNodes];

  const { node_id } = useParams<{ node_id?: string }>();

  const { onOpenDrawer } = useGraphContext();

  const form = useFormContext<JourneyGraph>();

  const nodes = form.watch("nodes");
  const edges = form.watch("edges");

  const selectedNode = useMemo(() => {
    return nodes.find(({ id }) => id === node_id);
  }, [node_id, nodes]);

  const branchNodes = useMemo(() => {
    if (
      selectedNode &&
      JOURNEY_NODES_WITH_BRANCH_NODES.includes(selectedNode.data.config.type)
    ) {
      return getOutgoers(selectedNode, nodes, edges);
    }

    return [];
  }, [selectedNode, nodes, edges]);

  return (
    <Card overflowY="auto">
      <ChakraAccordion allowToggle defaultIndex={0}>
        <ChakraAccordionItem border="none">
          {({ isExpanded }) => (
            <>
              <Row align="center" justify="space-between">
                <Text fontWeight="medium">Performance</Text>
                <ChakraAccordionButton
                  cursor="pointer"
                  outline="none !important"
                  borderRadius="md"
                  display="inline-flex"
                  width="min-content"
                  p={1}
                >
                  <Box
                    as={ChevronRightIcon}
                    fontSize="18px"
                    transform={isExpanded ? "rotate(90deg)" : ""}
                    transition="transform 150ms ease-in-out"
                  />
                </ChakraAccordionButton>
              </Row>
              <ChakraAccordionPanel p={0} pt={6}>
                <Column gap={4}>
                  <Table
                    rowHeight="24px"
                    data={[
                      // TODO(samuel): add uniques and other rows when backend is ready
                      {
                        label: "In progress",
                        total: numberOfUsersInJourney,
                      },
                    ]}
                    columns={[
                      {
                        headerSx: {
                          ...headerStyles,
                          pl: "0 !important",
                        },
                        cellSx: { ...cellStyles, pl: "0 !important" },
                        min: "180px",
                        header: () => (
                          <Row as={Text} gap={2} fontWeight="semibold">
                            <Box
                              as={MergeIcon}
                              fontSize="20px"
                              color="text.secondary"
                            />
                            Entire journey
                          </Row>
                        ),
                        cell: ({ label }, index) => (
                          <Text fontWeight={index === 0 ? "medium" : "normal"}>
                            {label}
                          </Text>
                        ),
                      },
                      {
                        headerSx: {
                          ...headerStyles,
                          ...rightAlignedHeaderStyles,
                        },
                        cellSx: {
                          ...cellStyles,
                          ...rightAlignedCellStyles,
                        },
                        header: () => <Text color="text.secondary">Total</Text>,
                        cell: ({ total }, index) => (
                          <Text fontWeight={index === 0 ? "medium" : "normal"}>
                            {total === null ? "--" : abbreviateNumber(total)}
                          </Text>
                        ),
                      },
                    ]}
                  />
                  {selectedNode ? (
                    <>
                      <Table
                        rowHeight="24px"
                        data={[
                          // TODO(samuel): add uniques and other rows when backend is ready
                          {
                            label: "Currently in tile",
                            total: Number(selectedNode.data.number_users),
                          },
                          ...branchNodes.map((node) => ({
                            label: `-> ${node.data.name}`,
                            total: Number(node.data.number_users),
                          })),
                        ]}
                        columns={[
                          {
                            headerSx: {
                              ...headerStyles,
                              pl: "0 !important",
                            },
                            cellSx: { ...cellStyles, pl: "0 !important" },
                            min: "180px",
                            header: () => {
                              const tileMetadata = enabledNodes.find(
                                ({ type }) =>
                                  type === selectedNode?.data.config.type,
                              );

                              return (
                                <Row
                                  as={Text}
                                  gap={2}
                                  color={`${tileMetadata?.color ?? "gray"}.600`}
                                  fontWeight="semibold"
                                >
                                  <IconBox
                                    bg={`${tileMetadata?.color ?? "gray"}.400`}
                                    boxSize="20px"
                                    icon={tileMetadata?.icon ?? <PlayIcon />}
                                    iconSize="14px"
                                  />
                                  {selectedNode?.data.name}
                                </Row>
                              );
                            },
                            cell: ({ label }, index) => (
                              <Text
                                fontWeight={index === 0 ? "medium" : "normal"}
                              >
                                {label}
                              </Text>
                            ),
                          },
                          {
                            headerSx: {
                              ...headerStyles,
                              ...rightAlignedHeaderStyles,
                            },
                            cellSx: {
                              ...cellStyles,
                              ...rightAlignedCellStyles,
                            },
                            header: () => (
                              <Text color="text.secondary">Total</Text>
                            ),
                            cell: ({ total }, index) => (
                              <Text
                                fontWeight={index === 0 ? "medium" : "normal"}
                              >
                                {total === null
                                  ? "--"
                                  : abbreviateNumber(total)}
                              </Text>
                            ),
                          },
                        ]}
                      />
                      <Box>
                        <Button onClick={onOpenDrawer}>
                          View configuration
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <Alert
                      title="Select a tile to view its performance"
                      type="subtle"
                      variant="inline"
                    />
                  )}
                </Column>
              </ChakraAccordionPanel>
            </>
          )}
        </ChakraAccordionItem>
      </ChakraAccordion>
    </Card>
  );
};

const headerStyles = {
  mb: 1,
  textTransform: "none",
  borderBottom: "1px solid",
};

const rightAlignedHeaderStyles = {
  display: "flex",
  justifyContent: "flex-end",
  paddingRight: "4 !important",
};

const cellStyles = {
  borderBottom: "none",
};

const rightAlignedCellStyles = {
  display: "flex",
  justifyContent: "end",
};
