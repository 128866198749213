import { Box, Row, Text } from "@hightouchio/ui";
import { FC } from "react";

export const Warning = ({
  children,
  prefix = true,
}: {
  children: React.ReactNode;
  prefix?: boolean;
}) => {
  if (!prefix) {
    return <>{children}</>;
  }
  return (
    <Row
      align="center"
      borderLeft="2px"
      borderColor="warning.border"
      gap={2}
      pl={2}
      whiteSpace="nowrap"
    >
      <Text color="warning.border">WARNING</Text>
      {children}
    </Row>
  );
};

export const Critical = ({
  children,
  prefix = true,
}: {
  children: React.ReactNode;
  prefix?: boolean;
}) => {
  if (!prefix) {
    return <>{children}</>;
  }
  return (
    <Row
      align="center"
      borderLeft="2px"
      borderColor="danger.base"
      gap={2}
      pl={2}
      whiteSpace="nowrap"
    >
      <Text color="danger.base">CRITICAL</Text>
      {children}
    </Row>
  );
};

export const Value = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      display="inline"
      bg="gray.100"
      px={1}
      py={0.5}
      borderRadius="md"
      color="text.primary"
    >
      {children}
    </Box>
  );
};

export type ConditionSummary<Value> = FC<{
  prefix?: boolean;
  condition: {
    error_value: Value;
    warning_value: Value;
  };
}>;
