import { forwardRef, ReactNode, useState } from "react";

import {
  Button,
  ButtonGroup,
  ButtonProps,
  ConfirmationDialog,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";

import { withPermission } from "src/components/permission";
import { ResourceToPermission as V1ResourceToPermission } from "src/components/permission/types";
import { V2ResourceToPermission } from "src/components/permission/types-v2";
import { ResourcePermissionInput } from "src/components/permission/use-resource-permission";
import { OrganizationPermissionInput } from "src/components/permission/use-organization-permissions";

import { useHightouchFormContext } from "./hooks";

type Props = {
  children?: ReactNode;
  enablePristineSubmit?: boolean;
  confirmation?: {
    message: ReactNode;
    title: string;
  };
  tooltip?: string | boolean;
  /**
   * Optional click event handler to run before submitting the form.
   */
  onClick?: () => void;
} & Omit<ButtonProps, "children" | "onClick" | "imageUrl">;

const UnpermissionedSubmitButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & Props
>(
  (
    {
      children,
      enablePristineSubmit = false,
      size = "lg",
      confirmation,
      onClick,
      isDisabled,
      ...props
    },
    ref,
  ) => {
    const {
      formState: { isSubmitting, isDirty },
    } = useFormContext();
    const { submit } = useHightouchFormContext();

    const content = children ?? "Save changes";

    const [confirming, setConfirming] = useState(false);

    const shouldDisable = isSubmitting || (!enablePristineSubmit && !isDirty);

    return (
      <>
        <Button
          ref={ref}
          variant="primary"
          isDisabled={shouldDisable ? true : isDisabled}
          isLoading={isSubmitting}
          size={size}
          onClick={async () => {
            onClick?.();
            if (confirmation) {
              setConfirming(true);
            } else {
              await submit();
            }
          }}
          {...props}
        >
          {content}
        </Button>
        {confirmation && (
          <ConfirmationDialog
            isOpen={confirming}
            onClose={() => setConfirming(false)}
            onConfirm={async () => {
              await submit();
              setConfirming(false);
            }}
            variant="warning"
            confirmButtonText="Confirm"
            title={confirmation.title}
          >
            {confirmation.message}
          </ConfirmationDialog>
        )}
      </>
    );
  },
);

UnpermissionedSubmitButton.displayName = "SubmitButton";

export const SubmitButton = withPermission(UnpermissionedSubmitButton);

export const SaveButton = <
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>(
  props: Props & {
    permission?: ResourcePermissionInput<V2Resource, V1Resource>;
    organizationPermission?: OrganizationPermissionInput;
  },
) => {
  return (
    <SubmitButton {...props} variant="primary">
      {props.children ?? "Save changes"}
    </SubmitButton>
  );
};

export const DiscardButton = ({
  children,
  size = "lg",
  onClick,
  isDisabled,
  ...props
}: Props) => {
  const {
    formState: { isDirty, isSubmitting },
    reset,
  } = useFormContext();

  const content = children ?? "Discard changes";

  const shouldDisable = isSubmitting || !isDirty;

  return (
    <Button
      isDisabled={shouldDisable ? true : isDisabled}
      onClick={() => {
        onClick?.();
        reset();
      }}
      size={size}
      {...props}
    >
      {content}
    </Button>
  );
};

export function FormActions<
  V2Resource extends V2ResourceToPermission,
  V1Resource extends V1ResourceToPermission,
>({
  children,
  permission,
  organizationPermission,
  confirmation,
  tooltip,
  ...props
}: Readonly<
  Props & {
    permission?: ResourcePermissionInput<V2Resource, V1Resource>;
    organizationPermission?: OrganizationPermissionInput;
  }
>) {
  return (
    <ButtonGroup>
      <SaveButton
        confirmation={confirmation}
        organizationPermission={organizationPermission}
        permission={permission}
        tooltip={tooltip}
        {...props}
      />
      <DiscardButton {...props} />
      {children}
    </ButtonGroup>
  );
}
