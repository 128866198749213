import { useState } from "react";

import {
  Alert,
  Box,
  CloseIcon,
  Column,
  ConfirmationDialog,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Paragraph,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import countBy from "lodash/countBy";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import pluralize from "pluralize";
import { DestinationForm } from "src/components/destinations/sync-form";
import { Warning } from "src/components/warning";
import { useOutletContext } from "src/router";

import { SyncTemplateOutletContext } from "./types";
import { useClearSyncLevelOverrides } from "./use-clear-sync-level-overrides";

export const SyncTemplateConfiguration = () => {
  const { enableSyncTemplateOverrides } = useFlags();
  const { dependentSyncs, syncTemplate, onUpdateConfig } =
    useOutletContext<SyncTemplateOutletContext>();

  const [confirmClearSyncLevelOverrides, setConfirmClearSyncLevelOverrides] =
    useState(false);

  const { clearSyncLevelOverrides } = useClearSyncLevelOverrides();

  const destination = syncTemplate.destination;
  const source = syncTemplate.segment.connection;

  const syncOverrides: Record<string, any>[] = dependentSyncs
    .map(({ sync_template_overrides }) =>
      omit(sync_template_overrides, ["configVersion"]),
    )
    .filter((overrides) => !isEmpty(overrides));

  const numberOfRelatedSyncsWithOverrides = countBy(
    syncOverrides.flatMap((config) => Object.keys(config)),
  );

  if (!source) {
    captureException(`Source not found for sync template ${syncTemplate.id}`);
    return (
      <Warning title="Source not found" subtitle="It may have been deleted." />
    );
  }

  return (
    <Column gap={6} pb={10}>
      {enableSyncTemplateOverrides && syncOverrides.length > 0 && (
        <Box mx={-6} mt={-6}>
          <Alert
            justify="center"
            title={`This template has sync-level overrides across ${pluralize(
              "sync",
              dependentSyncs.length,
              true,
            )}`}
            type="info"
            variant="banner"
            actions={
              <Menu>
                <MenuButton>Actions</MenuButton>
                <MenuList>
                  <MenuItem
                    icon={CloseIcon}
                    variant="danger"
                    onClick={() => setConfirmClearSyncLevelOverrides(true)}
                  >
                    Clear all sync level overrides
                  </MenuItem>
                </MenuList>
              </Menu>
            }
          />
        </Box>
      )}
      <DestinationForm
        isSyncForm={false}
        allowOverrides={enableSyncTemplateOverrides}
        testPermission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        permission={{
          v1: { resource: "sync_template", grant: "update" },
          v2: {
            resource: "model",
            grant: "can_update",
            id: syncTemplate.segment.id,
          },
        }}
        destination={destination}
        destinationDefinition={destination?.definition}
        model={syncTemplate.segment}
        slug={destination?.definition?.type}
        sourceDefinition={source.definition}
        syncConfig={syncTemplate.config} // sync config to populate the form
        syncTemplate={syncTemplate}
        numberOfRelatedSyncsWithOverrides={numberOfRelatedSyncsWithOverrides}
        onSubmit={async (config) => {
          onUpdateConfig(config);
        }}
      />
      <ConfirmationDialog
        isOpen={confirmClearSyncLevelOverrides}
        title="Clear all sync-level overrides"
        onConfirm={() =>
          clearSyncLevelOverrides(dependentSyncs.map(({ id }) => id))
        }
        onClose={() => setConfirmClearSyncLevelOverrides(false)}
        confirmButtonText={`Clear overrides for ${pluralize(
          "sync",
          dependentSyncs.length,
          true,
        )}`}
        variant="danger"
      >
        <Paragraph>
          Clearing sync-level overrides could change the behavior of the syncs.
          After clearing overrides, all syncs using this template will match the
          template’s exact configuration. Are you sure you want to clear all
          sync-level overrides?
        </Paragraph>
      </ConfirmationDialog>
    </Column>
  );
};
