import { useMemo, useState } from "react";

import {
  Menu,
  Button,
  MenuButton,
  MenuDivider,
  MenuIconButton,
  MenuItem,
  MenuList,
  PlusIcon,
  Row,
  Spinner,
  useToast,
  Column,
} from "@hightouchio/ui";
import { captureException } from "@sentry/react";
import { ChannelType } from "@hightouch/lib/resource-monitoring/types";

import { ChannelIcon } from "src/pages/alerting/recipients/components/channel-icon";
import {
  Channel,
  channelName,
} from "src/components/notification-channels/channel-definitions";

export const AddRecipientButton = ({
  onAdd,
  onCreate,
  channels,
  isIcon = false,
}: {
  onAdd: (channelId: string) => Promise<unknown>;
  onCreate: () => void;
  channels: Array<Channel>;
  isIcon?: boolean;
}) => {
  const { toast } = useToast();

  const AddMenuItem = ({ channel }: { channel: Channel }) => {
    const [isLoading, setIsLoading] = useState(false);
    const addRecipient = async () => {
      setIsLoading(true);
      try {
        await onAdd(channel.id);
        toast({
          id: "add-recipient",
          title: "Successfully added recipient",
          variant: "success",
        });
      } catch (e) {
        captureException(e);
        toast({
          id: "add-recipient-error",
          title: "Error adding recipient",
          variant: "error",
        });
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <MenuItem
        onClick={(event) => {
          event.stopPropagation();
          addRecipient();
        }}
      >
        <Row align="center" gap={4}>
          {isLoading ? (
            <Spinner size="sm" />
          ) : (
            <ChannelIcon channelType={channel.channel_type as ChannelType} />
          )}
          {channelName(channel)}
        </Row>
      </MenuItem>
    );
  };

  const sortedChannels = useMemo(
    () =>
      channels.sort(
        (a, b) =>
          a.channel_type.localeCompare(b.channel_type) ||
          channelName(a).localeCompare(channelName(b)),
      ),
    [channels],
  );

  const list = (
    <MenuList>
      <Column maxH="400px" overflow="auto">
        {sortedChannels.map((channel) => (
          <AddMenuItem key={channel.id} channel={channel} />
        ))}
      </Column>
      <MenuDivider />
      <MenuItem onClick={onCreate} icon={PlusIcon}>
        Create new recipient
      </MenuItem>
    </MenuList>
  );

  if (isIcon) {
    return (
      <Menu closeOnSelect={false}>
        <MenuIconButton
          aria-label="Add recipients"
          icon={PlusIcon}
          variant="secondary"
          size="sm"
        />
        {list}
      </Menu>
    );
  }

  if (!channels.length) {
    return (
      <Button onClick={onCreate} variant="primary">
        Add new recipient
      </Button>
    );
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton variant="primary">Add recipients</MenuButton>
      {list}
    </Menu>
  );
};
