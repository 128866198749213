import * as Yup from "yup";
import {
  ColumnOrConstant,
  Input,
  Label,
  MultiField,
  Section,
  showIfKeyMatchesPattern,
} from "@hightouch/formkit";

const validationLabel = (key) => {
  const label = Label({
    message:
      "Fields should reference paths relative to the source object provided.",
    color: "warning.base",
    fontWeight: "semibold",
    size: "sm",
  });

  return showIfKeyMatchesPattern({
    key,
    pattern: "^properties\\.",
    children: [label],
  });
};

export const FieldMappingSection = Section({
  children: [
    MultiField({
      heading: "Event Name",
      subheading: "The value or field to use as the event name.",
      components: [
        ColumnOrConstant("eventName", {
          default: "",
          validation: Yup.lazy((val) =>
            typeof val !== "string"
              ? Yup.object()
                  .shape({ from: Yup.mixed().nullable().required() })
                  .required()
                  .default(null)
                  .nullable()
              : Yup.string(),
          ),
        }),
        validationLabel("eventName.from"),
      ],
      optional: true,
    }),

    MultiField({
      heading: "User ID",
      subheading: "The field to use as the user ID.",
      components: [
        Input("userId", {
          validation: Yup.string(),
          placeholder: "userId",
        }),
        validationLabel("userId"),
      ],
      optional: true,
    }),

    MultiField({
      heading: "Anonymous ID",
      subheading: "The field to use as the anonymous ID.",
      components: [
        Input("anonymousId", {
          validation: Yup.string(),
          placeholder: "anonymousId",
        }),
        validationLabel("anonymousId"),
      ],
      optional: true,
    }),

    MultiField({
      heading: "Message ID",
      subheading: "The field to use as the message ID.",
      components: [
        Input("messageId", {
          validation: Yup.string(),
          placeholder: "messageId",
        }),
        validationLabel("messageId"),
      ],
      optional: true,
    }),

    MultiField({
      heading: "Timestamp",
      subheading: "The field to use as the timestamp.",
      components: [
        Input("timestamp", {
          validation: Yup.string(),
          placeholder: "timestamp",
        }),
        validationLabel("timestamp"),
      ],
      optional: true,
    }),
  ],
});
