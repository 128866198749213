import { useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import {
  DestinationInstancesInsertInput,
  DraftOperation,
  useCreateSyncsMutation,
  useSubmitDraftSyncMutation,
} from "src/graphql";
import { generateSlug } from "src/utils/slug";

export type SyncTemplateToCreateSync = {
  id: number;
  name: string;
  destination: {
    id: string;
  };
  sync_template_overrides?: Record<string, unknown> | null;
};

export const useAddSyncTemplates = () => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const { mutateAsync: createSyncs, isLoading: creatingSyncs } =
    useCreateSyncsMutation();
  const { mutateAsync: submitDraftSync, isLoading: submittingDraftSync } =
    useSubmitDraftSyncMutation();

  const addSyncTemplates = async ({
    audienceId,
    splitId,
    syncTemplates,
  }: {
    audienceId: string;
    splitId?: string;
    syncTemplates: SyncTemplateToCreateSync[];
  }) => {
    const approvalsRequired = workspace?.approvals_required;
    const syncObjects: DestinationInstancesInsertInput[] = syncTemplates.map(
      (syncTemplate) => ({
        segment_id: audienceId,
        sync_template_id: syncTemplate.id,
        slug: generateSlug(syncTemplate.name),
        draft: approvalsRequired,
        destination_id: syncTemplate.destination.id,
        sync_template_overrides: syncTemplate.sync_template_overrides,
        destination_instance_splits: splitId
          ? {
              data: [{ split_id: splitId }],
            }
          : undefined,
      }),
    );

    try {
      const syncs = await createSyncs({
        objects: syncObjects,
      });

      if (approvalsRequired) {
        const syncIds: string[] = (
          syncs.insert_destination_instances?.returning ?? []
        ).map(({ id }) => id.toString());

        // Save newly created syncs as new drafts without approvers.
        // Approvers will be requested later from the individual sync pages.
        await Promise.all(
          syncIds.map(async (syncId: string) => {
            return submitDraftSync(
              {
                resourceId: syncId,
                approverIds: [],
                operation: DraftOperation.Create,
                draft: {
                  _set: {
                    draft: false,
                  },
                },
              },
              {
                onError: (error) => {
                  Sentry.captureException(error);
                },
              },
            );
          }),
        );
      }

      toast({
        id: "add-sync-to-audience",
        title: `${pluralize("sync", syncTemplates.length, true)} added`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "add-sync-to-audience",
        title: `${pluralize(
          "sync",
          syncTemplates.length,
          true,
        )} could not be added. Please try again.`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return {
    addSyncTemplates,
    isLoading: creatingSyncs || submittingDraftSync,
  };
};
