import { FC, memo, useState } from "react";

import {
  Text,
  DeleteIcon,
  MenuActionsButton,
  MenuList,
  MenuItem,
  Menu,
  Row,
  Button,
  Column,
  EditableDescription,
  EditableHeading,
  useToast,
  RefreshIcon,
  Tooltip,
  Spinner,
  Badge,
  Avatar,
} from "@hightouchio/ui";
import { Link } from "src/router";
import * as Sentry from "@sentry/react";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import {
  IdrSetInput,
  useDeleteIdentityResolutionGraphMutation,
  useIdentityResolutionGraphQuery,
  useUpdateIdentityResolutionGraphMutation,
  useRunIdentityResolutionMutation,
  IdrRunStatus,
  useCancelIdentityResolutionMutation,
} from "src/graphql";
import {
  IdentityGraph,
  IdrStats,
  LegacyStats,
  ModelState,
} from "src/pages/identity-resolution/types";
import { PageSpinner } from "src/components/loading";
import { useTabState } from "src/components/route-tabs";
import {
  getIdentifiersFromModels,
  GraphVersion,
  GraphVersionEnabled,
  isRunningGraphDisabled,
} from "src/pages/identity-resolution/utils";
import { RunGraphButton } from "src/pages/identity-resolution/components";
import { formatDate } from "src/utils/time";

const tabs = [
  { title: "Summary", path: "summary" },
  { title: "Runs", path: "runs" },
  { title: "Models", path: "models" },
  { title: "Rules", path: "rules" },
  { title: "Configuration", path: "configuration" },
];

export type OutletContext = {
  graph: IdentityGraph;
  identifiers: string[];
};

// Given an IDR run object from the API, transform the statistics property. This is necessary
// because we may have old IDR runs in the DB upon an upgrade, and those runs will have the old
// statistics format.
export function transformLegacyStatistics(
  stats: LegacyStats | IdrStats,
): IdrStats | undefined {
  if (!stats) {
    return;
  }

  let transformedStats: IdrStats | undefined;

  // We need to transform events and profiles in the same way.
  ["events", "profiles"].forEach((key) => {
    // If the "total" or "resolved" keys are present, we have stats in the old format.
    if (stats[key]?.total !== undefined || stats[key]?.resolved !== undefined) {
      // Initialize the stats copy with default values first
      transformedStats ||= {
        profiles: { new: 0, num_ht_ids: 0, source_rows: 0, models: [] },
        events: { new: 0, num_ht_ids: 0, source_rows: 0, models: [] },
        num_ht_ids: 0,
        // We need to differentiate a real 0 value from an unknown value (due to old stats format),
        // and we use the isLegacy field to do so explicitly.
        isLegacy: true,
      };

      // Update the copy with the values from this key
      transformedStats[key] = {
        num_ht_ids: stats[key].resolved,
        source_rows: stats[key].total,
        new: stats[key].new,
        models: [],
      };
    }
  });

  return transformedStats || (stats as IdrStats);
}

export const IdentityResolutionGraphLoader: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { data: graph, isLoading } = useIdentityResolutionGraphQuery(
    { id: String(id) },
    {
      enabled: Boolean(id),
      select: (data) => {
        // Make a copy of the graph so we aren't mutating the graph from the API.
        const graph = cloneDeep(data.idr_by_pk);
        if (!graph) {
          return graph;
        }

        // Transform statistics from older IDR runs into the newer format so we don't have special
        // case code all over the place.
        graph.runs.map((run) => {
          run.stats = transformLegacyStatistics(run.stats);
          return run;
        });

        return graph as IdentityGraph;
      },
      refetchInterval: 5000,
    },
  );

  if (isLoading) {
    return <PageSpinner />;
  }

  if (!id) {
    return null;
  }

  if (!graph) {
    return (
      <Warning
        subtitle="It may have been deleted"
        title="Identity graph not found"
      />
    );
  }

  const identifiers = getIdentifiersFromModels(
    graph.models as ModelState[],
    graph.version === GraphVersion.V2,
  );

  // Return a memoized outlet to avoid re-rendering the entire graph page when the query re-fetches every 5 seconds.
  return <MemoizedOutlet context={{ graph, identifiers }} />;
};

const MemoizedOutlet = memo(
  function memoOutlet({ context }: { context: OutletContext }) {
    return <Outlet context={context} />;
  },
  (oldContext, newContext) => {
    return isEqual(oldContext, newContext);
  },
);

export const IdentityResolutionGraph: FC = () => {
  const { enableProfileBuilder, idrGraphVersionEnabled } = useFlags();
  const { activeTab } = useTabState(tabs, 4);
  const tab = activeTab?.path || "summary";
  const { graph, identifiers } = useOutletContext<OutletContext>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);

  const showLegacyBadge =
    idrGraphVersionEnabled !== GraphVersionEnabled.Legacy &&
    graph.version !== GraphVersion.V2;

  const deleteMutation = useDeleteIdentityResolutionGraphMutation();
  const updateMutation = useUpdateIdentityResolutionGraphMutation();
  const runMutation = useRunIdentityResolutionMutation();
  const cancelMutation = useCancelIdentityResolutionMutation();

  const update = async (input: IdrSetInput) => {
    try {
      await updateMutation.mutateAsync({
        id: graph.id,
        input,
      });

      toast({
        id: "update-graph",
        title: "Identity graph was updated",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "update-graph",
        title: "There was a problem updating your identity graph.",
        variant: "error",
      });
    }
  };

  const cancelRun = async () => {
    try {
      await cancelMutation.mutateAsync({
        graphId: graph.id,
      });

      toast({
        id: "cancel-run",
        title: "Identity graph run has been cancelled.",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "cancel-run",
        title: "There was a problem cancelling your identity graph run.",
        variant: "error",
      });
    }
  };

  const fullRerun = async () => {
    try {
      await runMutation.mutateAsync({
        id: graph.id,
        fullReRun: true,
      });

      toast({
        id: "run-graph",
        title: "Identity graph run has been initiated.",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "run-graph",
        title: "There was a problem running your identity graph.",
        message: error.message,
        variant: "error",
      });
    }
  };

  const currentStatus = graph.runs?.[0]?.status;
  const isRunning =
    currentStatus === IdrRunStatus.Processing ||
    currentStatus === IdrRunStatus.Queued;

  const idrTabs = enableProfileBuilder
    ? tabs.concat([
        { title: "Golden record", path: "golden-record" },
        { title: "Inspector", path: "inspector" },
      ])
    : tabs;

  const runsDisabled = isRunningGraphDisabled(graph);

  return (
    <DetailPage
      title={`${graph.name} - Identity graph`}
      bg={tab === "runs" ? "white" : "base.lightBackground"}
      hasBottomPadding={tab === "summary" || tab === "runs"}
      crumbs={[{ label: "Identity resolution", link: "/idr" }]}
      tabs={idrTabs}
      header={
        <Column minWidth={0} gap={2}>
          <Row flex={1} justify="space-between" minWidth={0} pt={1}>
            <EditableHeading
              size="lg"
              value={graph.name ?? ""}
              onChange={(name) => {
                update({ name });
              }}
            />
            <Row align="center" gap={4}>
              <Menu>
                <MenuActionsButton variant="secondary" />
                <MenuList>
                  <MenuItem
                    isDisabled={runsDisabled}
                    icon={RefreshIcon}
                    onClick={() => {
                      fullRerun();
                    }}
                  >
                    Rerun from start
                  </MenuItem>
                  <MenuItem
                    icon={DeleteIcon}
                    variant="danger"
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                  >
                    Delete
                  </MenuItem>
                </MenuList>
              </Menu>

              <Tooltip
                isDisabled={!runsDisabled}
                message="To run the graph, the graph must have at least one model and one merge rule defined"
              >
                {isRunning ? (
                  <Button
                    isDisabled={cancelMutation.isLoading}
                    onClick={cancelRun}
                  >
                    <Spinner size="sm" mr={2} />
                    {cancelMutation.isLoading ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <RunGraphButton />
                )}
              </Tooltip>
            </Row>
          </Row>
          <Row>
            <EditableDescription
              value={graph.description ?? ""}
              onChange={(description) => update({ description })}
            />
          </Row>
          <DetailBar>
            <Row align="center" gap={2} flexShrink={0}>
              <IntegrationIcon
                src={graph.source.definition?.icon}
                name={graph.source.definition?.name ?? ""}
              />
              <Link href={`/sources/${graph.source.id}`}>
                <Text isTruncated fontWeight="medium" color="inherit">
                  {graph.source.name}
                </Text>
              </Link>
            </Row>
            <Row align="center" gap={2} flexShrink={0}>
              <Text>Last updated:</Text>
              <Row gap={1} align="center">
                {formatDate((graph.updated_at || graph.created_at)!)}
                {graph.updated_by_user?.name && (
                  <>
                    <Text>by</Text>
                    <Avatar size="xs" name={graph.updated_by_user?.name} />
                  </>
                )}
              </Row>
            </Row>
            {showLegacyBadge && (
              <Row>
                <Badge size="sm">Legacy</Badge>
              </Row>
            )}
          </DetailBar>
        </Column>
      }
    >
      <Outlet context={{ graph, identifiers, runsDisabled }} />

      <DeleteConfirmationModal
        isOpen={isDeleting}
        label="graph"
        onClose={() => {
          setIsDeleting(false);
        }}
        onDelete={async () => {
          await deleteMutation.mutateAsync({ id: graph.id });
          navigate("/idr");
        }}
      />
    </DetailPage>
  );
};
