import { FC, useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Column,
  ResetIcon,
  Row,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import isEqual from "lodash/isEqual";
import get from "lodash/get";
import { useFormContext } from "react-hook-form";

import { useFormkitContext } from "src/formkit/components/formkit-context";

type SyncOverrideProps = {
  childNodeKeys: string[];
};

export const SyncOverride: FC<SyncOverrideProps> = ({ childNodeKeys }) => {
  const { enableSyncTemplateOverrides } = useFlags();

  const { syncConfig, overrideConfig, hasUnlockedFields } = useFormkitContext();
  const { getValues, setValue } = useFormContext();

  const [initialValues, setInitialValues] = useState({});

  // RULE: If the field's children are all unlocked, then this field's value may be overriden.
  const isUnlocked = childNodeKeys.some(
    (nodeKey) => get(overrideConfig, nodeKey) !== undefined,
  );

  // Store initial value on mount
  useEffect(() => {
    if (enableSyncTemplateOverrides) {
      const initialConfigValues = {};

      childNodeKeys.forEach((nodeKey) => {
        initialConfigValues[nodeKey] = get(syncConfig, [nodeKey]) ?? null;
      });

      setInitialValues(initialConfigValues);
    }
  }, [enableSyncTemplateOverrides, childNodeKeys, syncConfig]);

  const hasOverrides = childNodeKeys.some(
    (nodeKey) =>
      !isEqual(get(initialValues, nodeKey) ?? null, getValues(nodeKey) ?? null),
  );

  const reset = () => {
    childNodeKeys.forEach((nodeKey) => {
      // React hook form uses `null` to clear the value. Ignores `undefined`
      setValue(nodeKey, initialValues[nodeKey] ?? null);
    });
  };

  if (!enableSyncTemplateOverrides || !hasUnlockedFields || !isUnlocked) {
    return null;
  }

  return (
    <Row justify="end" gap={2}>
      {hasOverrides && <Badge variant="primary">Custom value</Badge>}
      <Tooltip
        message={
          <Column align="flex-start" gap={2}>
            <Text color="white" fontWeight="medium">
              Template default
            </Text>
            <Box px={1} py={0.5} bg="gray.800" borderRadius="sm">
              {childNodeKeys.map((nodeKey) => (
                <Text color="gray.background" key={nodeKey}>
                  {typeof initialValues[nodeKey] === "object"
                    ? JSON.stringify(initialValues[nodeKey])
                    : (initialValues[nodeKey] ?? "undefined")}
                </Text>
              ))}
            </Box>
          </Column>
        }
      >
        <Button
          icon={ResetIcon}
          isDisabled={!hasOverrides}
          variant="tertiary"
          size="sm"
          onClick={reset}
        >
          Restore default
        </Button>
      </Tooltip>
    </Row>
  );
};
