import { useState } from "react";

import {
  Column,
  Row,
  SearchInput,
  StarBadgeIcon,
  Tag,
  Text,
  Tooltip,
} from "@hightouchio/ui";

import { TextWithTooltip } from "src/components/text-with-tooltip";
import { useUser } from "src/contexts/user-context";
import { MembershipsOrderBy, useWorkspaceMembersQuery } from "src/graphql";
import { Pagination, Table, useTableConfig } from "src/ui/table";
import { PermissionedLinkButton } from "src/components/permission";
import { OrgSettingsBanner } from "./org-settings-banner";

enum SortKeys {
  Name = "user.name",
  Email = "user.email",
  Role = "role.name",
  CreatedAt = "user.created_at",
}

export const MembersV2 = () => {
  const { user: currentUser, workspace } = useUser();

  const [search, setSearch] = useState<string>("");

  const { limit, offset, orderBy, page, setPage, onSort } =
    useTableConfig<MembershipsOrderBy>({
      defaultSortKey: "user.name",
      defaultSortDirection: "asc",
      sortOptions: Object.values(SortKeys),
      limit: 25,
    });

  const { data, isLoading } = useWorkspaceMembersQuery(
    {
      orderBy,
      search: search ? `%${search}%` : undefined,
      limit,
      offset,
      workspaceId: workspace?.id,
    },
    {
      select: (data) => ({
        users: data.all_memberships_v2,
        count: data.all_memberships_v2_aggregate?.aggregate?.count,
      }),
      enabled: Boolean(workspace?.id),
      keepPreviousData: true,
      suspense: true,
    },
  );

  return (
    <Column gap={4}>
      <OrgSettingsBanner />
      <Row alignItems="center" justifyContent="space-between">
        <SearchInput
          placeholder="Search by name or email..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        <PermissionedLinkButton
          permission={{ v2: { resource: "workspace", grant: "can_update" } }}
          href="/settings/groups"
          variant="primary"
        >
          Manage groups
        </PermissionedLinkButton>
      </Row>
      <Table
        rowHeight="auto"
        columns={[
          {
            name: "Name",
            sortDirection: orderBy?.user?.name,
            cell: ({ user }) => (
              <Column>
                <TextWithTooltip fontWeight="medium">
                  <>
                    {user?.name} {currentUser?.id === user?.id && "(you)"}
                  </>
                </TextWithTooltip>
                <TextWithTooltip color="text.secondary">
                  {user?.email}
                </TextWithTooltip>
              </Column>
            ),
            onClick: () => onSort(SortKeys.Name),
          },
          {
            name: "Auth method",
            sortDirection: orderBy?.user?.name,
            cell: ({ user }) => {
              let authMethod = "Email (magic link)";

              if (user?.google_auth_id) {
                authMethod = "Google";
              } else if (user?.azure_ad_auth_id) {
                authMethod = "Microsoft";
              } else if (user?.auth0_auth_id) {
                authMethod = "Single sign-on";
              }

              return <TextWithTooltip>{authMethod}</TextWithTooltip>;
            },
            breakpoint: "sm",
            max: "0.5fr",
          },
          {
            name: "Groups",
            cell: ({ memberships }) => (
              <Row
                gap={2}
                flexWrap="wrap"
                py={2}
                minHeight="80px"
                alignItems="center"
              >
                {memberships.map(({ user_group, is_sso }) => (
                  <Tag key={`${user_group?.id}-${is_sso}`}>
                    <Row gap={1}>
                      {user_group?.is_organization_admin && (
                        <Tooltip message="Organization admin">
                          <StarBadgeIcon />
                        </Tooltip>
                      )}
                      <TextWithTooltip>{user_group?.name}</TextWithTooltip>
                      {is_sso && <Text>(via SSO)</Text>}
                    </Row>
                  </Tag>
                ))}
              </Row>
            ),
          },
        ]}
        key="user_id"
        data={data?.users || []}
        loading={isLoading}
        placeholder={{
          title: "No users",
          error: "Users failed to load, please try again.",
          body: search
            ? "No users match your search."
            : "No users in this organization",
        }}
      />
      <Row mt={4} justify="flex-end" width="100%">
        <Pagination
          count={data?.count || 0}
          label="members"
          page={page}
          rowsPerPage={limit}
          setPage={setPage}
        />
      </Row>
    </Column>
  );
};
