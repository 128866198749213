import { FC, useMemo } from "react";

import {
  Column,
  ConfirmationDialog,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  SectionHeading,
  Tag,
  Text,
  useDisclosure,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import {
  MonitorConditionTypes,
  MonitoredResourceType,
} from "@hightouch/lib/resource-monitoring/types";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import {
  useDeleteAllTriggerOverridesMutation,
  useSubscribeableResourcesQuery,
} from "src/graphql";
import { isPresent } from "src/types/utils";
import { useOverridesState } from "./use-overrides-state";
import { Triggers } from "./triggers";
import { Recipients } from "./recipients";

export const BulkDestinationAlertTriggerManagement: FC<{
  destinationIds: Array<string>;
}> = ({ destinationIds }) => {
  const { data: syncsAndDestinations } = useSubscribeableResourcesQuery(
    undefined,
    { suspense: true },
  );

  const destinations = useMemo(
    () =>
      syncsAndDestinations?.destinations.map((destination) => ({
        ...destination,
        option: {
          label: destination.name || destination.definition.name,
          value: String(destination.id.toString()),
          icon: destination.definition.icon,
        },
      })) ?? [],
    [syncsAndDestinations],
  );

  const selectedDestinations = useMemo(() => {
    return destinationIds
      .map((id) => destinations.find((d) => d.id.toString() === id))
      .filter(isPresent);
  }, [destinationIds, destinations]);

  const syncIds =
    syncsAndDestinations?.syncs
      .filter((sync) =>
        destinationIds.includes(sync.destination?.id?.toString()),
      )
      .map((sync) => sync.id?.toString()) ?? [];

  return (
    <Column flex={1} bg="base.lightBackground">
      <Heading destinations={selectedDestinations} syncIds={syncIds} />
      <Column px={6} gap={6} overflow="auto" pb={12} pt={6}>
        <Recipients destinationIds={destinationIds} />
        <Triggers destinationIds={destinationIds} />
      </Column>
    </Column>
  );
};

const Heading = ({
  syncIds,
  destinations,
}: {
  syncIds: string[];
  destinations: Array<{
    id: string;
    name: string | null;
    definition: {
      icon: string;
      name: string;
    };
  }>;
}) => {
  const destinationIds = destinations.map((d) => d.id.toString());
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { mutateAsync: removeSyncOverrides } =
    useDeleteAllTriggerOverridesMutation();

  const { getSyncCount, getOverrideCount } = useOverridesState(destinationIds);

  if (!destinations.length) return null;

  const singleDestination = (
    <Row gap={2} align="center">
      <IntegrationIcon
        src={destinations[0]!.definition.icon}
        name={destinations[0]!.name || destinations[0]!.definition.name}
      />
      <SectionHeading>
        {destinations[0]!.name || destinations[0]!.definition.name}
      </SectionHeading>
    </Row>
  );

  const multiDestination = (
    <Row gap={2} flexWrap="wrap">
      {destinations.map(({ id, name, definition }) => (
        <Tag key={id} imageUrl={definition.icon}>
          {name || definition.name}
        </Tag>
      ))}
    </Row>
  );

  // TODO: this isn't correct, we need to get the count of overrides for all conditions
  // Otherwise this will sometimes be 0 when there are overrides to other conditions
  const overrideCount = getOverrideCount(
    MonitorConditionTypes.SyncSequentialFailures,
    destinationIds,
  );

  const syncCount = getSyncCount(destinationIds);

  return (
    <Row
      bg="white"
      px={6}
      py={4}
      borderBottom="1px"
      borderColor="base.border"
      justify="space-between"
      align="center"
    >
      {destinations.length === 1 ? singleDestination : multiDestination}
      <Row gap={4} align="center">
        <Text color="text.secondary">
          {`${syncCount} ${pluralize("sync", syncCount)}`}
          {overrideCount
            ? ` (${overrideCount} ${pluralize(
                "sync",
                overrideCount,
              )} using overrides)`
            : ""}
        </Text>
        <Menu>
          <MenuActionsButton variant="secondary" />
          <MenuList>
            <MenuItem variant="danger" onClick={onOpen}>
              Clear all sync-level overrides
            </MenuItem>
          </MenuList>
        </Menu>
      </Row>

      <ConfirmationDialog
        isOpen={isOpen}
        title="Clear overrides"
        confirmButtonText="Proceed"
        variant="warning"
        onClose={onClose}
        onConfirm={async () => {
          await removeSyncOverrides({
            resourceIds: syncIds ?? [],
            resourceType: MonitoredResourceType.Sync,
          });
        }}
      >
        Are you sure you want to clear {overrideCount} sync-level{" "}
        {pluralize("override", overrideCount)}?
      </ConfirmationDialog>
    </Row>
  );
};
