import { createContext, FC, useContext, ReactNode, useEffect } from "react";

import {
  FrameStackIcon,
  SparkleIcon,
  SplitIcon,
  TableIcon,
  TraitIcon,
} from "@hightouchio/ui";
import { groupBy } from "lodash";

import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  MakeOptional,
  Maybe,
  SourceColumnDescription,
  SourceDefinitionFragment as SourceDefinition,
  SyncableColumn,
  SyncQuery,
} from "src/graphql";
import { useModelRun, useUpdateQuery } from "src/utils/models";
import { useModelState } from "src/hooks/use-model-state";
import { ExtendedTypes, RelationshipHierarchy } from "@hightouch/formkit";
import {
  isRelatedColumn,
  isTransformedColumn,
  isTraitColumn,
} from "@hightouch/lib/query/visual/types";
import { OverrideConfig } from "src/components/destinations/types";

export type ColumnOption = {
  customType?: string | null;
  description?: string | null;
  extendedType?: ExtendedTypes;
  icon?: React.ElementType;
  label: string;
  metadata?: {
    properties: Record<string, string[] | null>;
  };
  modelName?: string;
  modelIcon?: React.ElementType;
  options?: ColumnOption[];
  type: string | null;
  value: string | Record<string, unknown>;
};

export type FormkitSync = SyncQuery["syncs"][0];

export type FormkitSyncTemplateConfig = {
  id: number | null;
  override_config: OverrideConfig | null; // The overridable fields
};

export type FormkitModel = MakeOptional<
  Pick<
    NonNullable<FormkitSync["segment"]>,
    | "columns"
    | "syncable_columns"
    | "connection"
    | "custom_query"
    | "id"
    | "name"
    | "parent"
    | "primary_key"
    | "query_dbt_model_id"
    | "query_looker_look_id"
    | "query_integrations"
    | "query_raw_sql"
    | "query_table_name"
    | "query_type"
    | "visual_query_filter"
    | "visual_query_parent_id"
    | "matchboosting_enabled"
  >,
  | "custom_query"
  | "parent"
  | "query_dbt_model_id"
  | "query_looker_look_id"
  | "query_integrations"
  | "query_raw_sql"
  | "query_table_name"
  | "visual_query_filter"
  | "visual_query_parent_id"
> & {
  source_column_descriptions?: Array<SourceColumnDescription> | null;
};

export type FormkitDestination = NonNullable<FormkitSync["destination"]>;

export type ExternalSegment = {
  id: string;
  external_id: Maybe<string>;
  created_at: string;
};

export type DraftChange = { key: string; op: "add" | "replace" };

type FormkitSyncTemplateOverrideContext = {
  allowOverrides: boolean; // Show lock UI
  hasUnlockedFields: boolean; // Show the 'restore default' UI
  overrideConfig: OverrideConfig | null;
  relationshipHierarchy: RelationshipHierarchy | null;
  numberOfRelatedSyncsWithOverrides: Record<string, number> | null;
};

export type BaseFormkitContextType = {
  destination: FormkitDestination | undefined;
  destinationDefinition: DestinationDefinition | undefined;
  sourceDefinition: SourceDefinition | undefined;
  model: FormkitModel | undefined;
  sync: FormkitSync | undefined;
  syncTemplate: FormkitSyncTemplateConfig | undefined;
  supportsMatchboosting: boolean | undefined;
  reloadModel: () => void;
  reloadRows: () => void;
  queryRowsError: string | undefined;
  loadingModel: boolean;
  loadingRows: boolean;
  rows: any;
  slug: string | undefined;
  validate: any;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
};

export type FormkitContextType = BaseFormkitContextType & {
  columns: ColumnOption[];
  sourceId: string | undefined;
  workspaceId: string;
  isSetup: boolean;
  tunnel: any;
  credentialId: string | undefined;
  externalSegment: ExternalSegment | undefined;
  destinationConfig: Record<string, unknown> | undefined;
  syncConfig: Record<string, unknown> | undefined;
  canFieldsBeDisabled: boolean;
} & FormkitSyncTemplateOverrideContext;

export const FormkitContext = createContext<FormkitContextType>(
  {} as FormkitContextType,
);

export const useFormkitContext = () =>
  useContext<FormkitContextType>(FormkitContext);

type FormkitProviderProps = {
  children: ReactNode;
  model?: FormkitModel;
  destination?: FormkitDestination;
  destinationConfig?: Record<string, unknown>;
  sync?: FormkitSync;
  syncTemplate?: FormkitSyncTemplateConfig;
  syncConfig?: Record<string, unknown>;
  numberOfRelatedSyncsWithOverrides?: Record<string, number>;
  destinationDefinition?: DestinationDefinition;
  sourceDefinition?: SourceDefinition;
  externalSegment?: ExternalSegment;
  supportsMatchboosting?: boolean;
  validate: any;
  sourceId?: string;
  credentialId?: string;
  canFieldsBeDisabled?: boolean;
  /**Used within formkit to determine how to render secret fields */
  isSetup?: boolean;
  tunnel?: any;
  /**Used for drafts */
  isModelDraft?: boolean;
  draftChanges?: DraftChange[];
} & Partial<FormkitSyncTemplateOverrideContext>;

export enum ToggleOption {
  Boosted = "boosted",
  Column = "columns",
  Split = "split columns",
  Trait = "traits",
}

const COLUMN_ICON_DICTIONARY = {
  [ToggleOption.Boosted]: {
    group: SparkleIcon,
    model: TableIcon,
  },
  [ToggleOption.Column]: {
    group: TableIcon,
    model: TableIcon,
  },
  [ToggleOption.Trait]: {
    group: TraitIcon,
    model: FrameStackIcon,
  },
  [ToggleOption.Split]: {
    group: SplitIcon,
  },
};

const getColumnValue = (column: any) => {
  if (column.column_reference) {
    if (column.column_reference.type === "raw") {
      return column.column_reference.name;
    } else {
      return column.column_reference;
    }
  }
  return column.name;
};

const mapColumn = (
  column: SyncableColumn,
  isModelDraft: boolean | undefined,
  key: string,
  multipleGroups: boolean,
  description?: string | null,
): ColumnOption => {
  const columnValue = getColumnValue(column);
  const isSplit = key === ToggleOption.Split;
  const label = column.alias || column.name;
  const modelIcon = !isSplit && COLUMN_ICON_DICTIONARY[key].model;
  const modelName = !isSplit
    ? column.model_name + (isModelDraft ? " (draft)" : "")
    : "";
  const displayModelName = key === ToggleOption.Trait || multipleGroups;
  const option: ColumnOption = {
    customType: column.custom_type,
    label,
    metadata: column.metadata,
    modelIcon,
    ...(displayModelName && { modelName }),
    type: column.type,
    value: columnValue,
    description,
  };
  return option;
};

export const MATCHBOOSTED_IDENTIFIER_KEY = "Boosted column";

function isBoosted(column: SyncableColumn | undefined): boolean {
  return column?.column_reference?.type === "boosted";
}

function isSplit(column: SyncableColumn | undefined): boolean {
  return column?.column_reference?.type === "splitTest";
}

function isTrait(column: SyncableColumn | undefined): boolean {
  return (
    (isRelatedColumn(column?.column_reference) &&
      isTraitColumn(column?.column_reference.column)) ||
    isTransformedColumn(column?.column_reference)
  );
}

// We want to group into the following groups: column, boosted, split, and trait. In mapColumnsV1, we were grouping by model_name
// We use columnCount to determine if columns are coming from multiple models. If so, we will also display the model_name
// i.e. [{ icon: TableIcon, label: "Columns", options: [{ customType: undefined, label: "Name", metadata: undefined, modelIcon: TableIcon, type: "string", value: "name" }], type: "", value: "" }]
export const mapColumns = (
  columns: SyncableColumn[] | undefined,
  isModelDraft: boolean | undefined,
  destination: FormkitDestination | undefined,
  supportsMatchboosting: boolean | undefined,
  sourceColumnDescriptions?: Array<SourceColumnDescription> | null,
) => {
  const formattedGroups: Record<ToggleOption, SyncableColumn[]> = {
    [ToggleOption.Column]: [],
    [ToggleOption.Boosted]: [],
    [ToggleOption.Split]: [],
    [ToggleOption.Trait]: [],
  };

  const columnCount = {
    [ToggleOption.Column]: 0,
    [ToggleOption.Boosted]: 0,
    [ToggleOption.Split]: 0,
    [ToggleOption.Trait]: 0,
  };

  const descriptionMap =
    sourceColumnDescriptions?.reduce<Record<string, string | null>>(
      (result, { name, description }) => {
        if (name && description) {
          result[name.toLowerCase()] = description;
        }
        return result;
      },
      {},
    ) ?? {};

  if (columns?.length) {
    const groups = groupBy(columns, "model_name");
    if (destination && !supportsMatchboosting) {
      delete groups[MATCHBOOSTED_IDENTIFIER_KEY];
    }

    for (const options of Object.values(groups)) {
      if (options.length > 0) {
        if (isTrait(options[0])) {
          columnCount[ToggleOption.Trait]++;
          formattedGroups[ToggleOption.Trait].push(...options);
        } else if (isSplit(options[0])) {
          columnCount[ToggleOption.Split]++;
          formattedGroups[ToggleOption.Split].push(...options);
        } else if (isBoosted(options[0])) {
          columnCount[ToggleOption.Boosted]++;
          formattedGroups[ToggleOption.Boosted].push(...options);
        } else {
          options.forEach((option) => {
            // Columns on the model may be traits too
            if (isTrait(option)) {
              columnCount[ToggleOption.Trait]++;
              formattedGroups[ToggleOption.Trait].push(option);
            } else {
              columnCount[ToggleOption.Column]++;
              formattedGroups[ToggleOption.Column].push(option);
            }
          });
        }
      }
    }
  }

  const formattedGroupArray = Object.entries(formattedGroups).map(
    ([key, value]) => ({
      icon: COLUMN_ICON_DICTIONARY[key].group,
      label: key.replace(/_/g, " "),
      options:
        value
          .map((val) =>
            mapColumn(
              val,
              isModelDraft,
              key,
              columnCount[key] > 1,
              descriptionMap[val.name.toLowerCase()],
            ),
          )
          .sort((a, b) => a.label.localeCompare(b.label)) ?? [],
      // Required in ColumnOption
      type: "",
      value: "",
    }),
  );

  return formattedGroupArray.filter((item) => item.options.length > 0);
};

export const FormkitProvider: FC<Readonly<FormkitProviderProps>> = ({
  children,
  model,
  destination,
  destinationDefinition,
  sourceDefinition,
  supportsMatchboosting,
  allowOverrides = false,
  sync,
  syncTemplate,
  numberOfRelatedSyncsWithOverrides = null,
  syncConfig,
  externalSegment,
  validate,
  sourceId,
  isSetup,
  tunnel,
  credentialId,
  draftChanges,
  isModelDraft,
  destinationConfig,
  relationshipHierarchy = null,
  hasUnlockedFields = false,
  canFieldsBeDisabled = false,
}) => {
  const { workspace } = useUser();

  const update = useUpdateQuery();

  const columns = mapColumns(
    model?.syncable_columns,
    isModelDraft,
    destination,
    supportsMatchboosting,
    model?.source_column_descriptions,
  );

  const modelState = useModelState(model);

  useEffect(() => {
    if (model) {
      modelState.set(model);
    }
  }, [model]);

  const {
    rows,
    error: queryRowsError,
    runQuery,
    getSchema,
    schemaLoading,
    loading: rowsLoading,
  } = useModelRun(modelState.state, {
    onCompleted: async ({ columns, rows }, error) => {
      if (error || !columns) {
        return;
      }

      if (rows) {
        const jsonColumnsMetadata = extractJsonColumnsAndTheirProperties(rows);

        if (jsonColumnsMetadata) {
          const keys = Object.keys(jsonColumnsMetadata);
          keys.forEach((key) => {
            columns.forEach((column, index) => {
              if (column.name === key) {
                columns[index].metadata = {
                  properties: jsonColumnsMetadata[key]
                    ? Array.from(jsonColumnsMetadata[key] || [])
                    : undefined,
                };
              }
            });
          });
        }
      }

      await update({
        model: modelState.state,
        columns,
        overwriteMetadata: rows ? true : false,
      });
    },
  });

  const columnsLoading = sourceDefinition?.supportsResultSchema
    ? schemaLoading
    : rowsLoading;

  const reloadRows = () => {
    return runQuery({
      limit: true,
      // No reason to add this here since we only care about the columns.
      disableRowCounter: true,
    });
  };

  const reloadColumns = () => {
    if (sourceDefinition?.supportsResultSchema) {
      getSchema();
    } else {
      reloadRows();
    }
  };

  return (
    <FormkitContext.Provider
      value={{
        isSetup: Boolean(isSetup),
        model,
        destination,
        validate,
        sync,
        syncTemplate,
        syncConfig,
        destinationDefinition,
        destinationConfig,
        sourceDefinition,
        externalSegment,
        columns,
        supportsMatchboosting,
        loadingModel: columnsLoading,
        loadingRows: rowsLoading,
        reloadModel: reloadColumns,
        reloadRows,
        queryRowsError,
        rows,
        slug: destination?.type,
        sourceId,
        tunnel,
        workspaceId: String(workspace?.id),
        credentialId,
        draftChanges,
        isModelDraft,

        allowOverrides,
        overrideConfig:
          // syncTemplate or sync will be passed in, provided by the view. This context is used for both cases.
          // if syncTemplate is passed in, it will be the override config for the sync template
          // if sync is passed in, it will be the override config for the sync template attached to the sync
          syncTemplate?.override_config || sync?.sync_template?.override_config,
        numberOfRelatedSyncsWithOverrides,
        relationshipHierarchy,
        hasUnlockedFields,
        canFieldsBeDisabled,
      }}
    >
      {children}
    </FormkitContext.Provider>
  );
};

function extractJsonColumnsAndTheirProperties(rows: Record<string, any>[]): {
  [column: string]: Set<string>;
} {
  const jsonColumns: { [column: string]: Set<string> } = {};
  rows.forEach((row) => {
    const columns = Object.keys(row);
    columns.forEach((column) => {
      if (Array.isArray(row[column])) {
        if (!jsonColumns[column]) {
          jsonColumns[column] = new Set();
        }
        const firstEntry = row[column][0];
        if (
          typeof firstEntry === "object" &&
          !Array.isArray(firstEntry) &&
          firstEntry !== null
        ) {
          const props = Object.keys(firstEntry);
          props.forEach((prop) => jsonColumns[column]?.add(prop));
        }
      }
    });
  });

  return jsonColumns;
}
