import { Box, Row, Select, Text } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";
import pluralize from "pluralize";

import { Term } from "src/components/term";
import { NumberInputField } from "./fields/number-input-field";
import { ConditionSummary, Critical, Value, Warning } from "./utils";

export const ModelSizeField = ({ name }) => {
  const { setValue, control } = useFormContext();

  return (
    <Row gap={1} align="center" flexWrap="wrap">
      <Controller
        name={`${name}.[0].sign`}
        control={control}
        render={({ field: { value }, fieldState: { error } }) => (
          <Box width="120px">
            <Select
              isInvalid={Boolean(error)}
              value={value}
              size="sm"
              options={[
                { label: "More than", value: "gt" },
                { label: "Fewer than", value: "lt" },
              ]}
              onChange={(option) => {
                setValue(
                  `${name}.[0]`,
                  {
                    sign: option,
                    count: option === "gt" ? 1e6 : 1,
                  },
                  { shouldDirty: true },
                );
              }}
            />
          </Box>
        )}
      />
      <NumberInputField name={`${name}.[0].count`} />
      <Text>
        <Term message="Model size is the total number of queried rows when your sync runs. Model size is different from the number of sync operations.">
          queried rows
        </Term>{" "}
        during a sync run
      </Text>
    </Row>
  );
};

const withSign = (opts: { sign: "gt" | "lt"; count: number }) => {
  const prefix = opts.sign === "gt" ? "more than" : "fewer than";
  return (
    <>
      {prefix} <Value>{Intl.NumberFormat("en-US").format(opts.count)}</Value>{" "}
      queried {pluralize("row", opts.count)}
    </>
  );
};

const Summary = ({ value }) => {
  return <>alert if {withSign(value?.[0])} in a given run.</>;
};

export const ModelSizeSummary: ConditionSummary<
  { sign: "gt" | "lt"; count: number }[]
> = ({ prefix = true, condition }) => {
  return (
    <>
      {condition.error_value?.[0] &&
        Array.isArray(condition.error_value) &&
        condition.error_value.length > 0 && (
          <Critical prefix={prefix}>
            <Summary value={condition.error_value} />
          </Critical>
        )}
      {condition.warning_value?.[0] &&
        Array.isArray(condition.warning_value) &&
        condition.warning_value.length > 0 && (
          <Warning prefix={prefix}>
            <Summary value={condition.warning_value} />
          </Warning>
        )}
    </>
  );
};
