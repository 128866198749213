import {
  BidirectionalArrowIcon,
  CloseIcon,
  GroupedSelect,
  IconButton,
  Row,
  Text,
} from "@hightouchio/ui";
import { FC } from "react";
import { NORMALIZATION_GROUP_OPTIONS } from "src/pages/metrics/constants";
import { NormalizationType } from "src/types/visual";
import { SubSection } from "../subsection";
import { MeasuringMode, NormalizationOption } from "../types";

interface NormalizationProps {
  normalization: NormalizationType;
  onChange: (normalization: NormalizationType) => void;
  onRemove: () => void;
  measuringMode: MeasuringMode | undefined;
}

const isNormalizationOptionSupported = (
  measuringMode: MeasuringMode | undefined,
  option: NormalizationOption,
) => {
  if (measuringMode === MeasuringMode.Incrementality) {
    // This is the only option *NOT* supported for incrementality mode
    return option.value !== NormalizationType.RatePerInteraction;
  }

  // By default, assume in attribution mode
  return [
    NormalizationType.RatePerUser,
    NormalizationType.RatePerInteraction,
  ].includes(option.value);
};

export const Normalization: FC<NormalizationProps> = ({
  normalization,
  onChange,
  onRemove,
  measuringMode,
}) => {
  const availableOptions = NORMALIZATION_GROUP_OPTIONS.map((group) => ({
    ...group,
    options: group.options.filter((option) =>
      isNormalizationOptionSupported(measuringMode, option),
    ),
  })).filter((group) => group.options.length > 0);

  return (
    <SubSection>
      <Row justifyContent="space-between">
        <Text color="text.secondary">
          <Row gap={1} alignItems="center">
            <BidirectionalArrowIcon /> Normalization
          </Row>
        </Text>

        {measuringMode !== MeasuringMode.Incrementality && (
          <IconButton
            aria-label="Reset attribution window."
            icon={CloseIcon}
            size="sm"
            onClick={onRemove}
          />
        )}
      </Row>

      <Row>
        <GroupedSelect
          variant="alternative"
          size="sm"
          width="auto"
          popoverWidth="xs"
          optionGroups={availableOptions}
          value={normalization}
          onChange={(value) => {
            if (value !== undefined) {
              onChange(value);
            }
          }}
        />
      </Row>
    </SubSection>
  );
};
