import { FC } from "react";

import {
  Box,
  Column,
  InformationIcon,
  Row,
  Switch,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { useFormContext } from "react-hook-form";
import { useAnalyticsContext } from "../../state";
import {
  ChartFormState,
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
} from "../../types";

type MeasureIncrementalityProps = {
  measuringSelection: MeasuringSelection | undefined;
};

export const MeasureIncrementality: FC<MeasureIncrementalityProps> = ({
  measuringSelection,
}) => {
  const isDecisionEngineScope =
    measuringSelection?.scope === MeasurementScope.DecisionEngineFlow;

  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const measuringMode = form.watch("measuringMode");

  const { selectMeasurementMode } = useAnalyticsContext();

  const isIncrementalityMode = measuringMode === MeasuringMode.Incrementality;

  const onToggle = () => {
    // If already incrementality mode, switch back to attribution mode
    if (isIncrementalityMode) {
      selectMeasurementMode(MeasuringMode.Attribution);
    } else {
      selectMeasurementMode(MeasuringMode.Incrementality);
    }
  };

  if (!measuringSelection || !isDecisionEngineScope) {
    return null;
  }

  return (
    <Column display="flex" p={4} backgroundColor="gray.100" borderRadius="md">
      <Row gap={2} alignItems="center">
        <Switch
          size="sm"
          isChecked={isIncrementalityMode}
          onChange={onToggle}
        />
        <Row gap={1}>
          <Text color="text.primary" fontWeight="medium">
            Measure incremental lift
          </Text>

          <Tooltip
            message={
              <Box width="225px">
                <Text color="white">
                  Measure the incremental lift of the treatment group over the
                  holdout
                </Text>
              </Box>
            }
          >
            <InformationIcon color="text.tertiary" />
          </Tooltip>
        </Row>
      </Row>
    </Column>
  );
};
