import { FC } from "react";

import {
  MonitorConditionTypes,
  MonitoredResourceType,
} from "@hightouch/lib/resource-monitoring/types";
import {
  Box,
  Button,
  ButtonGroup,
  CloseIcon,
  Column,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  Heading,
  IconButton,
  Row,
  useDisclosure,
  useToast,
} from "@hightouchio/ui";
import pluralize from "pluralize";
import { captureException } from "@sentry/react";

import { MonitorDefinitions } from "./definitions";
import { useOverridesState } from "./use-overrides-state";
import { useDeleteResourceMonitorConditionMutation } from "src/graphql";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/components/text-with-tooltip";

type Props = {
  conditionType: MonitorConditionTypes;
  destinationIds: string[];
};

export const Overrides: FC<Readonly<Props>> = ({
  conditionType,
  destinationIds,
}) => {
  const { toast } = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { getOverrides } = useOverridesState(destinationIds);

  const overrides = getOverrides(conditionType, destinationIds);

  const { mutateAsync: removeSyncOverrides, isLoading: isRemoving } =
    useDeleteResourceMonitorConditionMutation();

  const onRemove = async () => {
    try {
      await removeSyncOverrides({
        conditionType,
        resourceIds: overrides?.map((o) => o.sync?.id).map(String) ?? [],
        resourceType: MonitoredResourceType.Sync,
      });
      toast({
        id: "remove-sync-overrides",
        title: "Sync-level overrides removed",
        variant: "success",
      });
      onClose();
    } catch (e) {
      toast({
        id: "remove-sync-overrides",
        title: "Error removing overrides",
        variant: "error",
      });
      captureException(e);
    }
  };

  const definition = MonitorDefinitions[conditionType];

  if (!overrides) {
    return null;
  }

  return (
    <>
      {overrides.length > 0 && (
        <Box
          as="button"
          fontWeight="medium"
          color="link.default"
          onClick={() => {
            onOpen();
          }}
        >
          {overrides.length} {pluralize("override", overrides.length)}
        </Box>
      )}
      <Drawer size="xl" onClose={onClose} isOpen={isOpen}>
        <DrawerHeader>
          <Row
            align="center"
            flex={1}
            minWidth={0}
            justifyContent="space-between"
          >
            <Heading>
              {`Sync-level overrides for "${definition.title}"`}
            </Heading>

            <IconButton
              aria-label="Close drawer"
              icon={CloseIcon}
              onClick={onClose}
            />
          </Row>
        </DrawerHeader>
        <DrawerBody>
          <Table
            columns={[
              {
                name: "Model",
                cell: ({ sync }) => {
                  return (
                    <TextWithTooltip>{sync?.segment?.name}</TextWithTooltip>
                  );
                },
              },
              {
                name: "Alert",
                min: "max-content",
                cell: ({ condition }) => {
                  return (
                    <Column gap={2} p={2}>
                      <definition.summary condition={condition} />
                    </Column>
                  );
                },
              },
            ]}
            data={overrides}
          />
        </DrawerBody>
        <DrawerFooter>
          <ButtonGroup>
            <Button variant="danger" onClick={onRemove} isLoading={isRemoving}>
              Remove override
            </Button>
            <Button onClick={onClose}>Close</Button>
          </ButtonGroup>
        </DrawerFooter>
      </Drawer>
    </>
  );
};
