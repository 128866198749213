import { FC, PropsWithChildren, useState } from "react";

import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Column,
  DeleteIcon,
  DragIcon,
  IconButton,
  Paragraph,
  PlusIcon,
  Row,
  Select,
  Text,
  Tooltip,
} from "@hightouchio/ui";
import { Reorder, useDragControls } from "framer-motion";
import omit from "lodash/omit";
import {
  FieldArrayWithId,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

import { ErrorMessage } from "src/components/explore/visual/error-message";
import { RulesFormStatev2 } from "src/pages/identity-resolution/types";

import { useRulesFormContext } from "./rules-form-context";

const boxShadowColor = "rgba(219, 225, 232, 0.20)";

type MergeRule = FieldArrayWithId<RulesFormStatev2, "merge_rules", "id">;

export const IdentifierMergeRules = () => {
  const { identifiers } = useRulesFormContext();
  const [movedItemId, setMovedItemId] = useState<string | null>(null);
  const {
    control,
    formState: { errors },
  } = useFormContext<RulesFormStatev2>();
  const { fields, append, update, remove, move } = useFieldArray({
    control,
    name: "merge_rules",
  });

  const updateMergeRules = (updatedMergeRules: MergeRule[]) => {
    const startIndex = fields.findIndex((rule) => rule.id === movedItemId);
    const destinationIndex = updatedMergeRules.findIndex(
      (rule) => rule.id === movedItemId,
    );
    move(startIndex, destinationIndex);
  };

  return (
    <Column gap={4}>
      <Column>
        <Text fontWeight="medium" size="lg">
          Identifier priority
        </Text>
        <Paragraph size="sm">
          Define the priority order of identifiers used to merge profiles.
        </Paragraph>
      </Column>

      {fields.length > 0 && (
        <>
          <Text fontWeight="medium" color="text.secondary">
            Highest priority
          </Text>
          <Reorder.Group
            axis="y"
            initial={false}
            layoutScroll
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
              listStyle: "none",
              paddingLeft: 0,
            }}
            values={fields}
            onReorder={updateMergeRules}
          >
            {fields.map((item, index) => {
              const identifierError =
                errors.merge_rules?.[index]?.identifier?.message;

              return (
                <DraggableItem
                  key={item.id}
                  item={item}
                  isUpDisabled={index === 0}
                  isDownDisabled={index === fields.length - 1}
                  onDown={() => move(index, index + 1)}
                  onDragEnd={() => setMovedItemId(null)}
                  onDragStart={setMovedItemId}
                  onUp={() => move(index, index - 1)}
                >
                  <Row align="baseline" gap={2}>
                    <Text color="text.secondary">Match on</Text>
                    <Column>
                      <Select
                        isInvalid={Boolean(identifierError)}
                        placeholder="Select an identifier..."
                        options={identifiers.filter(
                          (identifier) =>
                            identifier === item.identifier ||
                            !fields.some(
                              (mergeRule) =>
                                mergeRule.identifier === identifier,
                            ),
                        )}
                        optionValue={(option) => option}
                        optionLabel={(option) => option}
                        width="auto"
                        value={item.identifier}
                        onChange={(value) => {
                          if (!value) return;

                          update(index, {
                            ...omit(item, "id"),
                            identifier: value,
                          });
                        }}
                      />
                      {identifierError && (
                        <ErrorMessage>{identifierError}</ErrorMessage>
                      )}
                    </Column>
                  </Row>
                  <Tooltip
                    message="Remove identifier"
                    openSpeed="slow"
                    placement="top-end"
                  >
                    <IconButton
                      aria-label="Remove identifier."
                      variant="danger"
                      icon={DeleteIcon}
                      onClick={() => remove(index)}
                    />
                  </Tooltip>
                </DraggableItem>
              );
            })}
          </Reorder.Group>
        </>
      )}
      <Box>
        <Button
          icon={PlusIcon}
          onClick={() => append({ identifier: "", transformations: [] })}
        >
          Add identifier
        </Button>
      </Box>
      {fields.length > 0 && (
        <Text fontWeight="medium" color="text.secondary">
          Lowest priority
        </Text>
      )}
    </Column>
  );
};

type DraggableItemProps = {
  item: MergeRule;
  isUpDisabled: boolean;
  isDownDisabled: boolean;
  onDragStart: (id: string) => void;
  onDragEnd: () => void;
  onUp: () => void;
  onDown: () => void;
};

const DraggableItem: FC<PropsWithChildren<DraggableItemProps>> = ({
  children,
  item,
  isUpDisabled,
  isDownDisabled,
  onDragStart,
  onDragEnd,
  onUp,
  onDown,
}) => {
  const [dragging, setDragging] = useState(false);
  const controls = useDragControls();

  return (
    <Reorder.Item
      dragListener={false}
      dragControls={controls}
      value={item}
      style={{
        display: "flex",
        border: "1px solid var(--chakra-colors-base-divider)",
        borderRadius: "var(--chakra-radii-md)",
        boxShadow: `0px 0px 8px 0px ${boxShadowColor} inset`,
        overflow: "hidden",
        backgroundColor: "rgba(255,255,255,1)",
        userSelect: "none",
      }}
      whileDrag={{
        boxShadow:
          "0px 12px 16px rgba(16, 24, 40, 0.16), 0px 8px 16px rgba(16, 24, 40, 0.16), 0px 0px 12px rgba(16, 24, 40, 0.08)",
      }}
      onDragEnd={() => {
        setDragging(false);
        onDragEnd?.();
      }}
      onDragStart={() => {
        setDragging(true);
        onDragStart?.(item.id);
      }}
    >
      <Column
        align="center"
        justify="space-between"
        color="text.secondary"
        borderRight="1px"
        p={1}
        borderColor="base.border"
      >
        <Row
          as="button"
          outline="none !important"
          _focus={{ boxShadow: "outline" }}
          aria-label="Move up"
          color={isUpDisabled ? "base.border" : "inherit"}
          disabled={isUpDisabled}
          cursor={isUpDisabled ? "default" : "pointer"}
          onClick={onUp}
        >
          <ChevronUpIcon />
        </Row>
        <Row
          cursor={dragging ? "grabbing" : "grab"}
          color="base.border"
          className="handle"
          onPointerDown={(e) => controls.start(e)}
        >
          <Box as={DragIcon} fontSize="3xl" />
        </Row>
        <Row
          as="button"
          outline="none !important"
          _focus={{ boxShadow: "outline" }}
          aria-label="Move down"
          color={isDownDisabled ? "base.border" : "inherit"}
          disabled={isDownDisabled}
          cursor={isDownDisabled ? "default" : "pointer"}
          onClick={onDown}
        >
          <ChevronDownIcon />
        </Row>
      </Column>
      <Row align="center" justify="space-between" flex={1} gap={2} p={4}>
        {children}
      </Row>
    </Reorder.Item>
  );
};
