import {
  Box,
  CloseIcon,
  Column,
  GroupedCombobox,
  IconButton,
  PlusIcon,
  Row,
  SectionHeading,
  Text,
  Tooltip,
  WarningIcon,
} from "@hightouchio/ui";
import { isEqual, uniqBy } from "lodash";
import { useFormContext } from "react-hook-form";
import { isPresent } from "ts-extras";

import { useAnalyticsContext } from "src/pages/analytics/state";
import {
  ChartFormState,
  GraphType,
  GroupByOption,
  GroupByOptionColumnReference,
} from "src/pages/analytics/types";
import {
  getEventById,
  getMetricById,
  getUnsupportedMetricNamesForGroupByColumn,
} from "src/pages/analytics/utils";
import {
  convertValueToArray,
  getColumnsAndIndexToUpdate,
  getSameNameEventColumnsOptions,
  transformGroupByColumns,
} from "./group-by-utils";

export const GroupBy = () => {
  const {
    addGroupByColumn,
    addGroupByColumns,
    removeGroupByColumns,
    parent,
    parentModelLoading,
    audiencesAndMetricsLoading,
    metrics,
    events,
    measurementColumnOptions,
    additionalUserColumns,
  } = useAnalyticsContext();
  const form = useFormContext<ChartFormState>();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore: Suppress circular reference error
  const groupByColumns = form.watch("groupByColumns");
  const graphType = form.watch("graphType");
  const metricSelection = form.watch("metricSelection");

  const isFunnelGraphType = graphType === GraphType.Funnel;

  // Events are derived from the selected metrics.
  // It's possible that multiple metrics reference the same event, so we need to dedupe the models.
  const selectedEvents = uniqBy(
    metricSelection
      .map((selectedMetric) => {
        const metricDefinition = getMetricById(metrics, selectedMetric.id);
        const eventModelId =
          selectedMetric.eventModelId ?? metricDefinition?.config.eventModelId;

        return getEventById(events, {
          eventModelId,
          relationshipId:
            metricDefinition?.config.relationshipId ?? selectedMetric?.id,
        });
      })
      .filter(isPresent),
    (relationship) => relationship.id,
  );

  const groupByValues = transformGroupByColumns(groupByColumns, parent);

  const additionalUserColumnsOptions = (additionalUserColumns ?? []).map(
    (column) => ({
      groupLabel: null,
      name: column.alias,
      columnReference: column.columnReference,
      description: "AI decisioning user feature",
    }),
  );

  const groupByOptions: { label: string; options: GroupByOption[] }[] = [
    measurementColumnOptions != null
      ? {
          label: measurementColumnOptions.label,
          options: measurementColumnOptions.options.map((opt) => ({
            groupLabel: null,
            name: opt.alias,
            columnReference: opt.columnReference,
          })),
        }
      : null,
    {
      label: "User properties",
      options: (
        parent?.filterable_audience_columns
          .filter(
            ({ column_reference }) =>
              !isFunnelGraphType ||
              // Not supporting merge columns for funnel graphs right now
              (isFunnelGraphType && column_reference.type !== "related"),
          )
          .map((column) => ({
            groupLabel:
              column.model_id === parent.id?.toString()
                ? null
                : column.model_name,
            name: column.alias || column.name,
            columnReference: column.column_reference,
          })) ?? []
      ).concat(additionalUserColumnsOptions),
    },
    !isFunnelGraphType && selectedEvents.length === 1
      ? {
          label: "Event properties",
          options: selectedEvents.flatMap(({ to_model }) => {
            return to_model.filterable_audience_columns.map((column) => ({
              groupLabel: column.model_id == to_model.id ? null : to_model.name,
              name: column.alias || column.name,
              columnReference: column.column_reference,
            }));
          }),
        }
      : // When there are multiple metrics, only show columns of the same name and
        // group these together to represent one groupBy and one column in the graph
        !isFunnelGraphType && selectedEvents.length > 1
        ? {
            label: "Shared events properties",
            options: getSameNameEventColumnsOptions(selectedEvents),
          }
        : null,
  ]
    .filter(isPresent)
    .filter((group) => group.options.length > 0);

  const handleChange = (
    value: GroupByOptionColumnReference | undefined,
    optionIndex: number,
  ) => {
    const { columns, startIndex } = getColumnsAndIndexToUpdate({
      value,
      optionIndex,
      groupByColumns,
      groupByValues,
    });

    addGroupByColumns(columns, startIndex);
  };

  const addEmptyGroupBy = () => {
    addGroupByColumn(undefined, groupByColumns.length);
  };

  const removeGroup = (optionIndex: number) => {
    const currentGroupByValue = convertValueToArray(groupByValues[optionIndex]);
    const firstGroupByIndex = groupByColumns.findIndex((gb) =>
      isEqual(gb, currentGroupByValue[0]),
    );

    // Default to end of the list if we are not removing existing groupByColumns
    const startIndex =
      firstGroupByIndex !== -1 ? firstGroupByIndex : groupByColumns.length - 1;
    const endIndex = startIndex + currentGroupByValue.length - 1;

    removeGroupByColumns(startIndex, endIndex);
  };

  return (
    <Column gap={2}>
      <Row align="center" justify="space-between" minHeight="32px">
        <SectionHeading>Group by</SectionHeading>
        {((!isFunnelGraphType && groupByValues.length < 2) ||
          (isFunnelGraphType && groupByValues.length < 1)) && (
          <Tooltip message="Add group by">
            <IconButton
              icon={PlusIcon}
              aria-label="Add group by"
              onClick={addEmptyGroupBy}
            />
          </Tooltip>
        )}
      </Row>
      {groupByValues.map((groupByColumn, index) => {
        // TODO: @jenn-chan update function to handle multiple groupBys value
        const unsupportedMetricNames = !Array.isArray(groupByColumn)
          ? getUnsupportedMetricNamesForGroupByColumn({
              parent,
              groupByColumn,
              metricSelection,
              metrics,
              events: selectedEvents,
            })
          : [];

        return (
          <Column
            key={index}
            bg="white"
            border="1px solid #dbe1e8"
            borderRadius="md"
            gap={2}
            p={2}
          >
            <Row
              gap={2}
              flex={1}
              minWidth={0}
              sx={{
                input: {
                  width: "100%",
                  boxSizing: "border-box",
                },
              }}
            >
              <GroupedCombobox
                isLoading={parentModelLoading || audiencesAndMetricsLoading}
                optionLabel={({ groupLabel, name }) =>
                  groupLabel ? `${groupLabel} -> ${name}` : name
                }
                optionValue={(column) => column.columnReference}
                optionGroups={groupByOptions}
                placeholder="Select a column to group by..."
                value={groupByColumn}
                variant="heavy"
                onChange={(column) => handleChange(column, index)}
              />
              {(isPresent(groupByColumn) || groupByValues.length > 1) && (
                <Tooltip message="Remove group by column">
                  <IconButton
                    icon={CloseIcon}
                    aria-label="Remove group by column"
                    variant="tertiary"
                    onClick={() => removeGroup(index)}
                  />
                </Tooltip>
              )}
            </Row>
            {unsupportedMetricNames.length > 0 && (
              <Box
                display="grid"
                gridTemplateColumns="28px 1fr"
                alignItems="center"
                color="warning.base"
                fontSize="20px"
                gap={2}
              >
                <WarningIcon ml={2} />
                <Text color="warning.base" size="sm">
                  This “Group by” does not apply to{" "}
                  {unsupportedMetricNames.join(", ")}
                </Text>
              </Box>
            )}
          </Column>
        );
      })}
    </Column>
  );
};
