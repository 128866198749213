import { useEffect, useMemo, useState } from "react";

import {
  Button,
  ButtonGroup,
  Column,
  PlusIcon,
  Row,
  Switch,
  Text,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import noop from "lodash/noop";
import { Outlet, useNavigate, useOutletContext, useParams } from "src/router";

import genericPlaceholder from "src/assets/placeholders/generic.svg";
import { Drawer } from "src/components/drawer";
import { useHightouchForm } from "src/components/form";
import { TextWithTooltip } from "src/components/text-with-tooltip";
import {
  GoldenRecordIdentifier,
  GoldenRecordOutletContext,
  IdentityGraph,
  SurvivorshipRule,
  SurvivorshipRules,
} from "src/pages/identity-resolution/types";
import { Table } from "src/ui/table";

import { OutletContext } from ".";

const Placeholder = {
  image: genericPlaceholder,
  title: "No models",
  body: "Select input models to run an identity resolution process on. Use the resulting identity graph to create a golden record of user information.",
  error: "Models failed to load, please try again.",
};

function getInitialValues(
  models: IdentityGraph["models"],
): GoldenRecordIdentifier[] {
  const records: GoldenRecordIdentifier[] = models.flatMap((model) =>
    model.mappings.map((mapping) => ({
      identifier: mapping.identifier,
      columnName: mapping.identifier,
      models: [model.model],
      survivorshipRule: SurvivorshipRule.MostRecent,
      column: null,
    })),
  );

  // combine duplicate identifiers
  return Object.values(
    records.reduce(
      (acc, record) => {
        const storedRecord = acc[record.identifier];

        if (!storedRecord) {
          acc[record.identifier] = record;
        } else {
          acc[record.identifier] = {
            ...storedRecord,
            models: storedRecord.models.concat(record.models),
          };
        }

        return acc;
      },
      {} as Record<string, GoldenRecordIdentifier>,
    ),
  );
}

export const GoldenRecord = () => {
  const { enableProfileBuilder } = useFlags();
  const { recordId } = useParams<{ recordId?: string }>();
  const navigate = useNavigate();

  const [enableGoldenRecord, setEnableGoldenRecord] = useState(true);
  const [showDrawerAnimation, setShowDrawerAnimation] = useState(true);

  const closeDrawer = () => {
    navigate("./");
    setShowDrawerAnimation(true);
  };

  const { graph } = useOutletContext<OutletContext>();

  const form = useHightouchForm({
    onSubmit: () => Promise.resolve(),
    values: {
      records: getInitialValues(graph.models),
    },
  });

  useEffect(() => {
    if (!enableProfileBuilder) {
      navigate("../");
    }
  }, [enableProfileBuilder]);

  // Manage the drawer animation
  useEffect(() => {
    if (recordId) {
      setTimeout(() => {
        setShowDrawerAnimation(false);
      }, 500);
    }
  }, [recordId]);

  const saveRecord = (index: number, data: GoldenRecordIdentifier) => {
    form.setValue(`records.${index}`, data);

    return Promise.resolve();
  };

  const [selectedRecord, selectedRecordIndex] = useMemo(() => {
    const records = form.getValues("records");
    const recordIndex =
      records.findIndex((record) => record.identifier === recordId) ?? null;
    return [records[recordIndex] ?? null, recordIndex];
  }, [form.getValues, recordId]);

  const outletContext: GoldenRecordOutletContext = {
    record: selectedRecord,
    onSubmit: (data) => saveRecord(selectedRecordIndex, data),
    onClose: closeDrawer,
  };

  return (
    <Column pb={24} gap={4}>
      <Row justify="space-between">
        <Column gap={2}>
          <Row
            sx={{
              label: {
                display: "flex",
                gap: 2,
                cursor: "pointer",
              },
            }}
          >
            <Text as="label" size="lg" fontWeight="medium">
              Enable golden record
              <Switch
                isChecked={enableGoldenRecord}
                onChange={setEnableGoldenRecord}
              />
            </Text>
          </Row>
          <Text color="text.secondary">
            Compile the best identifiers from your models to make the build a
            user parent model.
          </Text>
        </Column>
        {enableGoldenRecord && <Button onClick={noop}>Configuration</Button>}
      </Row>

      {enableGoldenRecord && (
        <>
          <Table
            placeholder={{
              ...Placeholder,
              button: (
                <Button variant="primary" onClick={noop}>
                  Add model
                </Button>
              ),
            }}
            data={form.watch("records")}
            columns={[
              {
                name: "Column name",
                cell: ({ columnName, identifier }) => (
                  <Row gap={1}>
                    {/* TODO: hover and click effects on pill */}
                    <TextWithTooltip>
                      {columnName ?? identifier}
                    </TextWithTooltip>{" "}
                  </Row>
                ),
              },
              {
                name: "Survivorship rule",
                cell: ({ survivorshipRule }) => (
                  <TextWithTooltip>
                    {SurvivorshipRules[survivorshipRule].label}
                  </TextWithTooltip>
                ),
              },
              {
                name: "Models",
                cell: ({ models }) => (
                  <TextWithTooltip>
                    {models.map((model) => model.name).join(", ")}
                  </TextWithTooltip>
                ),
              },
            ]}
            onRowClick={({ identifier }) => navigate(identifier)}
          />

          {/* TODO: only show if no error? */}
          <Row justify="space-between">
            <Button size="lg" icon={PlusIcon} variant="tertiary" onClick={noop}>
              Add column
            </Button>
            <ButtonGroup size="lg">
              <Button size="lg" onClick={() => navigate("../inspector")}>
                Inspect results
              </Button>
            </ButtonGroup>
          </Row>
        </>
      )}

      <Drawer
        closeOnEsc
        blockBackgroundInteraction={showDrawerAnimation}
        isOpen={Boolean(recordId)}
        size="lg"
        contentWrapperStyles={{
          borderLeft: "1px",
          borderTop: "1px",
          borderColor: "base.border",
        }}
        onClose={closeDrawer}
      >
        <Outlet context={outletContext} />
      </Drawer>
    </Column>
  );
};
