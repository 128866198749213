import uniq from "lodash/uniq";
import { v4 as uuidv4 } from "uuid";
import { isPresent } from "ts-extras";

import {
  BlockRule,
  MergeRule as MergeRuleType,
  MergeRuleSet,
  IdentityGraph,
  RulesFormState,
  getDefaultIdentifiers,
} from "src/pages/identity-resolution/types";
import { ModelState } from "src/pages/identity-resolution/types";
import { IDRv2IdentifierMergeRule } from "src/types/idr";

import { GraphVersion } from "./graph-utils";

export const defaultOuterType = "and";
export const defaultInnerType = "or";

export const getDefaultMergeRule = (): MergeRuleType => ({
  identifier: "",
  operator: "eq",
  transformations: [],
});

export const getDefaultMergeRuleSet = (): MergeRuleSet => ({
  identifier: uuidv4(),
  type: defaultOuterType,
  conditions: [
    {
      type: defaultInnerType,
      rules: [
        {
          identifier: "",
          operator: "eq",
          transformations: [],
        },
      ],
    },
  ],
});
export const getDefaultBlockRule = (): BlockRule => ({
  identifier: "",
  limit: 1,
});

export const getDefaultMergeRuleV2 = (): IDRv2IdentifierMergeRule => ({
  identifier: "",
  transformations: [],
});
const isGraphVersionV2 = (
  graph: IdentityGraph,
): graph is IdentityGraph & { version: GraphVersion.V2 } => {
  return graph.version === GraphVersion.V2;
};

export const getRulesFormState = (graph: IdentityGraph): RulesFormState => {
  // The version will determine the shape of the merge rules
  // The graph version is set on creation and cannot be changed.
  if (isGraphVersionV2(graph)) {
    return {
      version: graph.version,
      merge_rules: graph.merge_rules ?? [getDefaultMergeRuleV2()],
      block_rules: graph.block_rules ?? [],
      resolution_rules: graph.resolution_rules ?? [],
    };
  }

  // We modified merge_rules to be an array of MergeRules objects, we need to handle the old
  // structure for backwards compatibility.
  const mergeRules =
    graph.merge_rules && !Array.isArray(graph.merge_rules)
      ? [graph.merge_rules]
      : graph.merge_rules;

  return {
    version: GraphVersion.V1 as const,
    merge_rules: mergeRules ?? [getDefaultMergeRuleSet()],
    block_rules: graph.block_rules ?? [],
    resolution_rules: graph.resolution_rules ?? [],
  };
};

/**
 * The graph needs to be re-run if:
 * - New limit rule is added
 * - The limit number for a limit rule is reduced
 *
 * Note: will be removed once the backend has updated this.
 *
 * @returns boolean
 */
export const checkForBlockRuleLimitDecreases = (
  oldBlockRules: BlockRule[] | null,
  newBlockRules: BlockRule[] | null,
) => {
  const oldIdentifiers = new Map(
    (oldBlockRules ?? []).map((rule) => [rule.identifier, rule.limit]),
  );

  for (const rule of newBlockRules ?? []) {
    // If rule is removed, we don't need to re-run
    if (oldIdentifiers.has(rule.identifier)) {
      const oldLimit = oldIdentifiers.get(rule.identifier);
      if (isPresent(oldLimit) && rule.limit < oldLimit) {
        return true;
      }
    } else {
      return true;
    }
  }

  return false;
};

export const getIdentifiersFromModels = (
  models: ModelState[],
  isIDRv2: boolean,
) => {
  // Pull out identifiers from models
  const identifiersDefinedOnModels = uniq(
    models.flatMap(({ mappings }) =>
      mappings.map(({ identifier }) => identifier),
    ),
  ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  // Filter out the identifiers from the default list that are not defined on the models
  const topIdentifiers = getDefaultIdentifiers(isIDRv2).filter((identifier) =>
    identifiersDefinedOnModels.includes(identifier),
  );

  // Order begins with default identifiers
  return uniq(topIdentifiers.concat(identifiersDefinedOnModels));
};

/**
 * Determines the identifiers that are defined by other models in the graph.
 *
 * Returns an empty array for v1 graphs
 */
export function getIdentifiersDefinedByThisModel(
  modelId: string | undefined,
  graph: IdentityGraph,
) {
  if (graph.version !== GraphVersion.V2 || !modelId) return [];

  const thisModelIdentifiers =
    graph.models
      .find((model) => model.id === modelId)
      ?.mappings?.map(({ identifier }) => identifier) ?? [];
  const otherIdentifiers = graph.models
    .filter((model) => model.id !== modelId)
    .flatMap((model) => model.mappings?.map(({ identifier }) => identifier));

  // Return all identifiers that are only defined by this model
  return thisModelIdentifiers.filter(
    (identifier) => !otherIdentifiers?.includes(identifier),
  );
}

export function getRemovedIdentifiers(
  identifiersDefinedByThisModel: string[],
  newIdentifiers: string[],
) {
  return identifiersDefinedByThisModel.filter(
    (identifier) => !newIdentifiers.includes(identifier),
  );
}
