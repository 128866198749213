import { useDecisionEngineFlowOutcomesQuery } from "src/graphql";
import { Relationship } from "src/types/visual";
import { PREDEFINED_METRIC_OPTIONS } from "../constants";
import {
  DecisionEngineAggregationOptions,
  decisionEngineFlowMetricOptions,
} from "../decision-engine-utils";
import {
  MeasurementScope,
  MeasuringMode,
  MeasuringSelection,
  Metric,
  MetricGroupOption,
} from "../types";
import {
  AggregationOptions,
  MetricAggregationOption,
} from "src/pages/metrics/constants";

type UseAvailableMetricOptions = {
  measuringSelection: MeasuringSelection | undefined;
  measuringMode: MeasuringMode | undefined;
  metrics: Metric[] | undefined;
  events: Relationship[] | undefined;
};

/**
 * Hook for fetching available metric options and supported aggregations for
 * these metrics based on the measuring selection and other dependencies
 */
export const useAvailableMetricOptions = ({
  measuringSelection,
  measuringMode,
  metrics,
  events,
}: UseAvailableMetricOptions): {
  availableMetricsOptions: MetricGroupOption[];
  supportedAggregationOptions: MetricAggregationOption[];
  isLoading: boolean;
} => {
  const { data: outcomesEvents, isLoading } =
    useDecisionEngineFlowOutcomesQuery(
      {
        flowId: measuringSelection?.id.toString() ?? "",
      },
      {
        enabled: Boolean(
          measuringSelection?.scope === MeasurementScope.DecisionEngineFlow,
        ),
        select: (data) => data.decision_engine_flow_outcomes,
      },
    );

  switch (measuringSelection?.scope) {
    case MeasurementScope.DecisionEngineFlow: {
      return {
        availableMetricsOptions: decisionEngineFlowMetricOptions(
          measuringSelection.id,
          measuringMode,
          events,
          outcomesEvents,
        ),
        supportedAggregationOptions: DecisionEngineAggregationOptions,
        isLoading,
      };
    }
    case MeasurementScope.AllData:
    default:
      return {
        availableMetricsOptions: defaultMetricOptions(metrics, events),
        supportedAggregationOptions: AggregationOptions,
        isLoading: false,
      };
  }
};

const defaultMetricOptions = (
  metrics: Metric[] | undefined,
  events: Relationship[] | undefined,
) => {
  return [
    {
      label: "Saved metrics",
      options: [
        ...PREDEFINED_METRIC_OPTIONS,
        ...(metrics ?? []).map((goal) => ({
          id: goal.id,
          eventModelId: null,
          name: goal.name,
          description: goal.description,
        })),
      ],
    },
    {
      label: "Events",
      options: (events ?? []).map((event) => ({
        id: event.id,
        eventModelId: event.to_model.id,
        name: event.to_model?.name ?? event.name,
        description: event?.to_model?.description,
      })),
    },
  ].filter(({ options }) => options.length > 0);
};
