import { Select, Row, Radio, RadioGroup, Box } from "@hightouchio/ui";
import { Controller, useFormContext } from "react-hook-form";

import {
  MonitorConditionFormType,
  MonitorModes,
} from "src/components/resource-alert-triggers/utils";
import { MonitorDefinitions } from "src/components/resource-alert-triggers/definitions";
import { MonitorConditionTypes } from "@hightouch/lib/resource-monitoring/types";

export const ModeField = ({
  mode,
  conditionType,
}: {
  mode: MonitorModes;
  conditionType: MonitorConditionTypes;
}) => {
  const definition = MonitorDefinitions[conditionType];
  const { control, setValue, watch } =
    useFormContext<MonitorConditionFormType>();

  const defaultModeSelection = watch("defaultModeSelection");

  const defaultCondition =
    mode === MonitorModes.CRITICAL
      ? {
          error_value: definition.defaultValue(),
          warning_value: null,
        }
      : {
          warning_value: definition.defaultValue(),
          error_value: null,
        };

  return (
    <Controller
      name="condition"
      control={control}
      render={({ field }) => {
        const onChange = (mode: MonitorModes) => {
          setValue("mode", mode);

          switch (mode) {
            case MonitorModes.NONE:
              field.onChange({
                error_value: null,
                warning_value: null,
              });
              break;
            case MonitorModes.CUSTOM:
              field.onChange({
                error_value: definition.defaultValue(),
                warning_value: definition.defaultValue(),
              });
              break;
            case MonitorModes.CRITICAL:
            case MonitorModes.WARNING:
              setValue("defaultModeSelection", mode);
              field.onChange({
                error_value:
                  mode === MonitorModes.CRITICAL
                    ? definition.defaultValue()
                    : null,
                warning_value:
                  mode === MonitorModes.WARNING
                    ? definition.defaultValue()
                    : null,
              });
              break;
          }
        };

        return (
          <RadioGroup orientation="vertical" value={mode} onChange={onChange}>
            <Radio
              isDisabled={!definition.canDisable}
              value={MonitorModes.NONE}
              label="No alert"
            />
            <Row gap={2} fontWeight="medium" alignItems="center">
              <Radio value={defaultModeSelection} label="Send a" />
              <Select
                size="sm"
                width="auto"
                value={defaultModeSelection}
                onChange={(o) => (o ? onChange(o) : undefined)}
                options={[MonitorModes.CRITICAL, MonitorModes.WARNING] as const}
                optionLabel={(o) => o}
                optionValue={(o) => o}
              />
              <definition.summary prefix={false} condition={defaultCondition} />
            </Row>
            <Box zIndex={1}>
              <Radio value={MonitorModes.CUSTOM} label="Custom..." />
            </Box>
          </RadioGroup>
        );
      }}
    />
  );
};
